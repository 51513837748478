<template>
  <v-dialog scrollable persistent v-model="dialog" width="1200px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header">Add Field Equipment</div>

        <v-card-text>
            <v-card style="">
              <v-card-text style="text-align:left">
              <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Equipment Name" outlined v-model="equipment.description" v-on:keydown.enter.prevent=''></v-text-field>

              <div style="height:12px"><span style="color:red" v-if="equipment_already">Equipment already exists.</span></div>

      <v-radio-group v-model="equipment.owner">
        <v-radio
          v-for="owner in ['Mid-State', 'United']"
          :key="owner"
          :label="owner + ' Owned'"
          :value="owner"
        ></v-radio>
      </v-radio-group>

              <v-simple-table>
                <thead>
                  <tr>
                    <th style="">Daily Cost</th>
                    <th style="">Weekly Cost</th>
                    <th style="">Monthly Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="equipment.cost_daily" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="equipment.cost_weekly" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="equipment.cost_monthly" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                  </tr>
                </tbody>
              </v-simple-table>

              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveRate()" small :disabled="equipment.description.trim() === '' || equipment_already">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency } from '../mixins/formatCurrency.js'

export default {
  name: 'AddFieldEquipment',
  components: {},
  mixins: [formatCurrency],
  data(){
    return{
      dialog: true,
      existing_equipment: [],
      equipment_already: false,
      equipment: {
        description: '',
        owner: 'Mid-State',
        cost_daily: 0,
        cost_weekly: 0,
        cost_monthly: 0
      }
    }
  },
  beforeMount(){
    for(var id in this.db.field_rates.equipment_rates){
      var equipment = this.db.field_rates.equipment_rates[id].description.trim().toLocaleLowerCase()
      if(this.existing_equipment.indexOf(equipment) === -1){
        this.existing_equipment.push(equipment)
      }
    }
  },
  watch: {
    equipment: {
      deep: true,
      handler(){
        this.equipment_already = this.existing_equipment.indexOf(this.equipment.description.trim().toLocaleLowerCase()) !== -1 ? true : false
        this.equipment['cost_daily'] = typeof(this.equipment['cost_daily']) === 'number' ? this.equipment['cost_daily'] : 0
        this.equipment['cost_weekly'] = typeof(this.equipment['cost_weekly']) === 'number' ? this.equipment['cost_weekly'] : 0
        this.equipment['cost_monthly'] = typeof(this.equipment['cost_monthly']) === 'number' ? this.equipment['cost_monthly'] : 0
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    saveRate(){
      var self = this
      Firebase.database().ref('field_rates/equipment_rates').push(self.equipment).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style lang="scss" scoped>


</style>


