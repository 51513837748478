<template>
  <div class="master-summary">
    <v-card-text>
      <div style="font-weight:bold">Master</div>
      <table>
        <tr>
          <td :class="display_columns === 'all' ? 'active-column-btn' : 'inactive-column-btn'"><div @click="display_columns = 'all'" class="filter-link">SHOW ALL COLUMNS</div></td>
          <td :class="display_columns === 'cost' ? 'active-column-btn' : 'inactive-column-btn'"><div @click="display_columns = 'cost'" class="filter-link">SHOW COST COLUMNS</div></td>
          <td :class="display_columns === 'revenue' ? 'active-column-btn' : 'inactive-column-btn'"><div @click="display_columns = 'revenue'" class="filter-link">SHOW REVENUE COLUMNS</div></td>
        </tr>
      </table>

      <div @click="show_detail = !show_detail" class="detail-link">{{show_detail ? '- LESS' : '+ MORE'}} DETAIL</div>

      <v-simple-table dense style="margin-bottom:20px;width:100%;white-space: nowrap;" id="master-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Code</th>
              <th class="text-left">Department</th>
              <th class="text-left" v-if="show_detail">Category</th>
              <th class="text-left" v-if="show_detail">Phase</th>
              <th class="text-left">Labor Hours</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Labor Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Labor Revenue</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Material Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Material Revenue</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Equipment Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Equipment Revenue</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Sub Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Sub Revenue</th>

              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Travel Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Travel Revenue</th>

              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Other Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Other Revenue</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'cost'">Total Cost</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Total Revenue</th>
              <th class="text-left">Margin</th>
              <th class="text-left" v-if="display_columns === 'all' || display_columns === 'revenue'">Total % of Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,index) in table_rows" :class="{total_row : row[3] === ''}" v-if="show_detail || row[3] === ''">
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[0]}}</td>
              <td>{{row[1]}}</td>
              <td v-if="show_detail">{{row[2]}}</td>
              <td v-if="show_detail">{{row[3]}}</td>
              <td>{{row[5] > 0 ? Math.round(row[5]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[6] > 0 ? formatCurrency(row[6]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[7] > 0 ? formatCurrency(row[7]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[8] > 0 ? formatCurrency(row[8]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[9] > 0 ? formatCurrency(row[9]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[10] > 0 ? formatCurrency(row[10]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[11] > 0 ? formatCurrency(row[11]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[12] > 0 ? formatCurrency(row[12]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[13] > 0 ? formatCurrency(row[13]) : '-'}}</td>


              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[14] > 0 ? formatCurrency(row[14]) : '-'}}</td> <!-- travel cost -->
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[15] > 0 ? formatCurrency(row[15]) : '-'}}</td> <!-- travel revenue -->

              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[16] > 0 ? formatCurrency(row[16]) : '-'}}</td> <!-- other cost -->
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[17] > 0 ? formatCurrency(row[17]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{row[18] > 0 ? formatCurrency(row[18]) : '-'}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{row[19] > 0 ? formatCurrency(row[19]) : '-'}}</td>
              <td>{{row[20]}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{calculatePercentOfValue(row[20])}}</td>
            </tr>
            <tr class="sum_row">
              <td v-if="display_columns === 'all' || display_columns === 'cost'">TOTAL</td>
              <td v-if="display_columns !== 'revenue'"></td><td v-if="display_columns === 'revenue'">TOTAL</td>
              <td v-if="show_detail"></td>
              <td v-if="show_detail"></td>
              <td>{{Math.round(summary_row.labor_hours)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.labor_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.labor_revenue)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.material_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.material_revenue)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.equipment_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.equipment_revenue)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.subcontracting_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.subcontracting_revenue)}}</td>

              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.travel_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.travel_revenue)}}</td>

              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.other_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.other_revenue)}}</td>

              <td v-if="display_columns === 'all' || display_columns === 'cost'">{{formatCurrency(summary_row.total_cost)}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{formatCurrency(summary_row.total_revenue)}}</td>
              <td>{{summary_row.margin}}</td>
              <td v-if="display_columns === 'all' || display_columns === 'revenue'">{{calculatePercentOfValue(summary_row.total_revenue)}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <table>
        <tr>
          <td><v-btn color="primary" small @click="exportCEF()" style="margin-left:10px">EXPORT CEF</v-btn></td>
          <td><v-btn color="primary" small @click="exportMaster()" style="margin-left:10px">EXPORT MASTER</v-btn></td>
          <td><v-btn color="primary" small @click="exportPenta()" style="margin-left:10px">EXPORT PENTA FILES</v-btn></td>
          <td><v-btn color="primary" small @click="exportSchedule()" style="margin-left:10px">EXPORT SCHEDULE</v-btn></td>
        </tr>
      </table>
    </v-card-text>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency } from '../mixins/formatCurrency.js'
import XLSX from 'xlsx'
import Moment from 'moment'

export default {
  name: 'master-summary',
  props: {
    opportunityid: String
  },
  mixins: [formatCurrency],
  components: {},
  data(){
    return{
      show_detail: false,
      display_columns: 'revenue',
      revenue_override_percentage: 0,
      table_rows: [],
      summary_row: {
        equipment_cost: 0,
        equipment_revenue: 0,
        labor_hours: 0,
        labor_cost: 0,
        labor_revenue: 0,
        material_cost: 0,
        material_revenue: 0,

        travel_cost: 0,
        travel_revenue: 0,

        other_cost: 0,
        other_revenue: 0,
        subcontracting_cost: 0,
        subcontracting_revenue: 0,
        total_hours: 0,
        total_cost: 0,
        total_revenue: 0,
        margin: 0
      },
      penta_data: {},
      fab_penta_data: {}
    }
  },
  mounted(){
    this.buildTable()
  },
  watch:{

  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    formatEquipmentUnit(unit){
      switch (unit) {
        case 'Daily':
          return 'DAY'
          break;
        case 'Weekly':
          return 'WEEK'
          break;
        case 'Monthly':
          return 'MONTH'
          break;
        default:
          return unit
      }
    },
    exportCEF(){
      // • Labor and Equipment only populated with Field quote sheets 
      // • 3rd Party tab populated with Field and Fabrication quote sheets 
      // • Ignore “Speciality Craft” in labor 
      // • There are new craft types that MS is working on with Mosaic
      var self = this
      Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(opp_snapshot){
        var opportunity = opp_snapshot.val()
        var quotes = opportunity.quotes
        var workbook = XLSX.utils.book_new()

        var consolidated_data = []
        var consolidated_ws;

        var labor_total = 0
        var equipment_total = 0
        var subs_total = 0
        var has_excel = false

        // LABOR
        var labor_summary = {
          'Field' : {
            'MANAGEMENT_PROJECT': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SUPERVIS_SUPERINTENDENT': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SAFETYPERS_FOREMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'QALITYCNTRL_LEVEL1': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SUPERVIS_FOREMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'MECHANIC_JOURNEYMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'LABORER_HELPER': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
          },
          'Electrical' : {
            'MANAGEMENT_PROJECT': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SUPERVIS_SUPERINTENDENT': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SAFETYPERS_FOREMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'QALITYCNTRL_LEVEL1': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SUPERVIS_FOREMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'MECHANIC_JOURNEYMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'LABORER_HELPER': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
          },
          'Civil' : {
            'MANAGEMENT_PROJECT': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SUPERVIS_SUPERINTENDENT': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SAFETYPERS_FOREMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'QALITYCNTRL_LEVEL1': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'SUPERVIS_FOREMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'MECHANIC_JOURNEYMAN': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
            'LABORER_HELPER': {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0},
          }
        }

        Firebase.database().ref('quotes').child(self.opportunityid).once('value',function(quotes_snapshot){
          var quotes = quotes_snapshot.val()

          for(var qid in quotes){
            var quote = quotes[qid]
            var quote_sheet = quote.quote_sheet

            if((quote_sheet === 'Field' || quote_sheet === 'Electrical' || quote_sheet === 'Civil') && quote.show_in_master){

              if(quote.tasks){
                for(var i = 0; i < quote.tasks.length; i++){
                  var task = quote.tasks[i]
                  console.log(task)
                  var task_num = i+1
                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['quantity'] += task['project_manager']['standard_time'] * task['project_manager']['employees_per_shift'] * task['project_manager']['number_of_shifts']
                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['quantity'] +=task['project_manager']['overtime'] * task['project_manager']['employees_per_shift'] * task['project_manager']['number_of_shifts']
                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['quantity']+= task['project_manager']['double_time'] * task['project_manager']['employees_per_shift'] * task['project_manager']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['project_manager'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['project_manager'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['project_manager'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['project_manager'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['project_manager'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['project_manager'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)                    
                  }

                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['subtotal'] = labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['quantity'] * labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['rate']
                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['subtotal'] = labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['quantity'] * labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['rate']
                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['subtotal'] = labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['quantity'] * labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['rate']
                  labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['total'] = labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['st']['subtotal'] + labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['ot']['subtotal'] + labor_summary[quote_sheet]['MANAGEMENT_PROJECT']['dt']['subtotal']

                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['quantity'] += task['general_superintendent']['standard_time'] * task['general_superintendent']['employees_per_shift'] * task['general_superintendent']['number_of_shifts']
                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['quantity'] += task['general_superintendent']['overtime'] * task['general_superintendent']['employees_per_shift'] * task['general_superintendent']['number_of_shifts']
                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['quantity'] += task['general_superintendent']['double_time'] * task['general_superintendent']['employees_per_shift'] * task['general_superintendent']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['general_superintendent'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['general_superintendent'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['general_superintendent'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['general_superintendent'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['general_superintendent'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['general_superintendent'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }

                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['subtotal'] = labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['quantity'] * labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['rate']
                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['subtotal'] = labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['quantity'] * labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['rate']
                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['subtotal'] = labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['quantity'] * labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['rate']
                  labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['total'] = labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['st']['subtotal'] + labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['ot']['subtotal'] + labor_summary[quote_sheet]['SUPERVIS_SUPERINTENDENT']['dt']['subtotal']

                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['quantity'] += task['safety_officer']['standard_time'] * task['safety_officer']['employees_per_shift'] * task['safety_officer']['number_of_shifts']
                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['quantity'] += task['safety_officer']['overtime'] * task['safety_officer']['employees_per_shift'] * task['safety_officer']['number_of_shifts']
                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['quantity'] += task['safety_officer']['double_time'] * task['safety_officer']['employees_per_shift'] * task['safety_officer']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['safety_officer'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['safety_officer'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['safety_officer'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['safety_officer'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['safety_officer'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['safety_officer'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }

                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['subtotal'] = labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['quantity'] * labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['rate']
                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['subtotal'] = labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['quantity'] * labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['rate']
                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['subtotal'] = labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['quantity'] * labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['rate']
                  labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['total'] = labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['st']['subtotal'] + labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['ot']['subtotal'] + labor_summary[quote_sheet]['SAFETYPERS_FOREMAN']['dt']['subtotal']

                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['quantity'] += task['qaqc']['standard_time'] * task['qaqc']['employees_per_shift'] * task['qaqc']['number_of_shifts']
                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['quantity'] += task['qaqc']['overtime'] * task['qaqc']['employees_per_shift'] * task['qaqc']['number_of_shifts']
                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['quantity'] += task['qaqc']['double_time'] * task['qaqc']['employees_per_shift'] * task['qaqc']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['qaqc'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['qaqc'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['qaqc'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['qaqc'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['qaqc'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['qaqc'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }

                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['subtotal'] = labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['quantity'] * labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['rate']
                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['subtotal'] = labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['quantity'] * labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['rate']
                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['subtotal'] = labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['quantity'] * labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['rate']
                  labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['total'] = labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['st']['subtotal'] + labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['ot']['subtotal'] + labor_summary[quote_sheet]['QALITYCNTRL_LEVEL1']['dt']['subtotal']

                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['quantity'] += task['foreman']['standard_time'] * task['foreman']['employees_per_shift'] * task['foreman']['number_of_shifts']
                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['quantity'] += task['foreman']['overtime'] * task['foreman']['employees_per_shift'] * task['foreman']['number_of_shifts']
                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['quantity'] += task['foreman']['double_time'] * task['foreman']['employees_per_shift'] * task['foreman']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['foreman'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['foreman'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['foreman'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['foreman'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['foreman'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['foreman'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }

                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['subtotal'] = labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['quantity'] * labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['rate']
                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['subtotal'] = labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['quantity'] * labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['rate']
                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['subtotal'] = labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['quantity'] * labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['rate']
                  labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['total'] = labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['st']['subtotal'] + labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['ot']['subtotal'] + labor_summary[quote_sheet]['SUPERVIS_FOREMAN']['dt']['subtotal']

                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['quantity'] += task['journeymen']['standard_time'] * task['journeymen']['employees_per_shift'] * task['journeymen']['number_of_shifts']
                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['quantity'] += task['journeymen']['overtime'] * task['journeymen']['employees_per_shift'] * task['journeymen']['number_of_shifts']
                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['quantity'] += task['journeymen']['double_time'] * task['journeymen']['employees_per_shift'] * task['journeymen']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['journeymen'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['journeymen'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['journeymen'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['journeymen'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['journeymen'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['journeymen'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }

                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['subtotal'] = labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['quantity'] * labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['rate']
                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['subtotal'] = labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['quantity'] * labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['rate']
                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['subtotal'] = labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['quantity'] * labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['rate']
                  labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['total'] = labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['st']['subtotal'] + labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['ot']['subtotal'] + labor_summary[quote_sheet]['MECHANIC_JOURNEYMAN']['dt']['subtotal']

                  labor_summary[quote_sheet]['LABORER_HELPER']['st']['quantity'] += task['helper']['standard_time'] * task['helper']['employees_per_shift'] * task['helper']['number_of_shifts']
                  labor_summary[quote_sheet]['LABORER_HELPER']['ot']['quantity'] += task['helper']['overtime'] * task['helper']['employees_per_shift'] * task['helper']['number_of_shifts']
                  labor_summary[quote_sheet]['LABORER_HELPER']['dt']['quantity'] += task['helper']['double_time'] * task['helper']['employees_per_shift'] * task['helper']['number_of_shifts']

                  if(!quote.is_msa_quote_sheet){
                    labor_summary[quote_sheet]['LABORER_HELPER']['st']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'ST']['helper'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['LABORER_HELPER']['ot']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'OT']['helper'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['LABORER_HELPER']['dt']['rate'] = quote['rates']['labor_revenue_rates'][quote['customer']+'DT']['helper'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }else{
                    labor_summary[quote_sheet]['LABORER_HELPER']['st']['rate'] = quote['rates']['labor_revenue_rates']['ST']['helper'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                    labor_summary[quote_sheet]['LABORER_HELPER']['ot']['rate'] = quote['rates']['labor_revenue_rates']['OT']['helper'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                    labor_summary[quote_sheet]['LABORER_HELPER']['dt']['rate'] = quote['rates']['labor_revenue_rates']['DT']['helper'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  }

                  labor_summary[quote_sheet]['LABORER_HELPER']['st']['subtotal'] = labor_summary[quote_sheet]['LABORER_HELPER']['st']['quantity'] * labor_summary[quote_sheet]['LABORER_HELPER']['st']['rate']
                  labor_summary[quote_sheet]['LABORER_HELPER']['ot']['subtotal'] = labor_summary[quote_sheet]['LABORER_HELPER']['ot']['quantity'] * labor_summary[quote_sheet]['LABORER_HELPER']['ot']['rate']
                  labor_summary[quote_sheet]['LABORER_HELPER']['dt']['subtotal'] = labor_summary[quote_sheet]['LABORER_HELPER']['dt']['quantity'] * labor_summary[quote_sheet]['LABORER_HELPER']['dt']['rate']
                  labor_summary[quote_sheet]['LABORER_HELPER']['total'] = labor_summary[quote_sheet]['LABORER_HELPER']['st']['subtotal'] + labor_summary[quote_sheet]['LABORER_HELPER']['ot']['subtotal'] + labor_summary[quote_sheet]['LABORER_HELPER']['dt']['subtotal']

                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num] = {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0}
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num] = {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0}
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num] = {'st': {'quantity':0,'rate':0,'subtotal':0}, 'ot': {'quantity':0,'rate':0,'subtotal':0}, 'dt': {'quantity':0,'rate':0,'subtotal':0}, 'total': 0}

                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['st']['quantity'] += task['specialty_craft_1']['standard_time'] * task['specialty_craft_1']['employees_per_shift'] * task['specialty_craft_1']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['ot']['quantity'] += task['specialty_craft_1']['overtime'] * task['specialty_craft_1']['employees_per_shift'] * task['specialty_craft_1']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['dt']['quantity'] += task['specialty_craft_1']['double_time'] * task['specialty_craft_1']['employees_per_shift'] * task['specialty_craft_1']['number_of_shifts']

                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['st']['rate'] = task['specialty_craft_1']['revenue_rate_st'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['ot']['rate'] = task['specialty_craft_1']['revenue_rate_ot'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['dt']['rate'] = task['specialty_craft_1']['revenue_rate_dt'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)

                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['st']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['st']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['st']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['ot']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['ot']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['ot']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['dt']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['dt']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['dt']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['total'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['st']['subtotal'] + labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['ot']['subtotal'] + labor_summary[quote_sheet]['SPECIALTY_CRAFT_1_TASK_'+task_num]['dt']['subtotal']

                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['st']['quantity'] += task['specialty_craft_2']['standard_time'] * task['specialty_craft_2']['employees_per_shift'] * task['specialty_craft_2']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['ot']['quantity'] += task['specialty_craft_2']['overtime'] * task['specialty_craft_2']['employees_per_shift'] * task['specialty_craft_2']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['dt']['quantity'] += task['specialty_craft_2']['double_time'] * task['specialty_craft_2']['employees_per_shift'] * task['specialty_craft_2']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['st']['rate'] = task['specialty_craft_2']['revenue_rate_st'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['ot']['rate'] = task['specialty_craft_2']['revenue_rate_ot'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['dt']['rate'] = task['specialty_craft_2']['revenue_rate_dt'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['st']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['st']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['st']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['ot']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['ot']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['ot']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['dt']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['dt']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['dt']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['total'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['st']['subtotal'] + labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['ot']['subtotal'] + labor_summary[quote_sheet]['SPECIALTY_CRAFT_2_TASK_'+task_num]['dt']['subtotal']

                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['st']['quantity'] += task['specialty_craft_3']['standard_time'] * task['specialty_craft_3']['employees_per_shift'] * task['specialty_craft_3']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['ot']['quantity'] += task['specialty_craft_3']['overtime'] * task['specialty_craft_3']['employees_per_shift'] * task['specialty_craft_3']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['dt']['quantity'] += task['specialty_craft_3']['double_time'] * task['specialty_craft_3']['employees_per_shift'] * task['specialty_craft_3']['number_of_shifts']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['st']['rate'] = task['specialty_craft_3']['revenue_rate_st'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['st'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['ot']['rate'] = task['specialty_craft_3']['revenue_rate_ot'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['ot'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['dt']['rate'] = task['specialty_craft_3']['revenue_rate_dt'] + (task.day_night === 'Night' ? quote['rates']['labor_night_shift_differentials']['dt'] : 0)
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['st']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['st']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['st']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['ot']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['ot']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['ot']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['dt']['subtotal'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['dt']['quantity'] * labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['dt']['rate']
                  labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['total'] = labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['st']['subtotal'] + labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['ot']['subtotal'] + labor_summary[quote_sheet]['SPECIALTY_CRAFT_3_TASK_'+task_num]['dt']['subtotal']
                }
              }
            }

            if(quote.show_in_master && quote_sheet === 'Excel'){
              has_excel = true
            }
          }

          for(var dept in labor_summary){
            for(var role in labor_summary[dept]){
              labor_total += labor_summary[dept][role]['total']
            }
          }

          consolidated_data.push([],[],['Department','Labor','Unit','Quantity','Rate (USD)','Sub-Total'])

          for(var dept in labor_summary){
            for(var role in labor_summary[dept]){
              if(labor_summary[dept][role]['st']['quantity'] > 0){
                consolidated_data.push([dept,role,'Straight Time ST ',labor_summary[dept][role]['st']['quantity'],labor_summary[dept][role]['st']['rate'],labor_summary[dept][role]['st']['subtotal']])
              }
              if(labor_summary[dept][role]['ot']['quantity'] > 0){
                consolidated_data.push([dept,role,'Over Time OT ',labor_summary[dept][role]['ot']['quantity'],labor_summary[dept][role]['ot']['rate'],labor_summary[dept][role]['ot']['subtotal']])
              }
              if(labor_summary[dept][role]['dt']['quantity'] > 0){
                consolidated_data.push([dept,role,'Double Time DT ',labor_summary[dept][role]['dt']['quantity'],labor_summary[dept][role]['dt']['rate'],labor_summary[dept][role]['dt']['subtotal']])
              }
            }
          }

          // EQUIPMENT
          consolidated_data.push([],[],['Department','Equipment','Unit','Quantity','Rate (USD)','Sub-Total'])
          var equipment_summary = []
          for(var qid in quotes){
            var quote = quotes[qid]
            var quote_sheet = quote.quote_sheet
            if((quote_sheet === 'Field' || quote_sheet === 'Electrical' || quote_sheet === 'Civil') && quote.show_in_master){
              if(quote.equipment && !quote.is_msa_quote_sheet){
                for(var i = 0; i < quote.equipment.length; i++){
                  var equipment = quote.equipment[i]
                  var ms_markup = quote.mid_state_equipment_markup_percentage / 100
                  if(equipment.owner === 'Mid-State' && equipment.equipment_id !== '-other-'){
                    equipment_summary.push({'department': quote_sheet,'equipment': equipment.description, 'unit': self.formatEquipmentUnit(equipment.rate_type), 'quantity': equipment.quantity * equipment.duration, 'rate': equipment.item_cost + (ms_markup * equipment.item_cost), 'subtotal': equipment.total_revenue})
                  }
                }
              }

              if(quote.equipment && quote.is_msa_quote_sheet){
                for(var e in quote.equipment){
                  var equipment = quote.equipment[e]
                  var ms_markup = quote.rates.markups.equipment_owned / 100
                  if(equipment.quantity > 0){
                    if(equipment.owner === 'Mid-State'){
                      equipment_summary.push({'department': quote_sheet,'equipment': equipment.description, 'unit': self.formatEquipmentUnit(equipment.rate_type), 'quantity': equipment.quantity * equipment.duration, 'rate': equipment.total_cost, 'subtotal': equipment.total_revenue})
                    }
                  }
                }
              }

            }
          }
          for(var i = 0; i < equipment_summary.length; i++){
            consolidated_data.push([equipment_summary[i].department, equipment_summary[i].equipment, equipment_summary[i].unit, equipment_summary[i].quantity, equipment_summary[i].rate, equipment_summary[i].subtotal])
            equipment_total += equipment_summary[i].subtotal
          }

          // SUBS
          var subs_summary = []

          for(var qid in quotes){
            var quote = quotes[qid]
            var quote_sheet = quote.quote_sheet

            if((quote_sheet === 'Field' || quote_sheet === 'Electrical' || quote_sheet === 'Civil') && quote.show_in_master){
              if(quote.equipment && !quote.is_msa_quote_sheet){
                for(var i = 0; i < quote.equipment.length; i++){
                  var equipment = quote.equipment[i]
                  var rental_markup = quote.rental_equipment_markup_percentage / 100

                  if(equipment.owner !== 'Mid-State' && equipment.equipment_id !== '-other-'){
                    subs_summary.push({'department': quote_sheet,'item': equipment.description, 'unit': equipment.rate_type, 'rate': equipment.total_cost, 'company': equipment.owner , 'markup': rental_markup, 'subtotal': equipment.total_revenue})
                  }

                  if(equipment.equipment_id === '-other-'){
                    subs_summary.push({'department': quote_sheet,'item': equipment.other_name, 'unit': equipment.rate_type, 'rate': equipment.total_cost, 'company': equipment.vendor, 'markup': rental_markup, 'subtotal': equipment.total_revenue})
                  }

                }
              }

              if(quote.equipment && quote.is_msa_quote_sheet){

                for(var e in quote.equipment){
                  var equipment = quote.equipment[e]
                  var rental_markup = quote.rates.markups.equipment_rental / 100
                  if(equipment.quantity > 0 && equipment.owner !== 'Mid-State'){
                    subs_summary.push({'department': quote_sheet,'item': equipment.description, 'unit': equipment.rate_type, 'rate': equipment.total_cost, 'company': equipment.owner , 'markup': rental_markup, 'subtotal': equipment.total_revenue})
                  }
                }
              }

              if(quote.material){
                if(!quote.is_msa_quote_sheet){
                  for(var i = 0; i < quote.material.length; i++){
                    var material = quote.material[i]
                    var material_markup = quote.material_markup_percentage / 100

                    if(material.owner !== 'Mid-State' && material.material_id !== '-other-'){
                      subs_summary.push({'department': quote_sheet,'item': material.description, 'unit': 'Each', 'rate': material.total_cost, 'company': 'Mid-State' , 'markup': material_markup, 'subtotal': material.total_revenue})
                    }

                    if(material.material_id === '-other-'){
                      subs_summary.push({'department': quote_sheet,'item': material.other_name, 'unit': 'Each', 'rate': material.total_cost, 'company': material.vendor, 'markup': material_markup, 'subtotal': material.total_revenue})
                    }
                  }                  
                }
                if(quote.is_msa_quote_sheet){
                  for(var m in quote.material){
                    var material = quote.material[m]
                    var material_markup = quote.rates.markups.material / 100
                    if(material.quantity > 0){
                      subs_summary.push({'department': quote_sheet,'item': material.item, 'unit': 'Each', 'rate': material.total_cost, 'company': 'Mid-State' , 'markup': material_markup, 'subtotal': material.total_revenue})  
                    }
                  }
                }
              }

              if(quote.small_tools_consumables_material && quote.small_tools_consumables_material.total_revenue > 0){
                // var material_markup = quote.material_markup_percentage / 100
                var material_markup = 0
                subs_summary.push({'department': quote_sheet,'item': 'Small Tools and Consumables', 'unit': 'Lot', 'rate': quote.small_tools_consumables_material.total_cost, 'company': 'Mid-State', 'markup': material_markup, 'subtotal':  quote.small_tools_consumables_material.total_revenue})
              }

              if(quote.welding_fuel_revenue > 0){
                subs_summary.push({'department': quote_sheet,'item': 'Fuel: Welding', 'unit': 'Lot', 'rate': quote.welding_fuel_cost, 'company': 'Mid-State', 'markup': 0, 'subtotal':  quote.welding_fuel_revenue})
              }

              if(quote.forklift_fuel_revenue > 0){
                subs_summary.push({'department': quote_sheet,'item': 'Fuel: Forklift', 'unit': 'Lot', 'rate': quote.forklift_fuel_cost, 'company': 'Mid-State', 'markup': 0, 'subtotal':  quote.forklift_fuel_revenue})
              }

              if(quote.air_compressor_fuel_revenue > 0){
                subs_summary.push({'department': quote_sheet,'item': 'Fuel: Air Compressor', 'unit': 'Lot', 'rate': quote.air_compressor_fuel_cost, 'company': 'Mid-State', 'markup': 0, 'subtotal':  quote.air_compressor_fuel_revenue})
              }

              if(quote.manlift_fuel_revenue > 0){
                subs_summary.push({'department': quote_sheet,'item': 'Fuel: Manlift', 'unit': 'Lot', 'rate': quote.manlift_fuel_cost, 'company': 'Mid-State', 'markup': 0, 'subtotal':  quote.manlift_fuel_revenue})
              }

              if(quote.require_trucks){
                subs_summary.push({'department': quote_sheet,'item': 'Pickup Trucks', 'unit': 'Lot', 'rate': quote.pickup_trucks.total_revenue, 'company': 'Mid-State', 'markup': 0, 'subtotal':  quote.pickup_trucks.total_revenue})
                subs_summary.push({'department': quote_sheet,'item': 'Service Trucks', 'unit': 'Lot', 'rate': quote.service_trucks.total_revenue, 'company': 'Mid-State', 'markup': 0, 'subtotal':  quote.service_trucks.total_revenue})
              }

              if(quote.subcontractors){
                for(var i = 0; i < quote.subcontractors.length; i++){
                  var subcontractor = quote.subcontractors[i]
                  var subcontractor_markup = quote.is_msa_quote_sheet ? (quote.rates.markups.subcontractor / 100) : (quote.subcontractor_markup_percentage / 100)
                  subs_summary.push({'department': quote_sheet,'item': subcontractor.description, 'unit': 'Lot', 'rate': subcontractor.total_cost, 'company': subcontractor.subcontractor , 'markup': subcontractor_markup, 'subtotal': subcontractor.total_revenue})
                }
              }

              if(quote.require_per_diem){
                var per_diem_cost = 0
                var per_diem_revenue = 0
                var per_diem_markup = quote.is_msa_quote_sheet ? (quote.rates.markups.per_diem / 100) : (quote.per_diem_markup_percentage / 100)

                for(var phase in quote.elmos.phases){
                  per_diem_cost += quote.elmos.phases[phase].other.per_diem_cost
                  per_diem_revenue += quote.elmos.phases[phase].other.per_diem_revenue
                }
                subs_summary.push({'department': quote_sheet,'item': 'Per Diem', 'unit': 'Lot', 'rate': per_diem_cost, 'company': 'Mid-State', 'markup': per_diem_markup, 'subtotal': per_diem_revenue})
              }

              if(quote.require_travel){
                var travel_cost = 0
                var travel_revenue = 0
                var travel_markup = quote.is_msa_quote_sheet ? (quote.rates.markups.travel / 100) : (quote.travel_markup_percentage / 100)
                for(var phase in quote.elmos.phases){
                  travel_cost += quote.elmos.phases[phase].other.travel_cost
                  travel_revenue += quote.elmos.phases[phase].other.travel_revenue
                }
                subs_summary.push({'department': quote_sheet,'item': 'Travel', 'unit': 'Lot', 'rate': travel_cost, 'company': 'Mid-State', 'markup': travel_markup, 'subtotal': travel_revenue})
              }
            }

            if(quote_sheet !== 'Field' && quote_sheet !== 'Electrical' && quote_sheet !== 'Civil' && quote.show_in_master){
              subs_summary.push({'department': quote_sheet,'item': quote_sheet.toUpperCase(), 'unit': '', 'rate': '', 'company': '', 'markup': '', 'subtotal': quote.elmos.total_revenue})
            }
          }

          consolidated_data.push([],[],['Department','Third Party (SUBS) Company','Description of Equipment, Material, or Service','Third Party Cost', 'Markup %', 'Invoice', 'Markup Cost'])
          for(var i = 0; i < subs_summary.length; i++){
            var markup_cost = (subs_summary[i].rate && subs_summary[i].markup) ? (subs_summary[i].rate * subs_summary[i].markup) : 0
            consolidated_data.push([subs_summary[i].department,subs_summary[i].company, subs_summary[i].item, subs_summary[i].rate, subs_summary[i].markup, subs_summary[i].subtotal, markup_cost ])
            subs_total += subs_summary[i].subtotal
          }

          if(has_excel){
            consolidated_data.push([],[])
            consolidated_data.push(['WARNING - THERE IS AT LEAST ONE EXCEL QUOTE SHEET UPLOADED FOR THIS OPPORTUNITY THAT DOES NOT APPEAR IN THIS REPORT.'])
          }

          consolidated_data.unshift(['CONTRACTOR ESTIMATE FORM'],[],['LABOR TOTAL', labor_total],['EQUIPMENT TOTAL', equipment_total],['THIRD PARTY (SUBS) TOTAL', subs_total],['TOTAL COST', labor_total+equipment_total+subs_total])

          consolidated_ws = XLSX.utils.aoa_to_sheet(consolidated_data);
          consolidated_ws['!cols'] = [{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25}];
          XLSX.utils.book_append_sheet(workbook, consolidated_ws, 'FORM');
          XLSX.writeFile(workbook, 'cef.xlsx')
        })

      })


    },
    exportMaster(){
      var self = this
      var workbook = XLSX.utils.book_new()
      var worksheet = XLSX.utils.table_to_sheet(document.getElementById('master-table'))
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'master_export.xlsx')
    },
    calculatePercentOfValue(amt){
      return amt > 0 ? ( amt / this.summary_row.total_revenue * 100).toFixed(1) + '%' : ''
    },
    calculateMargin(cost,revenue){
      return revenue > 0 ? ((revenue - cost) / revenue * 100 ).toFixed(1) + '%' : ''
    },
    exportPenta(){
      var self = this

      Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){
        var job_number = snapshot.val().job_number

        if(!job_number){
          self.$store.state.notification = 'Job Number is required before exporting PENTA files.'
          return
        }

        var current_date = Moment().format('MM/DD/YYYY')
        var ou_data = self.penta_data

        for(var ou in ou_data){
          var workbook;
          var worksheet;
          var sheet_rows = []
          var phases = ou_data[ou].phases


          if(ou !== '004'){
            workbook = XLSX.utils.book_new()
            sheet_rows.push(['Job Id:', job_number + ou], ['Date:', current_date],['Total Amount:', ou_data[ou].total_cost], ['Total Units:',0], ['Total Hours', ou_data[ou].total_hours], [], ['Cost Type','Costcode','Description','Amount','Units','Unit Cost','Hours','Hourly Rate','Units/Hour','Hours/Unit','Unit of Measure','Client Code','Client Descr','CO Id','Key','Rev Category','Retention %'])

            for(var phase in phases){
              var phase_data = phases[phase]
              var phase_num = Number(phase.split('Phase ')[1])
              var penta_phase_num = phase_num < 10 ? '00' + phase_num : '0' + phase_num

              if(phase_data.total_cost > 0){
                if(phase_data.labor.cost > 0){
                  var cost_code = 1 + String(penta_phase_num)
                  var description = 'LABOR PHASE ' + phase_num
                  var amount = _.round(phase_data.labor.cost,2)
                  var hours = _.round(phase_data.labor.hours,2)
                  sheet_rows.push(['L',cost_code, description , amount,'','', hours])
                }
                if(phase_data.material.cost > 0){
                  var cost_code = 2 + String(penta_phase_num)
                  var description = 'MATERIAL PHASE ' + phase_num
                  var amount = _.round(phase_data.material.cost,2)
                  sheet_rows.push(['M',cost_code, description, amount,'','', ''])
                }
                if(phase_data.equipment.cost > 0){
                  var cost_code = 3 + String(penta_phase_num)
                  var description = 'EQUIPMENT PHASE ' + phase_num
                  var amount = _.round(phase_data.equipment.cost,2)
                  sheet_rows.push(['E',cost_code, description, amount,'','', ''])
                }
                if(phase_data.subcontracting.cost > 0){
                  var cost_code = 4 + String(penta_phase_num)
                  var description = 'SUBCONTRACTING PHASE ' + phase_num
                  var amount = _.round(phase_data.subcontracting.cost,2)
                  sheet_rows.push(['S',cost_code, description, amount,'','', ''])
                }
                if(phase_data.other.cost > 0){
                  var cost_code = 5 + String(penta_phase_num)
                  var description = 'OTHER PHASE ' + phase_num
                  var amount = _.round(phase_data.other.cost,2)
                  sheet_rows.push(['O',cost_code, description, amount,'','', ''])
                }
                if(phase_data.travel.cost > 0){
                  var cost_code = 6 + String(penta_phase_num)
                  var description = 'TRAVEL PHASE ' + phase_num
                  var amount = _.round(phase_data.travel.cost,2)
                  sheet_rows.push(['T',cost_code, description, amount,'','', ''])
                }
              }
            }

            workbook.Workbook = {}
            workbook.Workbook.Names = [{Name: "cc", Ref: "Sheet1!$B:$B"},{Name: "client_cc", Ref: "Sheet1!$L:$L"},{Name: "client_descr", Ref: "Sheet1!$M:$M"},{Name: "co_id", Ref: "Sheet1!$N:$N"},{Name: "cost_type_cd", Ref: "Sheet1!$A:$A"},{Name: "d_hist_date", Ref: "Sheet1!$B$2"},{Name: "descr", Ref: "Sheet1!$C:$C"},{Name: "hrs", Ref: "Sheet1!$G:$G"},{Name: "hrs_per_unit", Ref: "Sheet1!$J:$J"},{Name: "job_amt", Ref: "Sheet1!$B$3"},{Name: "job_hrs", Ref: "Sheet1!$B$5"},{Name: "job_id", Ref: "Sheet1!$B$1"},{Name: "job_units", Ref: "Sheet1!$B$4"},{Name: "key_num", Ref: "Sheet1!$O:$O"},{Name: "lump_sum_amt", Ref: "Sheet1!$D:$D"},{Name: "rate", Ref: "Sheet1!$H:$H"},{Name: "ret_pct", Ref: "Sheet1!$Q:$Q"},{Name: "rev_cat_cd", Ref: "Sheet1!$P:$P"},{Name: "start_detail", Ref: "Sheet1!$8:$8"},{Name: "um", Ref: "Sheet1!$K:$K"},{Name: "unit_cost", Ref: "Sheet1!$F:$F"},{Name: "units", Ref: "Sheet1!$E:$E"},{Name: "units_per_hour", Ref: "Sheet1!$I:$I"}]
            worksheet = XLSX.utils.json_to_sheet(sheet_rows,{skipHeader: 1})
            XLSX.utils.book_append_sheet(workbook, worksheet)
            XLSX.writeFile(workbook, ou+'.xlsx')
          }

          if(ou === '004'){
            var sheet_rows_004 = []
            var cost_004 = 0
            var hours_004 = 0
            var rows_004 = []
            var sheet_rows_006 = []
            var cost_006 = 0
            var hours_006 = 0
            var rows_006 = []

            for(var phase in self.fab_penta_data){
              var data = self.fab_penta_data[phase]
              var phase_num = Number(phase.split('Phase ')[1])
              var penta_phase_num = phase_num < 10 ? '0' + phase_num : phase_num

              cost_004 += data['Prep & PM'].labor_cost + data['Pre Fab'].labor_cost + data['Supervision'].labor_cost + data['Fitting'].labor_cost + data['Welding'].labor_cost + data['Fab Gen'].labor_cost + data['QC'].labor_cost + data['Fab Subcontracting'].subcontracting_cost + data['material_cost']

              hours_004 += data['Prep & PM'].labor_hours + data['Pre Fab'].labor_hours + data['Supervision'].labor_hours + data['Fitting'].labor_hours + data['Welding'].labor_hours + data['Fab Gen'].labor_hours + data['QC'].labor_hours
              rows_004.push(['L','11'+penta_phase_num, 'PREP & PM PH ' + phase_num, data['Prep & PM'].labor_cost, '','', data['Prep & PM'].labor_hours])
              rows_004.push(['L','12'+penta_phase_num, 'PRE FAB PH ' + phase_num, data['Pre Fab'].labor_cost, '','', data['Pre Fab'].labor_hours])
              rows_004.push(['L','13'+penta_phase_num, 'SUB, FIT, WELD PH ' + phase_num, data['Supervision'].labor_cost + data['Fitting'].labor_cost + data['Welding'].labor_cost, '','', data['Supervision'].labor_hours + data['Fitting'].labor_hours + data['Welding'].labor_hours])
              rows_004.push(['L','14'+penta_phase_num, 'FAB GEN, QC PH ' + phase_num, data['Fab Gen'].labor_cost + data['QC'].labor_cost, '','', data['Fab Gen'].labor_hours + data['QC'].labor_hours])
              rows_004.push(['M','20'+penta_phase_num, 'MATERIAL PHASE ' + phase_num, data['material_cost'], '','', ''])
              rows_004.push(['S','40'+penta_phase_num, 'SUBCONTRACT PHASE ' + phase_num, data['Fab Subcontracting'].subcontracting_cost, '','', ''])

              // paint
              cost_006 += data['Paint'].labor_cost + data['Paint'].material_cost
              hours_006 += data['Paint'].labor_hours
              rows_006.push(['L','10'+penta_phase_num,'LABOR PHASE ' + phase_num, data['Paint'].labor_cost, '','',data['Paint'].labor_hours ])
              rows_006.push(['M','20'+penta_phase_num,'MATERIAL PHASE ' + phase_num, data['Paint'].material_cost,'','','' ])
            }


            // non-paint 004
            sheet_rows_004.push(['Job Id:', job_number + '004'], ['Date:', current_date],['Total Amount:', cost_004], ['Total Units:',0], ['Total Hours', hours_004], [], ['Cost Type','Costcode','Description','Amount','Units','Unit Cost','Hours','Hourly Rate','Units/Hour','Hours/Unit','Unit of Measure','Client Code','Client Descr','CO Id','Key','Rev Category','Retention %'])
            for(var i = 0; i < rows_004.length;i++){
              sheet_rows_004.push(rows_004[i])
            }
            workbook = XLSX.utils.book_new()
            workbook.Workbook = {}
            workbook.Workbook.Names = [{Name: "cc", Ref: "Sheet1!$B:$B"},{Name: "client_cc", Ref: "Sheet1!$L:$L"},{Name: "client_descr", Ref: "Sheet1!$M:$M"},{Name: "co_id", Ref: "Sheet1!$N:$N"},{Name: "cost_type_cd", Ref: "Sheet1!$A:$A"},{Name: "d_hist_date", Ref: "Sheet1!$B$2"},{Name: "descr", Ref: "Sheet1!$C:$C"},{Name: "hrs", Ref: "Sheet1!$G:$G"},{Name: "hrs_per_unit", Ref: "Sheet1!$J:$J"},{Name: "job_amt", Ref: "Sheet1!$B$3"},{Name: "job_hrs", Ref: "Sheet1!$B$5"},{Name: "job_id", Ref: "Sheet1!$B$1"},{Name: "job_units", Ref: "Sheet1!$B$4"},{Name: "key_num", Ref: "Sheet1!$O:$O"},{Name: "lump_sum_amt", Ref: "Sheet1!$D:$D"},{Name: "rate", Ref: "Sheet1!$H:$H"},{Name: "ret_pct", Ref: "Sheet1!$Q:$Q"},{Name: "rev_cat_cd", Ref: "Sheet1!$P:$P"},{Name: "start_detail", Ref: "Sheet1!$8:$8"},{Name: "um", Ref: "Sheet1!$K:$K"},{Name: "unit_cost", Ref: "Sheet1!$F:$F"},{Name: "units", Ref: "Sheet1!$E:$E"},{Name: "units_per_hour", Ref: "Sheet1!$I:$I"}]
            worksheet = XLSX.utils.json_to_sheet(sheet_rows_004,{skipHeader: 1})
            XLSX.utils.book_append_sheet(workbook, worksheet)
            XLSX.writeFile(workbook, '004.xlsx')

            // paint 006
            sheet_rows_006.push(['Job Id:', job_number + '006'], ['Date:', current_date],['Total Amount:', cost_006], ['Total Units:',0], ['Total Hours', hours_006], [], ['Cost Type','Costcode','Description','Amount','Units','Unit Cost','Hours','Hourly Rate','Units/Hour','Hours/Unit','Unit of Measure','Client Code','Client Descr','CO Id','Key','Rev Category','Retention %'])
            for(var i = 0; i < rows_006.length;i++){
              sheet_rows_006.push(rows_006[i])
            }
            workbook = XLSX.utils.book_new()
            workbook.Workbook = {}
            workbook.Workbook.Names = [{Name: "cc", Ref: "Sheet1!$B:$B"},{Name: "client_cc", Ref: "Sheet1!$L:$L"},{Name: "client_descr", Ref: "Sheet1!$M:$M"},{Name: "co_id", Ref: "Sheet1!$N:$N"},{Name: "cost_type_cd", Ref: "Sheet1!$A:$A"},{Name: "d_hist_date", Ref: "Sheet1!$B$2"},{Name: "descr", Ref: "Sheet1!$C:$C"},{Name: "hrs", Ref: "Sheet1!$G:$G"},{Name: "hrs_per_unit", Ref: "Sheet1!$J:$J"},{Name: "job_amt", Ref: "Sheet1!$B$3"},{Name: "job_hrs", Ref: "Sheet1!$B$5"},{Name: "job_id", Ref: "Sheet1!$B$1"},{Name: "job_units", Ref: "Sheet1!$B$4"},{Name: "key_num", Ref: "Sheet1!$O:$O"},{Name: "lump_sum_amt", Ref: "Sheet1!$D:$D"},{Name: "rate", Ref: "Sheet1!$H:$H"},{Name: "ret_pct", Ref: "Sheet1!$Q:$Q"},{Name: "rev_cat_cd", Ref: "Sheet1!$P:$P"},{Name: "start_detail", Ref: "Sheet1!$8:$8"},{Name: "um", Ref: "Sheet1!$K:$K"},{Name: "unit_cost", Ref: "Sheet1!$F:$F"},{Name: "units", Ref: "Sheet1!$E:$E"},{Name: "units_per_hour", Ref: "Sheet1!$I:$I"}]
            worksheet = XLSX.utils.json_to_sheet(sheet_rows_006,{skipHeader: 1})
            XLSX.utils.book_append_sheet(workbook, worksheet)
            XLSX.writeFile(workbook, '006.xlsx')
          }
        }
      });
    },
    exportSchedule(){
      var self = this

      Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(opp_snapshot){
        var opportunity = opp_snapshot.val()
        var opportunity_name = opportunity.name

        Firebase.database().ref('quotes').child(self.opportunityid).once('value',function(snapshot){
          // var opportunity = snapshot.val()
          var quotes = snapshot.val()
          var sheet_rows = [['ACTIVITY','DURATION']]
          var workbook = XLSX.utils.book_new()
          var worksheet;

          for(var qid in quotes){
            var quote = quotes[qid]
            var quote_sheet = quote.quote_sheet
            console.log(quote_sheet)
            if((quote_sheet === 'Field' || quote_sheet === 'Electrical' || quote_sheet === 'Civil' || quote_sheet === 'ELMCO Field') && quote.show_in_master){
              if(quote.tasks){
                for(var i = 0; i < quote.tasks.length; i++){
                  var task = quote.tasks[i]
                  sheet_rows.push([quote_sheet + ' - ' + task.description, task.lead_time_shifts])
                }
              }
            }
            if(quote_sheet !== 'Field' && quote_sheet !== 'Electrical' && quote_sheet !== 'Civil' && quote_sheet !== 'Transportation' && quote_sheet !== 'ELMCO Field' && quote.show_in_master){
              sheet_rows.push([quote_sheet, quote.lead_time_shifts])
            }
          }

          workbook.Workbook = {}
          worksheet = XLSX.utils.json_to_sheet(sheet_rows,{skipHeader: 1})
          worksheet['!cols'] = [{wch:45},{wch:15},{wch:15}]
          XLSX.utils.book_append_sheet(workbook, worksheet)
          XLSX.writeFile(workbook, opportunity_name+'_schedule.xlsx')
        })

      })
    },
    sortKeysBy(unsorted_obj){
      const ordered = Object.keys(unsorted_obj).sort().reduce(
        (obj, key) => {
          obj[key] = unsorted_obj[key];
          return obj;
        },
        {}
      );
    },
    orderKeys(obj){
      var sorted_obj = {}
      var phase_keys = Object.keys(obj).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
      phase_keys.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
      for(var i = 0; i < phase_keys.length; i++){
        sorted_obj[phase_keys[i]] = obj[phase_keys[i]]
      }

      return sorted_obj
    },
    buildTable(){
      var self = this
      self.table_rows = []
      self.penta_data = {}
      self.fab_penta_data = {}
      self.summary_row = {
        equipment_cost: 0,
        equipment_revenue: 0,
        labor_hours: 0,
        labor_cost: 0,
        labor_revenue: 0,
        material_cost: 0,
        material_revenue: 0,

        travel_cost: 0,
        travel_revenue: 0,

        other_cost: 0,
        other_revenue: 0,
        subcontracting_cost: 0,
        subcontracting_revenue: 0,
        total_hours: 0,
        total_cost: 0,
        total_revenue: 0,
        margin: 0
      }

      Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){
        var opportunity = snapshot.val()
        var ou_grouping = {}
        var fab_grouping = {}
        var quotes = {}

        self.revenue_override_percentage = opportunity.revenue_override_percentage ? (opportunity.revenue_override_percentage / 100 ) : 0

        Firebase.database().ref('quotes').child(self.opportunityid).once('value',function(quotes_snapshot){
          quotes = JSON.parse(JSON.stringify(quotes_snapshot.val()))

          for(var qid in quotes){
            var elmos = quotes[qid].elmos
            var quote_sheet = quotes[qid].quote_sheet
            var ou = elmos.ou_code
            var quote_description = quotes[qid].quote_title

            if(quotes[qid].show_in_master){
              if(quote_sheet === 'Fabrication' || (quote_sheet === 'Excel' && quotes[qid].department_quote_sheet === 'Fabrication')){

                if(!fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]){
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase] = quotes[qid].fab_elmos
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase].material_cost = fab_grouping['Phase ' + quotes[qid].fab_elmos.phase].material_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase].material_revenue = fab_grouping['Phase ' + quotes[qid].fab_elmos.phase].material_revenue
                }else{

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase].material_cost += quotes[qid].fab_elmos.material_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase].material_revenue += quotes[qid].fab_elmos.material_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Prep & PM'].labor_hours += quotes[qid].fab_elmos['Prep & PM'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Prep & PM'].labor_cost += quotes[qid].fab_elmos['Prep & PM'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Prep & PM'].labor_revenue += quotes[qid].fab_elmos['Prep & PM'].labor_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Pre Fab'].labor_hours += quotes[qid].fab_elmos['Pre Fab'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Pre Fab'].labor_cost += quotes[qid].fab_elmos['Pre Fab'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Pre Fab'].labor_revenue += quotes[qid].fab_elmos['Pre Fab'].labor_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Supervision'].labor_hours += quotes[qid].fab_elmos['Supervision'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Supervision'].labor_cost += quotes[qid].fab_elmos['Supervision'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Supervision'].labor_revenue += quotes[qid].fab_elmos['Supervision'].labor_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fitting'].labor_hours += quotes[qid].fab_elmos['Fitting'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fitting'].labor_cost += quotes[qid].fab_elmos['Fitting'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fitting'].labor_revenue += quotes[qid].fab_elmos['Fitting'].labor_revenue


                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Welding'].labor_hours += quotes[qid].fab_elmos['Welding'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Welding'].labor_cost += quotes[qid].fab_elmos['Welding'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Welding'].labor_revenue += quotes[qid].fab_elmos['Welding'].labor_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fab Gen'].labor_hours += quotes[qid].fab_elmos['Fab Gen'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fab Gen'].labor_cost += quotes[qid].fab_elmos['Fab Gen'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fab Gen'].labor_revenue += quotes[qid].fab_elmos['Fab Gen'].labor_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['QC'].labor_hours += quotes[qid].fab_elmos['QC'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['QC'].labor_cost += quotes[qid].fab_elmos['QC'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['QC'].labor_revenue += quotes[qid].fab_elmos['QC'].labor_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fab Subcontracting'].subcontracting_cost += quotes[qid].fab_elmos['Fab Subcontracting'].subcontracting_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Fab Subcontracting'].subcontracting_revenue += quotes[qid].fab_elmos['Fab Subcontracting'].subcontracting_revenue

                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Paint'].labor_hours += quotes[qid].fab_elmos['Paint'].labor_hours
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Paint'].labor_cost += quotes[qid].fab_elmos['Paint'].labor_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Paint'].labor_revenue += quotes[qid].fab_elmos['Paint'].labor_revenue
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Paint'].material_cost += quotes[qid].fab_elmos['Paint'].material_cost
                  fab_grouping['Phase ' + quotes[qid].fab_elmos.phase]['Paint'].material_revenue += quotes[qid].fab_elmos['Paint'].material_revenue

                }
              }

              if(!ou_grouping[ou]){

                ou_grouping[ou] = {}
                ou_grouping[ou].quote_sheet = quote_sheet
                ou_grouping[ou].department = elmos.ou_department
                ou_grouping[ou].job_id_code = elmos.ou_code
                ou_grouping[ou].phases = self.orderKeys(elmos.phases)
                ou_grouping[ou].cost_code = 'XXXX'
                ou_grouping[ou].total_revenue = elmos.total_revenue
                ou_grouping[ou].total_cost = elmos.total_cost
                ou_grouping[ou].total_hours = elmos.total_hours


                // split out travel from 'other'
                for(var phase in ou_grouping[ou].phases){
                  ou_grouping[ou].phases[phase].travel = {
                    cost: ou_grouping[ou].phases[phase].other.travel_cost ? ou_grouping[ou].phases[phase].other.travel_cost : 0,
                    revenue: ou_grouping[ou].phases[phase].other.travel_revenue ? ou_grouping[ou].phases[phase].other.travel_revenue : 0
                  }
                  ou_grouping[ou].phases[phase].other.cost = ou_grouping[ou].phases[phase].other.cost - (ou_grouping[ou].phases[phase].other.travel_cost ? ou_grouping[ou].phases[phase].other.travel_cost : 0)
                  ou_grouping[ou].phases[phase].other.revenue = ou_grouping[ou].phases[phase].other.revenue - (ou_grouping[ou].phases[phase].other.travel_revenue ? ou_grouping[ou].phases[phase].other.travel_revenue : 0)
                }

              }else{
                ou_grouping[ou].total_revenue += elmos.total_revenue
                ou_grouping[ou].total_cost += elmos.total_cost
                ou_grouping[ou].total_hours += elmos.total_hours

                for(var phase in elmos.phases){
                    ou_grouping[ou].phases[phase].equipment.cost += elmos.phases[phase].equipment.cost
                    ou_grouping[ou].phases[phase].equipment.revenue += elmos.phases[phase].equipment.revenue
                    ou_grouping[ou].phases[phase].material.cost += elmos.phases[phase].material.cost
                    ou_grouping[ou].phases[phase].material.revenue += elmos.phases[phase].material.revenue

                    // split out travel from 'other'
                    ou_grouping[ou].phases[phase].travel.cost += elmos.phases[phase].other.travel_cost ? elmos.phases[phase].other.travel_cost : 0
                    ou_grouping[ou].phases[phase].travel.revenue += elmos.phases[phase].other.travel_revenue ? elmos.phases[phase].other.travel_revenue : 0
                    ou_grouping[ou].phases[phase].other.cost += elmos.phases[phase].other.cost - (elmos.phases[phase].other.travel_cost ? elmos.phases[phase].other.travel_cost : 0)
                    ou_grouping[ou].phases[phase].other.revenue += elmos.phases[phase].other.revenue - (elmos.phases[phase].other.travel_revenue ? elmos.phases[phase].other.travel_revenue : 0)

                    ou_grouping[ou].phases[phase].subcontracting.cost += elmos.phases[phase].subcontracting.cost
                    ou_grouping[ou].phases[phase].subcontracting.revenue += elmos.phases[phase].subcontracting.revenue
                    ou_grouping[ou].phases[phase].total_revenue += elmos.phases[phase].total_revenue
                    ou_grouping[ou].phases[phase].total_cost += elmos.phases[phase].total_cost
                    ou_grouping[ou].phases[phase].labor.cost += elmos.phases[phase].labor.cost
                    ou_grouping[ou].phases[phase].labor.hours += elmos.phases[phase].labor.hours
                    ou_grouping[ou].phases[phase].labor.revenue += elmos.phases[phase].labor.revenue
                }
              }
            }

          }

          var ou_ordered = self.orderKeys(ou_grouping)

          self.penta_data = ou_ordered
          self.fab_penta_data = fab_grouping

          for(var ou in ou_ordered){
            var phases = ou_ordered[ou].phases
            ou_ordered[ou].equipment_cost = 0
            ou_ordered[ou].equipment_revenue = 0
            ou_ordered[ou].labor_cost = 0
            ou_ordered[ou].labor_revenue = 0
            ou_ordered[ou].material_cost = 0
            ou_ordered[ou].material_revenue = 0
            ou_ordered[ou].travel_cost = 0
            ou_ordered[ou].travel_revenue = 0
            ou_ordered[ou].other_cost = 0
            ou_ordered[ou].other_revenue = 0
            ou_ordered[ou].subcontracting_cost = 0
            ou_ordered[ou].subcontracting_revenue = 0

            if(ou === '004'){

                for(var phase in fab_grouping){
                  var fab_line_labor_hours = fab_grouping[phase]['Prep & PM'].labor_hours + fab_grouping[phase]['Pre Fab'].labor_hours + fab_grouping[phase]['Supervision'].labor_hours + fab_grouping[phase]['Fitting'].labor_hours + fab_grouping[phase]['Welding'].labor_hours + fab_grouping[phase]['Fab Gen'].labor_hours + fab_grouping[phase]['QC'].labor_hours
                  var fab_line_labor_cost = fab_grouping[phase]['Prep & PM'].labor_cost + fab_grouping[phase]['Pre Fab'].labor_cost + fab_grouping[phase]['Supervision'].labor_cost + fab_grouping[phase]['Fitting'].labor_cost + fab_grouping[phase]['Welding'].labor_cost + fab_grouping[phase]['Fab Gen'].labor_cost + fab_grouping[phase]['QC'].labor_cost

                  var fab_line_labor_revenue = fab_grouping[phase]['Prep & PM'].labor_revenue + fab_grouping[phase]['Pre Fab'].labor_revenue + fab_grouping[phase]['Supervision'].labor_revenue + fab_grouping[phase]['Fitting'].labor_revenue + fab_grouping[phase]['Welding'].labor_revenue + fab_grouping[phase]['Fab Gen'].labor_revenue + fab_grouping[phase]['QC'].labor_revenue
                  var fab_line_material_cost = fab_grouping[phase].material_cost
                  var fab_line_material_revenue = fab_grouping[phase].material_revenue
                  var fab_line_total_cost = fab_line_labor_cost + fab_line_material_cost
                  var fab_line_total_revenue = fab_line_labor_revenue + fab_line_material_revenue
                  var fab_line_margin = self.calculateMargin(fab_line_total_cost,fab_line_total_revenue)
                  var paint_total_cost = fab_grouping[phase]['Paint'].labor_cost + fab_grouping[phase]['Paint'].material_cost
                  var paint_total_revenue = fab_grouping[phase]['Paint'].labor_revenue + fab_grouping[phase]['Paint'].material_revenue

                  // REVENUE OVERRIDE LOGIC
                  fab_line_labor_revenue = fab_line_labor_revenue + (fab_line_labor_revenue * self.revenue_override_percentage)
                  fab_line_material_revenue = fab_line_material_revenue + (fab_line_material_revenue * self.revenue_override_percentage)
                  fab_line_total_revenue = fab_line_total_revenue + (fab_line_total_revenue * self.revenue_override_percentage)
                  paint_total_revenue = paint_total_revenue + (paint_total_revenue * self.revenue_override_percentage)

                  // REVENUE OVERRIDE LOGIC
                  fab_grouping[phase]['Prep & PM'].labor_revenue = fab_grouping[phase]['Prep & PM'].labor_revenue + (fab_grouping[phase]['Prep & PM'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Pre Fab'].labor_revenue = fab_grouping[phase]['Pre Fab'].labor_revenue + (fab_grouping[phase]['Pre Fab'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Supervision'].labor_revenue = fab_grouping[phase]['Supervision'].labor_revenue + (fab_grouping[phase]['Supervision'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Fitting'].labor_revenue = fab_grouping[phase]['Fitting'].labor_revenue + (fab_grouping[phase]['Fitting'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Welding'].labor_revenue = fab_grouping[phase]['Welding'].labor_revenue + (fab_grouping[phase]['Welding'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Fab Gen'].labor_revenue = fab_grouping[phase]['Fab Gen'].labor_revenue + (fab_grouping[phase]['Fab Gen'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['QC'].labor_revenue = fab_grouping[phase]['QC'].labor_revenue + (fab_grouping[phase]['QC'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Fab Subcontracting'].subcontracting_revenue = fab_grouping[phase]['Fab Subcontracting'].subcontracting_revenue + (fab_grouping[phase]['Fab Subcontracting'].subcontracting_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Paint'].labor_revenue = fab_grouping[phase]['Paint'].labor_revenue + (fab_grouping[phase]['Paint'].labor_revenue * self.revenue_override_percentage)
                  fab_grouping[phase]['Paint'].material_revenue = fab_grouping[phase]['Paint'].material_revenue + (fab_grouping[phase]['Paint'].material_revenue * self.revenue_override_percentage)

                  self.table_rows.push(['004','MSM CF Fabrication', 'FABRICATING', phase, '', fab_line_labor_hours, fab_line_labor_cost, fab_line_labor_revenue,fab_line_material_cost,fab_line_material_revenue,'','','','','','',fab_line_total_cost,fab_line_total_revenue,fab_line_margin])
                  self.table_rows.push(['004','MSM CF Fabrication', '1101 - Prep / PM', phase, '', fab_grouping[phase]['Prep & PM'].labor_hours, fab_grouping[phase]['Prep & PM'].labor_cost,fab_grouping[phase]['Prep & PM'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['Prep & PM'].labor_cost, fab_grouping[phase]['Prep & PM'].labor_revenue, self.calculateMargin(fab_grouping[phase]['Prep & PM'].labor_cost,fab_grouping[phase]['Prep & PM'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', '1201 - Pre Fab', phase, '', fab_grouping[phase]['Pre Fab'].labor_hours, fab_grouping[phase]['Pre Fab'].labor_cost, fab_grouping[phase]['Pre Fab'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['Pre Fab'].labor_cost, fab_grouping[phase]['Pre Fab'].labor_revenue, self.calculateMargin(fab_grouping[phase]['Pre Fab'].labor_cost,fab_grouping[phase]['Pre Fab'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', '1301 - Supervision', phase, '', fab_grouping[phase]['Supervision'].labor_hours, fab_grouping[phase]['Supervision'].labor_cost, fab_grouping[phase]['Supervision'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['Supervision'].labor_cost, fab_grouping[phase]['Supervision'].labor_revenue, self.calculateMargin(fab_grouping[phase]['Supervision'].labor_cost,fab_grouping[phase]['Supervision'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', '1301 - Fitting', phase, '', fab_grouping[phase]['Fitting'].labor_hours, fab_grouping[phase]['Fitting'].labor_cost, fab_grouping[phase]['Fitting'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['Fitting'].labor_cost, fab_grouping[phase]['Fitting'].labor_revenue, self.calculateMargin(fab_grouping[phase]['Fitting'].labor_cost,fab_grouping[phase]['Fitting'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', '1301 - Welding', phase, '', fab_grouping[phase]['Welding'].labor_hours, fab_grouping[phase]['Welding'].labor_cost, fab_grouping[phase]['Welding'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['Welding'].labor_cost, fab_grouping[phase]['Welding'].labor_revenue, self.calculateMargin(fab_grouping[phase]['Welding'].labor_cost,fab_grouping[phase]['Welding'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', '1401 - Fab Gen', phase, '', fab_grouping[phase]['Fab Gen'].labor_hours, fab_grouping[phase]['Fab Gen'].labor_cost, fab_grouping[phase]['Fab Gen'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['Fab Gen'].labor_cost, fab_grouping[phase]['Fab Gen'].labor_revenue, self.calculateMargin(fab_grouping[phase]['Fab Gen'].labor_cost,fab_grouping[phase]['Fab Gen'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', '1401 - QC', phase, '', fab_grouping[phase]['QC'].labor_hours, fab_grouping[phase]['QC'].labor_cost, fab_grouping[phase]['QC'].labor_revenue, '', '', '', '' , '' , '', '', '', fab_grouping[phase]['QC'].labor_cost, fab_grouping[phase]['QC'].labor_revenue, self.calculateMargin(fab_grouping[phase]['QC'].labor_cost,fab_grouping[phase]['QC'].labor_revenue)])
                  self.table_rows.push(['004','MSM CF Fabrication', 'FAB SUBCONTRACTING', phase, '','','','','','','','',fab_grouping[phase]['Fab Subcontracting'].subcontracting_cost,fab_grouping[phase]['Fab Subcontracting'].subcontracting_revenue,'','',fab_grouping[phase]['Fab Subcontracting'].subcontracting_cost,fab_grouping[phase]['Fab Subcontracting'].subcontracting_revenue,self.calculateMargin(fab_grouping[phase]['Fab Subcontracting'].subcontracting_cost,fab_grouping[phase]['Fab Subcontracting'].subcontracting_revenue)])
                  self.table_rows.push(['006','MSM CF Fabrication', 'PAINT', phase, '', fab_grouping[phase]['Paint'].labor_hours, fab_grouping[phase]['Paint'].labor_cost, fab_grouping[phase]['Paint'].labor_revenue,fab_grouping[phase]['Paint'].material_cost,fab_grouping[phase]['Paint'].material_revenue, '', '', '', '' , '' , '', paint_total_cost,paint_total_revenue, self.calculateMargin(paint_total_cost,paint_total_revenue)])
                }

            }

            for(var phase in phases){
              // REVENUE OVERRIDE LOGIC
              phases[phase].labor.revenue = phases[phase].labor.revenue + (phases[phase].labor.revenue * self.revenue_override_percentage)
              phases[phase].material.revenue = phases[phase].material.revenue + (phases[phase].material.revenue * self.revenue_override_percentage)
              phases[phase].equipment.revenue = phases[phase].equipment.revenue + (phases[phase].equipment.revenue * self.revenue_override_percentage)
              phases[phase].subcontracting.revenue = phases[phase].subcontracting.revenue + (phases[phase].subcontracting.revenue * self.revenue_override_percentage)
              phases[phase].travel.revenue = phases[phase].travel.revenue + (phases[phase].travel.revenue * self.revenue_override_percentage)
              phases[phase].other.revenue = phases[phase].other.revenue + (phases[phase].other.revenue * self.revenue_override_percentage)
              phases[phase].total_revenue = phases[phase].total_revenue + (phases[phase].total_revenue * self.revenue_override_percentage)

              if(ou !== '004' && (phases[phase].total_revenue || phases[phase].total_cost)){
                self.table_rows.push([
                                ou_ordered[ou].job_id_code,
                                ou_ordered[ou].department,
                                ou_ordered[ou].quote_sheet,
                                phase,
                                quote_description,
                                phases[phase].labor.hours,
                                phases[phase].labor.cost,
                                phases[phase].labor.revenue,
                                phases[phase].material.cost,
                                phases[phase].material.revenue,
                                phases[phase].equipment.cost,
                                phases[phase].equipment.revenue,
                                phases[phase].subcontracting.cost,
                                phases[phase].subcontracting.revenue,
                                phases[phase].travel.cost,
                                phases[phase].travel.revenue,
                                phases[phase].other.cost,
                                phases[phase].other.revenue,
                                phases[phase].total_cost,
                                phases[phase].total_revenue,
                                self.calculateMargin(phases[phase].total_cost, phases[phase].total_revenue),
                                ''
                              ])
              }

              ou_ordered[ou].equipment_cost += phases[phase].equipment.cost
              ou_ordered[ou].equipment_revenue += phases[phase].equipment.revenue
              ou_ordered[ou].labor_cost += phases[phase].labor.cost
              ou_ordered[ou].labor_revenue += phases[phase].labor.revenue
              ou_ordered[ou].material_cost += phases[phase].material.cost
              ou_ordered[ou].material_revenue += phases[phase].material.revenue

              ou_ordered[ou].travel_cost += phases[phase].travel.cost
              ou_ordered[ou].travel_revenue += phases[phase].travel.revenue

              ou_ordered[ou].other_cost += phases[phase].other.cost
              ou_ordered[ou].other_revenue += phases[phase].other.revenue
              ou_ordered[ou].subcontracting_cost += phases[phase].subcontracting.cost
              ou_ordered[ou].subcontracting_revenue += phases[phase].subcontracting.revenue

              self.summary_row.labor_hours += phases[phase].labor.hours
              self.summary_row.labor_cost += phases[phase].labor.cost
              self.summary_row.labor_revenue += phases[phase].labor.revenue
              self.summary_row.equipment_cost += phases[phase].equipment.cost
              self.summary_row.equipment_revenue += phases[phase].equipment.revenue
              self.summary_row.material_cost += phases[phase].material.cost
              self.summary_row.material_revenue += phases[phase].material.revenue

              self.summary_row.travel_cost += phases[phase].travel.cost
              self.summary_row.travel_revenue += phases[phase].travel.revenue
              self.summary_row.other_cost += phases[phase].other.cost
              self.summary_row.other_revenue += phases[phase].other.revenue

              self.summary_row.subcontracting_cost += phases[phase].subcontracting.cost
              self.summary_row.subcontracting_revenue += phases[phase].subcontracting.revenue
              self.summary_row.total_hours += phases[phase].labor.hours
              self.summary_row.total_cost += phases[phase].total_cost
              self.summary_row.total_revenue += phases[phase].total_revenue
              self.summary_row.margin = self.calculateMargin(self.summary_row.total_cost,self.summary_row.total_revenue)
            }

            self.table_rows.push([
                                  ou,
                                  ou_ordered[ou].department,
                                  '',
                                  '',
                                  '',
                                  ou_ordered[ou].total_hours,
                                  ou_ordered[ou].labor_cost,
                                  ou_ordered[ou].labor_revenue,
                                  ou_ordered[ou].material_cost,
                                  ou_ordered[ou].material_revenue,
                                  ou_ordered[ou].equipment_cost,
                                  ou_ordered[ou].equipment_revenue,
                                  ou_ordered[ou].subcontracting_cost,
                                  ou_ordered[ou].subcontracting_revenue,
                                  ou_ordered[ou].travel_cost,
                                  ou_ordered[ou].travel_revenue,
                                  ou_ordered[ou].other_cost,
                                  ou_ordered[ou].other_revenue,
                                  ou_ordered[ou].total_cost,
                                  ou_ordered[ou].total_revenue + (ou_ordered[ou].total_revenue * self.revenue_override_percentage), // REVENUE OVERRIDE LOGIC
                                  self.calculateMargin(ou_ordered[ou].total_cost, ou_ordered[ou].total_revenue + (ou_ordered[ou].total_revenue * self.revenue_override_percentage)), // REVENUE OVERRIDE LOGIC
                                  ''
                                ])

          }

          self.$forceUpdate();

        })
      })
    }
  }
}
</script>

<style lang="scss">

  .master-summary{

  }

  .sum_row{
    background: #ddd;
    font-weight:bold;
  }
  .sum_row:hover{
    background: #ddd !important;
    font-weight:bold;
  }
  .total_row{
    background: #eee;
    font-weight: bold;
  }
  .total_row:hover{
    background: #eee !important;
  }

  .inactive-column-btn{
    padding-bottom: 2px;
    border-bottom: 2px solid white;
  }

  .active-column-btn{
    padding-bottom: 2px;
    border-bottom: 2px solid #223773;
  }

  .detail-link{
    margin-top: 25px;
    color: #223773;
    font-size: 10px;
    padding: 0px 10px;
    cursor: pointer;
    font-weight: bold;
  }

  .filter-link{
    color: #223773;
    font-size: 10px;
    padding: 0px 10px;
    cursor: pointer;
    font-weight: bold;
  }
</style>


