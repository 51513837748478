import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Accounts from '../views/Accounts.vue'
import Contacts from '../views/Contacts.vue'
import Opportunities from '../views/Opportunities.vue'
import Users from '../views/Users.vue'
import Reports from '../views/Reports.vue'
import Account from '../views/Account.vue'
import Contact from '../views/Contact.vue'
import Opportunity from '../views/Opportunity.vue'
import User from '../views/User.vue'
import Rates from '../views/Rates.vue'
import Admin from '../views/Admin.vue'
import TransferOwnership from '../views/TransferOwnership.vue'
import ManageReports from '../views/ManageReports.vue'
import MSA from '../views/MSA.vue'
// import PageNotFound from '../views/PageNotFound.vue'
import Firebase from 'firebase/app'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: Opportunities,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rates',
    name: 'Rates',
    component: Rates,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transfer',
    name: 'TransferOwnership',
    component: TransferOwnership,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage-reports',
    name: 'ManageReports',
    component: ManageReports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/:accountId',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact/:contactId',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/opportunity/:opportunityId',
    name: 'Opportunity',
    component: Opportunity,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/:userId',
    name: 'User',
    component: User,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/msa',
    name: 'MSA',
    component: MSA,
    meta: {
      requiresAuth: true
    }
  },  
  // {
  //   path: '*',
  //   name: 'PageNotFound',
  //   component: PageNotFound
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await Firebase.getCurrentUser()){
    next('/');
  }else{
    next();
  }
});

export default router
