<template>
  <div class="msa">
    <v-container fluid>
      <v-card>
        <v-card-text>
          <div class="dropzone bg-gray" @dragover="dragover" @dragleave="dragleave" @drop="drop" v-if="!fileslist.length">
            <input type="file" @change="onChange" ref="file" style="opacity: 0;display:none"/>
            <div v-if="!currentFile" style="position:relative;top:25%;text-align:center;">
              Drag and Drop MSA Rates File Here
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card style="margin-top:25px">
        <v-card-text>
          <div style="font-size:18px">MSA RATES</div>

          <div style="margin-top: 10px;">
            <v-autocomplete dense hide-details="auto" :items="msas" outlined label="SEARCH FOR MSA" item-text="name" v-model="selected_msa" return-object></v-autocomplete>
            <div>{{selected_msa.id}}</div>
          </div>
          
          <div v-if="selected_msa" style="margin-top: 20px;">

            <div style="padding:5px 0px">
              <div class="rate-title">Markups</div>
              <table>
                <thead>
                  <th>Equipment</th>
                  <th>Material Owned</th>
                  <th>Material Rental</th>
                  <th>Subcontractors</th>
                  <th>Travel</th>
                  <th>Per Diem</th>
                </thead>

                <tbody>
                  <tr>
                    <td>{{selected_msa_rates.markups.equipment}}%</td>
                    <td>{{selected_msa_rates.markups.material_owned}}%</td>
                    <td>{{selected_msa_rates.markups.material_rental}}%</td>
                    <td>{{selected_msa_rates.markups.subcontractor}}%</td>
                    <td>{{selected_msa_rates.markups.travel}}%</td>
                    <td>{{selected_msa_rates.markups.per_diem}}%</td>
                  </tr>                                      
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Fuel Cost Rates</div>
              <table>
                <thead>
                  <th>Air Compressor</th>
                  <th>Forklift</th>
                  <th>Manlift</th>
                  <th>Welding</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.fuel_cost_rates.air_compressor)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.fuel_cost_rates.forklift)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.fuel_cost_rates.manlift)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.fuel_cost_rates.welding)}}</td>
                  </tr>                                        
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Per Diem Rates</div>
              <table>
                <thead>
                  <th>Hotels</th>
                  <th>Meals</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.per_diem_rates.hotels)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.per_diem_rates.meals)}}</td>
                  </tr>                                     
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Rental In/Out Charges</div>
              <table>
                <thead>
                  <th></th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.rental_in_out_charges)}}</td>
                  </tr>                                     
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Small Tools & Consumables</div>
              <table>
                <thead>
                  <th>Small Tools</th>
                  <th>Consumables</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{selected_msa_rates.small_tool_consumables_rates.small_tools_cost_rate}}% of Labor Revenue</td>
                    <td>{{selected_msa_rates.small_tool_consumables_rates.consumables_cost_rate}}% of Labor Revenue</td>
                  </tr>                                     
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Travel Rates</div>
              <table>
                <thead>
                  <th>Project Manager</th>
                  <th>General Superintendent</th>
                  <th>Foreman</th>
                  <th>Journeymen</th>
                  <th>Helper/FW/HW</th>
                  <th>Safety Officer</th>
                  <th>QAQC</th>
                  <th>Specialty Craft</th>
                </thead>                 
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.project_manager)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.general_superintendent)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.foreman)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.journeymen)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.helper)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.safety_officer)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.qaqc)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.travel_rates.specialty_craft)}}</td>
                  </tr>                                                                
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Truck Rates</div>
              <table>
                <thead>
                  <th>Pickup Truck Revenue Per Truck</th>
                  <th>Pickup Truck Revenue Per Mile</th>
                  <th>Pickup Truck Cost Per Mile</th>
                  <th>Service Truck Revenue Per Truck</th>
                  <th>Service Truck Revenue Per Mile</th>
                  <th>Service Truck Cost Per Mile</th>
                </thead>                 
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.truck_rates.pickup_truck_revenue_per_truck)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.truck_rates.pickup_truck_revenue_per_mile)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.truck_rates.pickup_truck_cost_per_mile)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.truck_rates.service_truck_revenue_per_truck)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.truck_rates.service_truck_revenue_per_mile)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.truck_rates.service_truck_cost_per_mile)}}</td>                    
                  </tr>                                                                
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Labor Cost Rates</div>
              <table>
                <thead>
                  <th>Standard Time</th>
                  <th>Overtime</th>
                  <th>Double Time</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.labor_cost_rates.st)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_cost_rates.ot)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_cost_rates.dt)}}</td>
                  </tr>                                     
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Labor Night Shift Differentials</div>
              <table>
                <thead>
                  <th>Standard Time</th>
                  <th>Overtime</th>
                  <th>Double Time</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{formatCurrency(selected_msa_rates.labor_night_shift_differentials.st)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_night_shift_differentials.ot)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_night_shift_differentials.dt)}}</td>
                  </tr>                                     
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Labor Revenue Rates</div>
              <table>
                <thead>
                  <th></th>
                  <th>Project Manager</th>
                  <th>General Superintendent</th>
                  <th>Foreman</th>
                  <th>Journeymen</th>
                  <th>Helper/FW/HW</th>
                  <th>Safety Officer</th>
                  <th>QAQC</th>
                </thead>                 
                <tbody>
                  <tr>
                    <td style="font-weight: bold">Standard Time</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].project_manager)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].general_superintendent)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].foreman)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].journeymen)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].helper)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].safety_officer)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['ST'].qaqc)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Overtime</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].project_manager)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].general_superintendent)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].foreman)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].journeymen)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].helper)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].safety_officer)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['OT'].qaqc)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Double Time</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].project_manager)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].general_superintendent)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].foreman)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].journeymen)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].helper)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].safety_officer)}}</td>
                    <td>{{formatCurrency(selected_msa_rates.labor_revenue_rates['DT'].qaqc)}}</td>
                  </tr>                                                                  
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Equipment Rates</div>
              <table>
                <thead>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Owner</th>
                  <th>Daily Cost</th>
                  <th>Weekly Cost</th>
                  <th>Monthly Cost</th>
                </thead>

                <tbody>
                  <tr v-for="rate in selected_msa_rates.equipment_rates">
                    <td>{{rate.description}}</td>
                    <td>{{rate.category}}</td>
                    <td>{{rate.owner}}</td>
                    <td>{{formatCurrency(rate.cost_daily)}}</td>
                    <td>{{formatCurrency(rate.cost_weekly)}}</td>
                    <td>{{formatCurrency(rate.cost_monthly)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="padding:5px 0px">
              <div class="rate-title">Material Rates</div>
              <table>
                <thead>
                  <th>Item</th>
                  <th>Cost</th>
                </thead>
                <tbody>
                  <tr v-for="rate in selected_msa_rates.material_rates">
                    <td>{{rate.item}}</td>
                    <td>{{formatCurrency(rate.cost)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import XLSX from 'xlsx'
import { formatCurrency } from './../mixins/formatCurrency.js'

export default {
  name: 'msa',
  components: {},
  mixins: [formatCurrency],
  data(){
    return{
      currentFile: null,
      fileslist: [],
      selected_msa: false,
      selected_msa_rates: {}
    }
  },
  beforeMount(){
    if(!this.is_admin){
      this.$router.push('/home')
    }
  },
  watch: {
    'selected_msa': function(val){
      this.selected_msa_rates = this.db.msa_rates[this.selected_msa.id]
    },
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      is_admin: 'is_admin',
      msas: 'msas',
    })
  },
  methods:{
    readFileData(){
      var reader = new FileReader();
      var self = this
      var msa_database = {}

      reader.readAsBinaryString(this.fileslist[0]);

      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {type: 'binary'})

        var msa_ids_tab = workbook.Sheets['DATABASE_MSA_IDS']
        var equipment_tab = workbook.Sheets['MSA_EQUIPMENT']
        var material_tab = workbook.Sheets['MSA_MATERIAL']
        var labor_tab = workbook.Sheets['MSA_LABOR']
        var misc_tab = workbook.Sheets['MSA_MISC']

        var msa_ids_json;
        var equipment_json;
        var material_json;
        var labor_json;
        var misc_json;

        if(!msa_ids_tab){
          self.isLoading = false
          alert('Invalid File')
          return
        }

        msa_ids_json = XLSX.utils.sheet_to_json(msa_ids_tab)
        for(var i = 0; i < msa_ids_json.length; i++){
          var row_data = msa_ids_json[i]
          if(row_data.msa){
            msa_database[row_data.id] = {
              'name': row_data.msa,
              'equipment_rates': [],
              'material_rates': [],              
              'labor_cost_rates': {'st':0,'ot':0,'dt':0},
              'labor_night_shift_differentials': {'st':0,'ot':0,'dt':0},
              'labor_revenue_rates': {'ST': {'foreman': 0, 'general_superintendent': 0, 'helper': 0, 'journeymen': 0, 'project_manager': 0, 'qaqc': 0, 'safety_officer': 0},'OT': {'foreman': 0, 'general_superintendent': 0, 'helper': 0, 'journeymen': 0, 'project_manager': 0, 'qaqc': 0, 'safety_officer': 0},'DT': {'foreman': 0, 'general_superintendent': 0, 'helper': 0, 'journeymen': 0, 'project_manager': 0, 'qaqc': 0, 'safety_officer': 0}},
              'markups': {'equipment': 0,'material_owned': 0,'material_rental': 0,'subcontractor': 0,'per_diem': 0,'travel': 0},
              'rental_in_out_charges': 0,
              'small_tool_consumables_rates':{'consumables_cost_rate':0,'small_tools_cost_rate':0},
              'fuel_cost_rates': {'air_compressor': 0,'forklift': 0,'manlift': 0,'welding': 0},              
              'per_diem_rates': {'hotels': 0,'meals': 0},
              'travel_rates': {'foreman': 0, 'general_superintendent': 0, 'helper': 0, 'journeymen': 0, 'project_manager': 0, 'qaqc': 0, 'safety_officer': 0, 'specialty_craft': 0},
              'truck_rates': {'pickup_truck_cost_per_mile': 0, 'pickup_truck_revenue_per_mile': 0, 'pickup_truck_revenue_per_truck': 0, 'service_truck_cost_per_mile': 0, 'service_truck_revenue_per_mile': 0, 'service_truck_revenue_per_truck': 0}
            } 
          }
        }

        equipment_json = XLSX.utils.sheet_to_json(equipment_tab)
        for(var i = 0; i < equipment_json.length; i++){
          var row_data = equipment_json[i]
          msa_database[row_data.msa_id].equipment_rates.push({'category':row_data.category,'description':row_data.description,'cost_daily':row_data.cost_daily,'cost_weekly':row_data.cost_weekly,'cost_monthly':row_data.cost_monthly,'owner':row_data.owner})
        }

        material_json = XLSX.utils.sheet_to_json(material_tab)
        for(var i = 0; i < material_json.length; i++){
          var row_data = material_json[i]
          msa_database[row_data.msa_id].material_rates.push({'item':row_data.item,'cost':row_data.cost})
        }

        labor_json = XLSX.utils.sheet_to_json(labor_tab)
        for(var i = 0; i < labor_json.length; i++){
          var row_data = labor_json[i]
          msa_database[row_data.msa_id].labor_cost_rates.st = row_data.st_cost
          msa_database[row_data.msa_id].labor_cost_rates.ot = row_data.ot_cost
          msa_database[row_data.msa_id].labor_cost_rates.dt = row_data.dt_cost

          msa_database[row_data.msa_id].labor_night_shift_differentials.st = row_data.st_night_diff
          msa_database[row_data.msa_id].labor_night_shift_differentials.ot = row_data.ot_night_diff
          msa_database[row_data.msa_id].labor_night_shift_differentials.dt = row_data.dt_night_diff

          msa_database[row_data.msa_id].labor_revenue_rates['ST'] = {
            foreman: row_data.st_foreman_revenue,
            general_superintendent: row_data.st_general_superintendent_revenue,
            helper: row_data.st_helper_revenue,
            journeymen: row_data.st_journeymen_revenue,
            project_manager: row_data.st_project_manager_revenue,
            qaqc: row_data.st_qaqc_revenue,
            safety_officer: row_data.st_safety_officer_revenue,
          }

          msa_database[row_data.msa_id].labor_revenue_rates['OT'] = {
            foreman: row_data.ot_foreman_revenue,
            general_superintendent: row_data.ot_general_superintendent_revenue,
            helper: row_data.ot_helper_revenue,
            journeymen: row_data.ot_journeymen_revenue,
            project_manager: row_data.ot_project_manager_revenue,
            qaqc: row_data.ot_qaqc_revenue,
            safety_officer: row_data.ot_safety_officer_revenue,
          }

          msa_database[row_data.msa_id].labor_revenue_rates['DT'] = {
            foreman: row_data.dt_foreman_revenue,
            general_superintendent: row_data.dt_general_superintendent_revenue,
            helper: row_data.dt_helper_revenue,
            journeymen: row_data.dt_journeymen_revenue,
            project_manager: row_data.dt_project_manager_revenue,
            qaqc: row_data.dt_qaqc_revenue,
            safety_officer: row_data.dt_safety_officer_revenue,
          }          
        }

        misc_json = XLSX.utils.sheet_to_json(misc_tab)
        for(var i = 0; i < misc_json.length; i++){
          var row_data = misc_json[i]
          
          msa_database[row_data.msa_id].markups = {
            equipment: row_data.equipment_markup,
            material_owned: row_data.material_owned_markup,
            material_rental: row_data.material_rental_markup,
            subcontractor: row_data.subcontractor_markup,
            per_diem: row_data.per_diem_markup,
            travel: row_data.travel_markup,
          }

          msa_database[row_data.msa_id].rental_in_out_charges = row_data.rental_in_out

          msa_database[row_data.msa_id].small_tool_consumables_rates = {
            consumables_cost_rate: row_data.consumables_cost,
            small_tools_cost_rate: row_data.small_tools_cost
          }

          msa_database[row_data.msa_id].fuel_cost_rates = {
            air_compressor: row_data.fuel_air_compressor,
            forklift: row_data.fuel_forklift,
            manlift: row_data.fuel_manlift,
            welding: row_data.fuel_welding
          }

          msa_database[row_data.msa_id].per_diem_rates = {
            hotels: row_data.per_diem_hotel,
            meals: row_data.per_diem_meals
          }

          msa_database[row_data.msa_id].travel_rates = {
            foreman: row_data.travel_foreman,
            general_superintendent: row_data.travel_general_superintendent,
            helper: row_data.travel_helper,
            journeymen: row_data.travel_journeymen,
            project_manager: row_data.travel_project_manager,
            qaqc: row_data.travel_qaqc,
            safety_officer: row_data.travel_safety_officer,
            specialty_craft: row_data.travel_specialty_craft
          }

          msa_database[row_data.msa_id].truck_rates = {
            pickup_truck_cost_per_mile: row_data.pickup_truck_cost_per_mile,
            pickup_truck_revenue_per_mile: row_data.pickup_truck_revenue_per_mile,
            pickup_truck_revenue_per_truck: row_data.pickup_truck_revenue_per_truck,
            service_truck_cost_per_mile: row_data.service_truck_cost_per_mile,
            service_truck_revenue_per_mile: row_data.service_truck_revenue_per_mile,
            service_truck_revenue_per_truck: row_data.service_truck_revenue_per_truck
          }
        }

        Firebase.database().ref('msa_rates').remove()
        Firebase.database().ref('msa_rates').set(msa_database)

      }
    },
    onChange() {
      var newFiles = [...this.$refs.file.files];
      if(newFiles.length > 1){
        alert('Only one file allowed')
        return
      }      
      newFiles = _.sortBy(newFiles, (obj) => parseInt(obj.name, 10));
      this.fileslist = this.fileslist || [];
      this.fileslist = [...this.fileslist, ...newFiles];
      this.readFileData()
    },
    removeFile(i) {
      this.fileslist.splice(i, 1);
    },    
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-active')) {
        event.currentTarget.classList.remove('bg-gray');
        event.currentTarget.classList.add('bg-active');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray');
      event.currentTarget.classList.remove('bg-active');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-gray');
      event.currentTarget.classList.remove('bg-active');
      this.onChange();
    },
  }
}
</script>

<style lang="scss" scoped>
  .dropzone{
    position: relative;
    display: table-cell;
    top: 0px;
    width: 450px;
    height: 50px;
    border-radius: 4px;
  }

  .bg-gray{
    background: white;
    color: #223773;
    border: 2px dashed #223773;
  }

  .bg-active{
    background: #223773;
    border: 2px dashed white;
    color: white;
  }
  .handle{
    cursor:pointer
  }

  th{
    text-align:left;
    font-size: 12px;
    padding-right:50px;
  }

  td{
    font-size:12px;
    line-height:110%;
    padding-right:50px;
    text-align:left;
  }
  .rate-title{
    font-weight:bold;background:#223773;color:white;padding-left:5px
  }
</style>


