<template>
      <v-dialog scrollable persistent v-model="dialog" width="800">
        <v-form ref="form" lazy-validation>
          <v-card>
            <div class="form-header">Log a Call</div>

              <v-container style="position:relative">
                <v-row class="inputs-container">

                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete sort="name" :disabled="lockaccount" required :rules="accountRules" :items="active_accounts" v-model="logged_call.account" item-text="name" item-value="id" label="*Account" dense outlined></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete sort="first_name" :items="active_contacts_for_selected_account" v-model="logged_call.contact" item-text="name" item-value="id" label="Contact Name" dense outlined>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No contacts available for selected account.
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-combobox dense :items="['Call', 'Email','Lunch', 'Send Letter', 'Send Quote', 'Other']" outlined label="*Type" v-model="logged_call.subject"></v-combobox>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea dense label="*Comments" required :rules="commentsRules" outlined v-model="logged_call.comments"></v-textarea>
                  </v-col>

                </v-row>
              </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">Cancel</v-btn>
              <v-btn color="primary" @click="saveCall()">Save</v-btn>
            </v-card-actions>

          </v-card>
        </v-form>
      </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';

  export default {
    name: 'LogCallForm',
    props: {
      accountid: String,
      lockaccount: Boolean
    },
    data(){
      return{
        dialog: true,
        valid: false,
        logged_call: {
          account: '',
          contact: '',
          subject: 'Call',
          comments: ''
        },
        accountRules: [
          v => !!v || 'Account is required'
        ],
        commentsRules: [
          v => !!v || 'Comments are required'
        ],
      }
    },
    beforeMount(){
      if(this.accountid){
        this.logged_call.account = this.accountid
      }
    },
    watch: {
      'logged_call.account': function(){
        this.logged_call.contact = ''
      }
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        active_accounts: 'active_accounts',
        active_contacts: 'active_contacts'
      }),
      active_contacts_for_selected_account(){
        var contacts = this.active_contacts
        var arr = []
        for(var i = 0; i < contacts.length; i++){
          if(contacts[i].account === this.logged_call.account){
            arr.push(contacts[i])
          }
        }
        return arr
      }
    },
    methods: {

      saveCall(){
        var self = this

        self.$store.state.isLoading = true

        self.$refs.form.validate()

        if(self.logged_call.comments === ''){
          self.$store.state.notification = 'Please complete required fields.'
          self.$store.state.isLoading = false
          return
        }

        self.logged_call.added_by = self.current_user
        self.logged_call.created_date = Date.now()

        Firebase.database().ref('logged_calls').push(self.logged_call).then(function(snapshot){
          Firebase.database().ref('logged_calls').child(snapshot.key).child('id').set(snapshot.key).then(function(){
            self.$store.state.notification = 'Call successfully logged.'
            self.$store.state.isLoading = false
            self.$emit('close')
          })
        })


      }
    }
  }
</script>
<style lang="scss">

</style>

