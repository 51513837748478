import Firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

// DEVELOPMENT - this DB is used by the training environment (at least until a
// dedicated dev DB is created) at train.ms-ops.net which pulls code from
// the S3 bucket called train.ms-ops

// const firebaseApp = Firebase.initializeApp({
//   apiKey: "AIzaSyBsAhIsnKd5_2ff548vpkDC60c7-aPzlJ4",
//   authDomain: "ms-ops-dev.firebaseapp.com",
//   databaseURL: "https://ms-ops-dev-default-rtdb.firebaseio.com/",
//   storageBucket: "gs://ms-ops-dev.appspot.com"
// });

// TEST - this database is used by test environment at test.ms-ops.net
// which pulls code from S3 bucket test.ms-ops

const firebaseApp = Firebase.initializeApp({
  apiKey: "AIzaSyCCLU2lFdvgpBZj6HkYAd9u1L3kTTvafCA",
  authDomain: "ms-ops-test.firebaseapp.com",
  databaseURL: "https://ms-ops-test-86dbc-default-rtdb.firebaseio.com/",
  storageBucket: "gs://ms-ops-test-86dbc.appspot.com",
});

// PRODUCTION
// const firebaseApp = Firebase.initializeApp({
//   apiKey: "AIzaSyAIaCVkXGG7NjMB-ox1yvjphTgVra3ry84",
//   authDomain: "ms-ops-production-default-rtdb.firebaseapp.com",
//   databaseURL: "https://ms-ops-production-default-rtdb.firebaseio.com/",
//   storageBucket: "gs://ms-ops-production.appspot.com"
// });
