<template>
  <div>
    <div style="padding:0px 0px 0px 0px;font-size:18px;">My Assigned Estimates</div>
    <span style="padding:0px 0px 0px 0px;font-size:10px;font-style:italic">Opportunities assigned to me that are in Estimating or Quote Revision stage</span>
      <v-data-table dense style="height: 385px;overflow-y:auto" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="[{text: 'Opportunity', value: 'opportunity'},{text: 'Assigned Department', value: 'department'},{text: 'Stage', value: 'stage'},{text: 'Quote Number', value: 'quote_number'},{text: 'Due Date', value: 'due_date'}]" :items="data" :items-per-page="-1" hide-default-footer fixed-header>
        <template v-slot:item="row">
            <tr>
              <td style="font-size:10px !important"><span style="color:#223773;cursor: pointer;" @click="$router.push({name: 'Opportunity', params: {opportunityId: row.item.opportunity_id}})">{{row.item.opportunity}}</span></br><span style="font-size:10px;font-style: italic">{{row.item.account}}</span></td>
              <td style="font-size:10px !important">{{row.item.department}}</td>
              <td style="font-size:10px !important">{{row.item.stage}}</td>
              <td style="font-size:10px !important">{{row.item.quote_number}}</td>
              <td style="font-size:10px !important">{{ formatDate(row.item.due_date) }}</td>
            </tr>
        </template>
      </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'

export default {
  props: {
    data: Array
  },
  components: {},
  created() {},
  data() {
    return {
      sortBy: 'due_date',
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  watch:{

  },
  methods: {
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    }
  }
};
</script>
