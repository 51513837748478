<template>
      <v-dialog v-model="id" scrollable persistent max-width="1100px;">

        <v-form ref="form" lazy-validation>
          <v-card>
            <div class="form-header">{{id === 'new' ? 'New' : 'Edit'}} User</div>
            <v-card-text>
              <v-container style="position:relative">

                <div class="form-banner">User Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field dense @focus="$event.target.select()" label="*First Name" required outlined :rules="first_nameRules" v-model="form_data.first_name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field dense @focus="$event.target.select()" label="*Last Name" required outlined :rules="last_nameRules" v-model="form_data.last_name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select dense :items="['Administrator', 'Sales', 'Estimating']" outlined label="*Role" :rules="roleRules" v-model="form_data.role"></v-select>
                  </v-col>
                </v-row>

                <v-row class="inputs-container" v-show="id === 'new'">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="*Email" required outlined :rules="id === 'new' ? emailRules : []" v-model="form_data.email"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="*Password" required outlined :rules="id === 'new' ? passwordRules : []" v-model="form_data.password"></v-text-field>
                  </v-col>
                </v-row>

                <div style="text-align:left;font-size:12px">*indicates required field</div>

              </v-container>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">Cancel</v-btn>
              <v-btn color="primary" @click="saveNewUser()" v-if="id === 'new'">Save</v-btn>
              <v-btn color="primary" @click="saveEditUser()" v-if="id !== 'new'">Save</v-btn>
            </v-card-actions>
          </v-card>

        </v-form>
      </v-dialog>


</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';
  import axios from 'axios'
  import qs from 'qs'

  export default {
    name: 'AccountForm',
    props: {
      id: String
    },
    data(){
      return{
    searchInput: "",
        form_data: {
          'first_name': '',
          'last_name': '',
          'email': '',
          'password': '',
          'role': '',
          'owner': ''
        },
        // valid: false,
        first_nameRules: [
          v => !!v || 'First Name is required'
        ],
        last_nameRules: [
          v => !!v || 'Last Name is required'
        ],
        roleRules: [
          v => !!v || 'Role is required'
        ],
        emailRules: [
          v => !!v || 'Email is required'
        ],
        passwordRules: [
          v => !!v || 'Password is required'
        ]
      }
    },
    mounted(){
      if(this.id !== 'new'){
        this.form_data = JSON.parse( JSON.stringify( this.db.users[this.id] ) )
      }else{
        this.form_data.owner = this.current_user
      }
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        users_function_url: 'users_function_url'
      }),
    },
    methods: {
      saveNewUser(){
        var self = this
        var url = this.users_function_url+'addUser?first_name='
                  +this.form_data.first_name
                  +'&last_name='+this.form_data.last_name
                  +'&email='+this.form_data.email
                  +'&password='+this.form_data.password
                  +'&role='+this.form_data.role
                  +'&owner='+this.current_user
                  +'&created_date='+Date.now()
                  +'&added_by='+this.current_user

        var headers = {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'}

        var is_valid = self.$refs.form.validate()

        if(! is_valid){
          self.$store.state.notification = 'Please complete required fields.'
          return
        }

        self.$store.state.isLoading = true

        axios.post(url, {headers})
        .then(function (response) {
          self.$store.state.isLoading = false
          if(response.data.success){
            self.$store.state.notification = response.data.success.message
            self.$emit('close')
          }else if(response.data.error){
            self.$store.state.notification = response.data.error.message
          }else{
            self.$store.state.notification = 'Unknown error adding user.'
          }
        })
        .catch(function (error) {
          self.$store.state.isLoading = false
          self.$store.state.notification = 'Unknown error adding user.'
        });

      },
      saveEditUser(){
        var self = this
        var is_valid = self.$refs.form.validate()
        if(! is_valid){
          self.$store.state.notification = 'Please complete required fields.'
          return
        }
        self.$store.state.isLoading = true
        Firebase.database().ref('users').child(self.id).child('first_name').set(self.form_data.first_name)
        Firebase.database().ref('users').child(self.id).child('last_name').set(self.form_data.last_name)
        Firebase.database().ref('users').child(self.id).child('role').set(self.form_data.role)
        self.$store.state.notification = 'User successfully updated.'
        self.$store.state.isLoading = false
        self.$emit('close')
      }
    }
  }
</script>
<style lang="scss">

</style>

