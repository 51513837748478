<template>
    <div>
      <v-dialog v-model="id" scrollable persistent max-width="1100px;">

        <v-form ref="form" lazy-validation>
          <v-card>

            <div class="form-header">{{id === 'new' ? 'New' : 'Edit'}} Opportunity</div>

            <v-card-text>

              <v-container style="position:relative;max-width:2500px">

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="3">
                      <!-- <v-select dense :items="['Opportunity Identification', 'Assigning Quote #', 'Estimating', 'Review & Assembly', 'Submitting Proposal', 'Quote Revision', 'Customer Evaluating', 'Closed Won', 'Closed Lost']" outlined label="Stage" v-model="form_data.stage" :disabled="id === 'new'"></v-select> -->
                      <v-select dense :items="['Opportunity Identification', 'Assigning Quote #', 'Estimating', 'Review & Assembly', 'Submitting Proposal', 'Quote Revision', 'Customer Evaluating', 'Closed Won', 'Closed Lost']" outlined label="Stage" v-model="form_data.stage"></v-select>
                  </v-col>
                </v-row>

                <div class="form-banner">Opportunity Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="*Opportunity Name" outlined v-model="form_data.name" :rules="requiredRules" autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-autocomplete sort="name" v-model="form_data.owner" :items="active_users" item-text="name" item-value="id" label="*Opportunity Owner" dense outlined :rules="requiredRules"></v-autocomplete>
                    <div v-if="form_data.owner !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('owner')">Clear Selected</div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-autocomplete sort="name" v-model="form_data.account" :items="active_accounts" item-text="name" item-value="id" label="*Account Name" @change="form_data.contact_to_send_quote = ''"dense outlined :rules="requiredRules"></v-autocomplete>
                    <div v-if="form_data.account === ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="new_account = true">+ Add Account</div>
                    <div v-if="form_data.account !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('account')">Clear Selected</div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-menu v-model="close_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form_data.close_date" label="*Close/Award Date" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off" :rules="requiredRules"></v-text-field>
                      </template>
                      <v-date-picker v-model="form_data.close_date" class="mt-4" @input="close_date_menu = false"></v-date-picker>
                    </v-menu>
                    <div v-if="form_data.close_date !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('close_date')">Clear Selected</div>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-autocomplete sort="first_name" v-model="form_data.contact_to_send_quote" :items="active_contacts_for_selected_account" item-text="name" item-value="id" no-data-text="No contacts for selected account name" :label="(!form_data.salesforce_opportunity ? '*' : '') + 'Contact to Send Quote'" dense outlined :rules="!form_data.salesforce_opportunity ? requiredRules : []"></v-autocomplete>
                    <div v-if="form_data.contact_to_send_quote === ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="new_contact = true">+ Add Contact</div>
                    <div v-if="form_data.contact_to_send_quote !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('contact_to_send_quote')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-menu v-model="project_start_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form_data.project_start_date" :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') + 'Project Start Date'" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-text-field>
                      </template>
                      <v-date-picker v-model="form_data.project_start_date" class="mt-4" @input="project_start_date_menu = false"></v-date-picker>
                    </v-menu>
                    <div v-if="form_data.project_start_date !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('project_start_date')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-menu v-model="project_end_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form_data.project_end_date" :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') + 'Project End Date'" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-text-field>
                      </template>
                      <v-date-picker v-model="form_data.project_end_date" class="mt-4" @input="project_end_date_menu = false"></v-date-picker>
                    </v-menu>
                    <div v-if="form_data.project_end_date !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('project_end_date')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-select dense :items="['Evaluating', 'Well Positioned', 'Strongly Positioned', 'Verbal Award']" outlined label="Likelihood of Close" v-model="form_data.likelihood_of_close"></v-select>
                    <div v-if="form_data.likelihood_of_close !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('likelihood_of_close')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-textarea dense label="Facility Location (City, State)" outlined rows="1" class="line-height-adjustment" v-model="form_data.facility_location" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-textarea>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="form_data.salesforce_opportunity">
                    <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-if="form_data.salesforce_opportunity" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Amount" outlined v-model.number="form_data.salesforce_revenue" prefix="$"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="form_data.salesforce_opportunity">
                    <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-if="form_data.salesforce_opportunity" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Cost" outlined v-model.number="form_data.salesforce_cost" prefix="$"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="form_data.salesforce_opportunity">
                    <div style="text-align:left;padding-top:12px;font-size:16px">Margin: {{margin(form_data.salesforce_revenue,form_data.salesforce_cost)}}</div>
                  </v-col>

                </v-row>

                <v-row class="inputs-container">

                  <v-col cols="12" sm="6" md="6">
                    <v-textarea dense label="Scope of Work" outlined rows="8" class="line-height-adjustment" v-model="form_data.scope_of_work" auto-grow></v-textarea>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-textarea dense label="Next Steps" outlined rows="8" class="line-height-adjustment" v-model="form_data.next_steps" auto-grow></v-textarea>
                  </v-col>

                </v-row>

                <div class="form-banner">RFQ Required Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="12" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Quote Number" outlined class="line-height-adjustment" v-model="form_data.quote_number"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-select dense :items="['AM Review', 'Email Customer', 'Portal', 'Internal']" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Quote Delivery'" v-model="form_data.quote_delivery" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.quote_delivery !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('quote_delivery')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-menu v-model="due_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form_data.due_date" :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Due Date'" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-text-field>
                      </template>
                      <v-date-picker v-model="form_data.due_date" class="mt-4" @input="due_date_menu = false"></v-date-picker>
                    </v-menu>
                    <div v-if="form_data.due_date !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('due_date')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-select dense :items="times_arr" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Due Time'" v-model="form_data.due_time" prepend-inner-icon="mdi-clock" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.due_time !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('due_time')">Clear Selected</div>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-select dense :items="['Budget', 'Firm Quote', 'T&M']" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Quote Type'" v-model="form_data.quote_type" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.quote_type !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('quote_type')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-select dense :items="['Formal Proposal','Price/LT Only']" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Proposal Type'" v-model="form_data.proposal_type" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.proposal_type !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('proposal_type')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="12" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Old Quote/Project Number" outlined class="line-height-adjustment" v-model="form_data.old_quote_number"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-select dense :items="['FOB - Customer Site','FOB - Midstate']" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Shipping/Delivery Method'" v-model="form_data.shipping_delivery_method" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.shipping_delivery_method !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('shipping_delivery_method')">Clear Selected</div>
                  </v-col>

                </v-row>

                <v-row class="inputs-container">

                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      :items="['Labor','Material','Equipment','Subs']"
                      :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') + 'To Be Furnished'"
                      multiple
                      outlined
                      dense
                      v-model="form_data.to_be_furnished"
                      :rules="form_data.stage !== 'Opportunity Identification' ? requiredRulesMulti : []"
                      attach
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" style="position:relative">
                    <v-select dense :items="revenue_categories" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Revenue Category'" v-model="form_data.revenue_category" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.revenue_category !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('revenue_category')">Clear Selected</div>
                  </v-col>

                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="3">
                    <v-textarea dense label="Build to Specific Code" outlined rows="1" class="line-height-adjustment" v-model="form_data.build_to_specific_code"></v-textarea>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-select dense :items="['No Paint','Paint','Other','Galvanized','Prime Only','Per Specifications']" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Coatings Information'" v-model="form_data.coatings_information" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.coatings_information !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('coatings_information')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-select dense :items="['Import','Domestic','N/A','Either Acceptable']" outlined :label="(form_data.stage !== 'Opportunity Identification' ? '*' : '') +  'Steel'" v-model="form_data.steel" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.steel !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('steel')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-select dense :items="['Yes','No']" outlined label="All Material A36 CS" v-model="form_data.all_material_a36_cs"></v-select>
                    <div v-if="form_data.all_material_a36_cs !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('all_material_a36_cs')">Clear Selected</div>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-select dense :items="['Yes','No']" outlined label="Pre-Bid" v-model="form_data.pre_bid" :rules="form_data.stage !== 'Opportunity Identification' ? requiredRules : []"></v-select>
                    <div v-if="form_data.pre_bid !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('pre_bid')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-menu v-model="pre_bid_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form_data.pre_bid_date" label="Pre-Bid Date" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off"></v-text-field>
                      </template>
                      <v-date-picker v-model="form_data.pre_bid_date" class="mt-4" @input="pre_bid_date_menu = false"></v-date-picker>
                    </v-menu>
                    <div v-if="form_data.pre_bid_date !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('pre_bid_date')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" style="position:relative">
                    <v-select dense :items="times_arr" outlined label="Pre-Bid Time" v-model="form_data.pre_bid_time" prepend-inner-icon="mdi-clock"></v-select>
                    <div v-if="form_data.pre_bid_time !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('pre_bid_time')">Clear Selected</div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-autocomplete
                      sort="name"
                      v-model="form_data.pre_bid_attendees"
                      :items="active_users"
                      item-text="name"
                      item-value="id"
                      label="Pre-Bid Attendees"
                      dense
                      outlined
                      multiple
                      clearable
                      :rules="form_data.pre_bid === 'Yes' ? requiredRules : []"
                      @input="attendeeSearch=null"
                      :search-input.sync="attendeeSearch"
                      >
                    </v-autocomplete>

                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Job Number" outlined class="line-height-adjustment" v-model="form_data.job_number"></v-text-field>
                  </v-col>

                </v-row>

                <div class="form-banner" v-if="!departments_for_estimating_required_notification">{{form_data.stage !== 'Opportunity Identification' ? '*' : ''}}Departments for Estimating</div>
                <div class="form-banner" style="color:red" v-if="departments_for_estimating_required_notification">{{form_data.stage !== 'Opportunity Identification' ? '*' : ''}}Departments for Estimating</div>

                <v-row class="inputs-container" style="padding-bottom:50px">
                  <v-col cols="12" sm="12" md="6" v-for="(estimating_object, key) in form_data.estimating">
                    <v-row style="height:50px">
                      <v-col cols="3">
                        <v-checkbox :label="estimating_object.title" v-model="form_data.estimating[key].required"></v-checkbox>
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete sort="name" :disabled="!form_data.estimating[key].required" style="padding-top:15px" v-model="form_data.estimating[key].estimator" :items="active_users" item-text="name" item-value="id" :label="estimating_object.title + ' Estimator'" dense outlined></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <div class="form-banner">Stage Update Email Distribution List</div>
                <div style="text-align: left" v-if="form_data.owner">
                  <div>Owner: {{db.users[form_data.owner].email}}</div>
                  <div>Estimators: {{estimatorEmails.join(', ')}}</div>
                  <div>Others:</div>

                  <div v-for="(item, index) in form_data.distribution_list_for_stage_updates" :key="index" style="border-bottom: 1px solid lightgray;width:250px;font-size: 12px;line-height: 110%">
                    <v-row dense>
                      <v-col sm="10" style="vertical-align: center;">{{item}}</v-col>
                      <v-col sm="2" style="text-align: right;"><v-btn x-small color="primary" @click="removeEmailFromDistribution(index)"><v-icon>mdi-delete</v-icon></v-btn></v-col>
                    </v-row>
                  </div>

                  <v-row style="width:800px;position: relative;">
                    <v-col>
                      <v-autocomplete sort="name" v-model="newEmail" :items="active_users_sorted" item-text="name" item-value="email" label="" dense outlined></v-autocomplete>
                      <div v-if="newEmail !== ''" class="table-link" style="position: absolute;bottom:15px;font-size: 10px;" @click="newEmail = ''">Clear Selected</div>
                    </v-col>
                    <v-col>
                      <v-btn color="primary" small @click="addEmailToDistribution" :disabled="!newEmail">Add Email</v-btn>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-banner">Competitor Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="3" style="position:relative">
                    <v-select dense :items="['Price','Relationship','Cancelled', 'No bid - resources', 'No bid - services not offered', 'Self performed']" outlined :label="(form_data.stage === 'Closed Lost' ? '*' : '') +  'Loss Reason'" v-model="form_data.loss_reason" :rules="form_data.stage === 'Closed Lost' ? requiredRules : []"></v-select>
                    <div v-if="form_data.loss_reason !== ''" class="table-link" style="position:absolute;bottom:10px;right:10px;font-size:10px" @click="clearData('loss_reason')">Clear Selected</div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field v-model="form_data.lost_to_competitor" label="Lost to Competitor" dense outlined autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field v-model="form_data.competitor_price" label="Competitor Price" dense outlined autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-textarea dense label="Competitors Involved" outlined rows="1" class="line-height-adjustment" v-model="form_data.competitors_involved"></v-textarea>
                  </v-col>
                </v-row>

                <div style="text-align:left;font-size:12px">*indicates required field</div>

              </v-container>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">{{form_has_edit ? 'Cancel' : 'Close'}}</v-btn>
              <v-btn color="primary" @click="saveOpportunity()" :disabled="!form_has_edit || is_saving">Save</v-btn>
            </v-card-actions>
          </v-card>

        </v-form>
      </v-dialog>
      <AccountForm v-if="new_account" :id="'new'" @close="closeAccountForm"/>
      <ContactForm v-if="new_contact" :id="'new'" @close="closeContactForm"/>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';
  import { emailMixin } from '../../mixins/emailMixin.js'
  import AccountForm from './AccountForm.vue'
  import ContactForm from './ContactForm.vue'
  import Moment from 'moment'

  export default {
    name: 'OpportunityForm',
    components: {AccountForm,ContactForm},
    mixins: [emailMixin],
    props: {
        id: String
    },
    data(){
      return{
        new_account: false,
        new_contact: false,
        times_arr: ['12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM','12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'],
        attendeeSearch: "",
        close_date_menu: false,
        due_date_menu: false,
        project_start_date_menu: false,
        project_end_date_menu: false,
        pre_bid_date_menu: false,
        requiredRules: [
          value => !!value || 'Required'
        ],
        requiredRulesMulti: [
          value =>  value.length > 0 || "Required"
        ],
        active_contacts_for_selected_account: [],
        form_data: {
          'name' : '',
          'owner' : '',
          'account': '',
          'stage': 'Opportunity Identification',
          'status': 'open',
          'facility_location': '',
          'likelihood_of_close': '',
          'probability': '',
          'amount': '',
          'close_date': '',
          'project_start_date': '',
          'project_end_date': '',
          'quote_number': '',
          'quote_delivery': '',
          'quote_type': '',
          'proposal_type': '',
          'revenue_category': '',
          'coatings_information': '',
          'shipping_delivery_method': '',
          'steel': '',
          'due_date': '',
          'due_time': '',
          'loss_reason': '',
          'lost_to_competitor': '',
          'competitor_price': '',
          'competitors_involved': '',
          'next_steps': '',
          'job_number': '',
          'pre_bid': '',
          'pre_bid_date': '',
          'pre_bid_time': '',
          'pre_bid_attendees': [],
          'all_material_a36_cs': '',
          'scope_of_work': '',
          'build_to_specific_code': '',
          'old_quote_number': '',
          'shop_margin': '',
          'contact_to_send_quote': '',
          'to_be_furnished': [],
          'distribution_list_for_stage_updates': [],
          'salesforce_revenue': 0,
          'salesforce_cost': 0,
          'estimating': {
            'civil' : {
              'required': false,
              'estimator': '',
              'title': 'Civil'
            },
            'electrical' : {
              'required': false,
              'estimator': '',
              'title': 'Electrical'
            },

            'elmco_field' : {
              'required': false,
              'estimator': '',
              'title': 'ELMCO Field'
            },

            'elmco_fabrication' : {
              'required': false,
              'estimator': '',
              'title': 'ELMCO Fabrication'
            },

            'elmco_machine' : {
              'required': false,
              'estimator': '',
              'title': 'ELMCO Machine'
            },

            'engineering' : {
              'required': false,
              'estimator': '',
              'title': 'Engineering'
            },
            'fabrication' : {
              'required': false,
              'estimator': '',
              'title': 'Fabrication'
            },
            'field' : {
              'required': false,
              'estimator': '',
              'title': 'Field'
            },
            'machine' : {
              'required': false,
              'estimator': '',
              'title': 'Machine'
            },
            'mechanical' : {
              'required': false,
              'estimator': '',
              'title': 'Mechanical'
            },
            'med' : {
              'required': false,
              'estimator': '',
              'title': 'MED'
            },
            'pump' : {
              'required': false,
              'estimator': '',
              'title': 'Pump'
            },
            'qaqc' : {
              'required': false,
              'estimator': '',
              'title': 'QA/QC'
            },
            'sms' : {
              'required': false,
              'estimator': '',
              'title': 'SMS'
            },
            'teco' : {
              'required': false,
              'estimator': '',
              'title': 'TECO'
            },
            'transportation' : {
              'required': false,
              'estimator': '',
              'title': 'Transportation'
            }
          },
        },
        newEmail: '',
        display_estimate: false,
        original_form_data: null,
        editable: false,
        form_has_edit: false,
        is_saving: false,
        estimating_has_edit: false,
        loss_reason_popup: false,
        departments_for_estimating_required_notification: false
      }
    },
    beforeMount(){
      var self = this

      if(self.id !== 'new'){
        Firebase.database().ref('opportunities').child(self.id).once('value',function(snapshot){
          var opportunity = snapshot.val()

          self.form_data = opportunity

          if(self.form_data.distribution_list_for_stage_updates === undefined){
            self.$set(self.form_data, 'distribution_list_for_stage_updates', []);
          }

          if(!self.form_data.salesforce_opportunity){
            self.form_data.salesforce_revenue = 0
            self.form_data.salesforce_cost = 0
          }

          if(self.form_data.estimating['elmco'] === undefined){
            self.$set(self.form_data.estimating, 'elmco', {'required': false, 'estimator': '', 'title': 'ELMCO'});
          }

          if(self.form_data.estimating['elmco_field'] === undefined){
            self.$set(self.form_data.estimating, 'elmco_field', {'required': false, 'estimator': '', 'title': 'ELMCO Field'});
          }

          if(self.form_data.estimating['elmco_fabrication'] === undefined){
            self.$set(self.form_data.estimating, 'elmco_fabrication', {'required': false, 'estimator': '', 'title': 'ELMCO Fabrication'});
          }

          if(self.form_data.estimating['elmco_machine'] === undefined){
            self.$set(self.form_data.estimating, 'elmco_machine', {'required': false, 'estimator': '', 'title': 'ELMCO Machine'});
          }

          self.form_data.to_be_furnished = self.form_data.to_be_furnished ? self.form_data.to_be_furnished : []
          self.original_form_data = JSON.parse(JSON.stringify(self.form_data))
        })


      }else{
        self.form_data.quote_delivery = 'AM Review'
        self.original_form_data = JSON.parse(JSON.stringify(self.form_data))
      }



      self.editable = true
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        active_accounts: 'active_accounts',
        // active_contacts: 'active_contacts',
        active_users: 'active_users',
        is_admin: 'is_admin',
        revenue_categories: 'revenue_categories'
      }),
      active_users_sorted(){
        return _.orderBy(this.active_users, ['last_name', 'first_name'], ['asc', 'asc']);
      },
      estimatorEmails(){
        var arr = []
        for(var dep in this.form_data.estimating){ // add assigned estimators
          if(this.form_data.estimating[dep].estimator !== ''){
            if(arr.indexOf(this.db.users[this.form_data.estimating[dep].estimator].email) === -1){
              arr.push(this.db.users[this.form_data.estimating[dep].estimator].email)    
            }
          }
        }      
        return arr
      }
    },
    watch: {
      'form_data':{
        deep: true,
        handler(val){
          var has_update = !_.isEqual(this.form_data, this.original_form_data);
          this.form_has_edit = (this.editable && has_update) ? true : false
        }
      },
      'form_data.quote_delivery':{
        deep: true,
        handler(val){
          this.checkValidDueDate()
        }        
      },
      'form_data.due_date':{
        deep: true,
        handler(val){
          this.checkValidDueDate()
        }
      },
      'form_data.account':{
        deep: true,
        handler(val){
          var self = this
          var contacts_arr = []

          Firebase.database().ref('contacts').orderByChild('account').equalTo(val).on('value',function(snapshot){
            var contacts =  snapshot.val()
            for(var c in contacts){
              var contact_object = contacts[c];
              contact_object.name = contact_object.first_name + ' ' + contact_object.last_name;
              contact_object.id = c;
              if (!contact_object.disabled) {
                contacts_arr.push(contacts[c])
              }
            }
            self.active_contacts_for_selected_account = contacts_arr
          })
        }
      },
      'form_data.estimating':{
        deep: true,
        handler(val){
          var has_department_selected = false

          for(var department in this.form_data.estimating){
            if(!this.form_data.estimating[department].required){
              this.form_data.estimating[department].estimator = ''
            }else{
              has_department_selected = true
              this.departments_for_estimating_required_notification = false
            }
          }

          if(this.form_data.stage !== 'Opportunity Identification' && !has_department_selected){
            this.departments_for_estimating_required_notification = true
          }

        }
      },
      'form_data.salesforce_revenue':{
        deep: true,
        handler(val){
          this.form_data.salesforce_revenue = Math.max(val,0)
        }
      },
      'form_data.salesforce_cost':{
        deep: true,
        handler(val){
          this.form_data.salesforce_cost = Math.max(val,0)
        }
      }
    },
    methods: {
      addEmailToDistribution() {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(this.newEmail)) {
          alert("Please enter a valid email address");
          return;
        }
        if (!Array.isArray(this.form_data.distribution_list_for_stage_updates)) {
          self.$set(self.form_data, 'distribution_list_for_stage_updates', []);
        }
        if (this.newEmail && !this.form_data.distribution_list_for_stage_updates.includes(this.newEmail)) {
          this.form_data.distribution_list_for_stage_updates.push(this.newEmail);
          this.newEmail = '';
        }
      },
      removeEmailFromDistribution(index) {
        var confirm_delete = confirm('Are you sure you want to remove ' + this.form_data.distribution_list_for_stage_updates[index] + '?')
        if(confirm_delete){
          this.form_data.distribution_list_for_stage_updates.splice(index, 1);  
        }
      },      
      checkValidDueDate(){
          var self = this
          var due_dates_count = {}
          var max = self.db.daily_opportunities_due_max
          var due_date = self.form_data.due_date
          if(due_date == ''){
            return
          }
          Firebase.database().ref('opportunities').orderByChild('status').equalTo('open').once('value',function(snapshot){
            var opportunities_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
            for(var opp_id in opportunities_db_clone){
              if(due_dates_count[opportunities_db_clone[opp_id].due_date]){
                due_dates_count[opportunities_db_clone[opp_id].due_date].cnt++
                due_dates_count[opportunities_db_clone[opp_id].due_date].ids.push(opp_id)
              }else{
                due_dates_count[opportunities_db_clone[opp_id].due_date] = {
                  cnt: 1,
                  ids: [opp_id]
                }
              }
            }

            if(due_dates_count[due_date] && due_dates_count[due_date].cnt >= max && (due_dates_count[due_date].ids.indexOf(self.id) === -1 || self.original_form_data.quote_delivery === 'Portal') && self.form_data.quote_delivery !== 'Portal'){
              if(self.db.users[self.current_user].role !== 'Administrator'){
                var message = Moment(due_date).format('MM/DD/YY') + ' has reached its daily limit of ' + max + ' opportunities. Please choose another due date.' 
                alert(message)
                self.form_data.due_date = ''
              }else{
                var message = Moment(due_date).format('MM/DD/YY') + ' has reached its daily limit of ' + max + ' opportunities.' 
                alert(message)
              }
            }
          })
      },
      margin(revenue,cost){
        var raw = ((revenue - cost) / revenue) * 100
        var formatted = raw.toFixed(1)
        return revenue > 0 ? formatted + '%' : 'Missing Revenue'
      },
      clearData(field){
        this.form_data[field] = ''
      },
      closeAccountForm(new_account_db_key){
        if(new_account_db_key){
          this.form_data.account = new_account_db_key
        }
        this.new_account = false
      },
      closeContactForm(new_contact_db_key){
        if(new_contact_db_key){
          this.form_data.contact_to_send_quote = new_contact_db_key
        }
        this.new_contact = false
      },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      estimateDepartment(department){
        if(this.form_has_edit){
          this.$store.state.notification = 'Please save changes made to this opportunity before completing an estimate.'
          return
        }
        this.display_estimate = department
      },
      recordStageChange(opp_id){
        var self = this
        var stage_change_object = {changed_by: self.current_user,changed_date: Date.now(),stage: self.form_data.stage }

        if(self.id === 'new'){
          Firebase.database().ref('opportunities').child(opp_id).child('stage_history').push(stage_change_object)
        }

        if(this.id !== 'new' && self.form_data.stage !== self.original_form_data.stage){
          Firebase.database().ref('opportunities').child(self.id).child('stage_history').push(stage_change_object)
        }

        if(self.form_data.stage !== self.original_form_data.stage){
          var to_arr = []
          to_arr.push(self.db.users[self.form_data.owner].email) // add owner
          for(var dep in self.form_data.estimating){ // add assigned estimators
            if(self.form_data.estimating[dep].estimator !== ''){
              to_arr.push(self.db.users[self.form_data.estimating[dep].estimator].email)  
            }
          }

          // need to add custom distribution list now
          for(var i = 0; i < this.form_data.distribution_list_for_stage_updates.length;i++){
            to_arr.push(this.form_data.distribution_list_for_stage_updates[i])
          }

          var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          to_arr = to_arr.filter((email, index, self) => re.test(email) && index === self.indexOf(email));
          self.opportunityStageUpdate(to_arr, self.db.accounts[self.form_data.account].name, self.form_data.name, self.form_data.quote_number, self.original_form_data.stage, self.form_data.stage, self.db.users[self.current_user].first_name + ' ' + self.db.users[self.current_user].last_name)
        }
      },
      saveOpportunity(){

        var self = this
        self.$store.state.isLoading = true
        self.is_saving = true
        var is_valid = self.$refs.form.validate()
        var has_department_selected = false

        if(! is_valid){

          self.$store.state.isLoading = false
          self.is_saving = false
          self.$store.state.notification = 'Please complete required fields.'

          for(var dep in self.form_data.estimating){
            if(self.form_data.estimating[dep].required){
              has_department_selected = true
            }
          }

          if(self.form_data.stage !== 'Opportunity Identification' && !has_department_selected){
            self.departments_for_estimating_required_notification = true
          }
          return
        }

        for(var dep in self.form_data.estimating){
          var current_estimator = self.form_data.estimating[dep].estimator
          var previous_estimator = self.original_form_data.estimating[dep].estimator
          var title = self.form_data.estimating[dep].title
          if(current_estimator !== previous_estimator && current_estimator !== ''){
            self.estimateAssignment(self.db.users[current_estimator].email, self.form_data.estimating[dep].title, self.db.accounts[self.form_data.account].name, self.form_data.name, self.form_data.quote_number)
          }
          if(self.form_data.estimating[dep].required){
            has_department_selected = true
          }
        }


        if(self.form_data.pre_bid_attendees){
          var previous_attendees = self.original_form_data.pre_bid_attendees ? self.original_form_data.pre_bid_attendees : []
          var current_attendees = self.form_data.pre_bid_attendees ? self.form_data.pre_bid_attendees : []

          for(var i = 0; i < self.form_data.pre_bid_attendees.length; i++){
            var attendee_key = self.form_data.pre_bid_attendees[i]
            if(previous_attendees.indexOf(attendee_key) === -1){
              self.prebidAttendee(self.db.users[attendee_key].email, self.db.accounts[self.form_data.account].name, self.form_data.name, self.form_data.quote_number, Moment(self.form_data.pre_bid_date).format('MM/DD/YY') + ' ' + self.form_data.pre_bid_time )
            }
          }
        }

        self.form_data.status = self.form_data.stage.indexOf('Closed') === -1 ? 'open' : 'closed'

        if(self.form_data.stage !== 'Opportunity Identification' && !has_department_selected){
          self.$store.state.isLoading = false
          self.is_saving = false
          self.$store.state.notification = 'At least one department for estimating must be selected.'
          self.departments_for_estimating_required_notification = true
          return
        }

        if(this.id === 'new'){
          self.form_data.added_by = self.current_user
          self.form_data.created_date = Date.now()

          Firebase.database().ref('opportunities').push(self.form_data).then(function(snapshot){
            self.newOppNotificationEmail(snapshot.key,':newOpp')
            Firebase.database().ref('opportunities').child(snapshot.key).child('id').set(snapshot.key).then(function(){
              var key = snapshot.key

              if(self.form_data.stage === 'Assigning Quote #'){
                self.requestQuoteNumberEmail(self.db.accounts[self.form_data.account].name, self.form_data.name)
              }

              self.recordStageChange(key)
              self.$store.state.notification = 'Opportunity successfully saved.'
              self.original_form_data = JSON.parse(JSON.stringify(self.form_data))
              self.$store.state.isLoading = false
              self.is_saving = false
              self.$emit('save-new',key)
            })
          })
        }

        if(this.id !== 'new'){
          self.form_data.edited_by = self.current_user
          self.form_data.edit_date = Date.now()

          Firebase.database().ref('opportunities').child(self.id).set(self.form_data).then(function(snapshot){
            self.newOppNotificationEmail(self.id,':updateOpp')
            self.recordStageChange()

            if(self.form_data.stage === 'Assigning Quote #' && self.original_form_data.stage !== 'Assigning Quote #'){
              self.requestQuoteNumberEmail(self.db.accounts[self.form_data.account].name, self.form_data.name)
            }

            if(self.form_data.stage === 'Quote Revision' && self.original_form_data.stage !== 'Quote Revision'){

              Firebase.database().ref('quotes').child(self.id).once('value',function(quotes_snapshot){
                var quotes = quotes_snapshot.val()
                for(var qid in quotes){
                  Firebase.database().ref('quotes').child(self.id).child(qid).child('completed').set(false)
                  Firebase.database().ref('quotes').child(self.id).child(qid).child('reviewed').set(false)
                }
              })

              Firebase.database().ref('totals').child(self.id).once('value',function(totals_snapshot){
                var totals = totals_snapshot.val()
                for(var tid in totals){
                  Firebase.database().ref('totals').child(self.id).child(tid).child('completed').set(false)
                  Firebase.database().ref('totals').child(self.id).child(tid).child('reviewed').set(false)
                }
              })
            }

            self.$store.state.notification = 'Opportunity successfully saved.'
            self.original_form_data = JSON.parse(JSON.stringify(self.form_data))
            self.form_has_edit = false
            self.$store.state.isLoading = false
            self.is_saving = false

          })
        }
      }
    }
  }
</script>
<style lang="scss">

</style>
