<template>
  <div class="rates-page">
    <v-container>

      <v-expansion-panels focusable>

        <!-- MSA -->
        <v-expansion-panel v-if="is_msa_admin">
          <v-expansion-panel-header>MSA</v-expansion-panel-header>
          <v-expansion-panel-content>
            <MSA_Admin/>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- FIELD -->
        <v-expansion-panel>
          <v-expansion-panel-header>Field</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header" @click="showSub('field_per_diem')">Per Diem Rates</div>
              <v-simple-table v-show="show_sub === 'field_per_diem'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Hotel</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/per_diem_rates/hotels')">{{ formatCurrency(db.field_rates.per_diem_rates.hotels) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Meals</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/per_diem_rates/meals')">{{ formatCurrency(db.field_rates.per_diem_rates.meals) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_small_tools_consumables')">Small Tools & Consumables Rates</div>
              <v-simple-table v-show="show_sub === 'field_small_tools_consumables'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Small Tools Cost Rate</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/small_tool_consumables_rates/small_tools_cost_rate')"
                        >{{ db.field_rates.small_tool_consumables_rates.small_tools_cost_rate }}%</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Consumables Cost Rate</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/small_tool_consumables_rates/consumables_cost_rate')"
                        >{{ db.field_rates.small_tool_consumables_rates.consumables_cost_rate }}%</span
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_material_cost')">Material Cost Rates</div>

              <div class="rates-btn" v-show="show_sub === 'field_material_cost'" @click="add_field_material = true">+ ADD MATERIAL</div>

              <v-simple-table v-show="show_sub === 'field_material_cost'">
                <tbody>

                  <tr v-for="data in sorted_material" v-if="db.field_rates.material_rates[data.key]">

                    <td style="text-align:left;" class="click-rate" @click="editFieldMaterial(data.key)">{{ data.item }}</td>

                    <td style="text-align:right">
                      {{ formatCurrency(data.cost) }}
                    </td>
                  </tr>

                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_fuel_cost')">Fuel Cost Rates</div>
              <v-simple-table v-show="show_sub === 'field_fuel_cost'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Air Compressor</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/fuel_cost_rates/air_compressor')">{{
                        formatCurrency(db.field_rates.fuel_cost_rates.air_compressor)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Forklift</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/fuel_cost_rates/forklift')">{{ formatCurrency(db.field_rates.fuel_cost_rates.forklift) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Manlift</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/fuel_cost_rates/manlift')">{{ formatCurrency(db.field_rates.fuel_cost_rates.manlift) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Welding</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/fuel_cost_rates/welding')">{{ formatCurrency(db.field_rates.fuel_cost_rates.welding) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_equipment_cost')">Equipment Cost Rates</div>

              <div class="rates-btn" v-show="show_sub === 'field_equipment_cost'" @click="add_field_equipment = true">+ ADD EQUIPMENT</div>

              <v-simple-table v-show="show_sub === 'field_equipment_cost'">

                <tbody>
                  <tr v-for="data in sorted_equipment" v-if="db.field_rates.equipment_rates[data.key]">
                    <td style="text-align:left;" class="click-rate" @click="editFieldEquipment(data.key)">{{ data.description }} ({{ data.owner }})</td>
                    <td style="text-align:right;">
                      <div>Daily</div>
                      {{ formatCurrency(data.cost_daily) }}
                    </td>
                    <td style="text-align:right;">
                      <div>Weekly</div>
                      {{ formatCurrency(data.cost_weekly) }}
                    </td>
                    <td style="text-align:right;">
                      <div>Monthly</div>
                      {{ formatCurrency(data.cost_monthly) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_labor_cost')">Labor Cost Rates</div>
              <v-simple-table v-show="show_sub === 'field_labor_cost'">
                <tbody>
                  <tr>
                    <td style="text-align:left;">Standard Time</td>
                    <td style="text-align:right;">
                      <span class="click-rate" @click="editRate('field_rates/labor_cost_rates/st')">{{ formatCurrency(db.field_rates.labor_cost_rates.st) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left;">Overtime</td>
                    <td style="text-align:right;">
                      <span class="click-rate" @click="editRate('field_rates/labor_cost_rates/ot')">{{ formatCurrency(db.field_rates.labor_cost_rates.ot) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left;">Double Time</td>
                    <td style="text-align:right;">
                      <span class="click-rate" @click="editRate('field_rates/labor_cost_rates/dt')">{{ formatCurrency(db.field_rates.labor_cost_rates.dt) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_labor_night_shift_differential')">Labor Night Shift Differential</div>
              <v-simple-table v-show="show_sub === 'field_labor_night_shift_differential'">
                <tbody>
                  <tr>
                    <td style="text-align:left;">Standard Time</td>
                    <td style="text-align:right;">
                      <span class="click-rate" @click="editRate('field_rates/labor_night_shift_differentials/st')">{{ db.field_rates.labor_night_shift_differentials.st }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left;">Overtime</td>
                    <td style="text-align:right;">
                      <span class="click-rate" @click="editRate('field_rates/labor_night_shift_differentials/ot')">{{ db.field_rates.labor_night_shift_differentials.ot }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left;">Double Time</td>
                    <td style="text-align:right;">
                      <span class="click-rate" @click="editRate('field_rates/labor_night_shift_differentials/dt')">{{ db.field_rates.labor_night_shift_differentials.dt }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_labor_revenue')">Labor Revenue Rates</div>

              <div class="rates-btn" v-show="show_sub === 'field_labor_revenue'" @click="add_field_labor_revenue_rates = true">+ ADD CUSTOMER</div>

              <div v-show="show_sub === 'field_labor_revenue'" v-for="customer in customers_arr" style="border:1px solid gray;margin-bottom:5px" v-if="db.field_rates.labor_revenue_rates[customer+'ST'] && db.field_rates.labor_revenue_rates[customer+'OT'] && db.field_rates.labor_revenue_rates[customer+'DT']">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td style="text-align:left;"><span class="click-rate" @click="editFieldLaborRevenueRates(customer)">{{ customer }}</span></td></td>
                      <td style="text-align:right;"><div>Project Manager</div></td>
                      <td style="text-align:right;"><div>General Superintendent</div></td>
                      <td style="text-align:right;"><div>Foreman</div></td>
                      <td style="text-align:right;"><div>Journeymen</div></td>
                      <td style="text-align:right;"><div>Helper/FW/HW</div></td>
                      <td style="text-align:right;"><div>Safety Officer</div></td>
                      <td style="text-align:right;"><div>QAQC</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left;">ST</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['project_manager'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['general_superintendent'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['foreman'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['journeymen'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['helper'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['safety_officer'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'ST']['qaqc'])}}</td>
                    </tr>
                    <tr>
                      <td style="text-align:left;">OT</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['project_manager'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['general_superintendent'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['foreman'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['journeymen'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['helper'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['safety_officer'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'OT']['qaqc'])}}</td>
                    </tr>
                    <tr>
                      <td style="text-align:left;">DT</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['project_manager'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['general_superintendent'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['foreman'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['journeymen'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['helper'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['safety_officer'])}}</td>
                      <td style="text-align:right;">{{formatCurrency(db.field_rates.labor_revenue_rates[customer+'DT']['qaqc'])}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_rental')">Rental In/Out Charges</div>
              <v-simple-table v-show="show_sub === 'field_rental'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Rental In/Out Cost</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/rental_in_out_charges')">{{ formatCurrency(db.field_rates.rental_in_out_charges) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_travel')">Travel Rates</div>
              <v-simple-table v-show="show_sub === 'field_travel'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Foreman</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/foreman')">{{ formatCurrency(db.field_rates.travel_rates.foreman) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">General Superintendent</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/general_superintendent')">{{
                        formatCurrency(db.field_rates.travel_rates.general_superintendent)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Helper</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/helper')">{{ formatCurrency(db.field_rates.travel_rates.helper) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Journeymen</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/journeymen')">{{ formatCurrency(db.field_rates.travel_rates.journeymen) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Project Manager</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/project_manager')">{{
                        formatCurrency(db.field_rates.travel_rates.project_manager)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">QAQC</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/qaqc')">{{ formatCurrency(db.field_rates.travel_rates.qaqc) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Safety Officer</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/safety_officer')">{{ formatCurrency(db.field_rates.travel_rates.safety_officer) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Specialty Craft</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/travel_rates/specialty_craft')">{{
                        formatCurrency(db.field_rates.travel_rates.specialty_craft)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('field_truck')">Truck Rates</div>
              <v-simple-table v-show="show_sub === 'field_truck'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Pickup Truck Revenue Per Truck</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/truck_rates/pickup_truck_revenue_per_truck')">{{
                        formatCurrency(db.field_rates.truck_rates.pickup_truck_revenue_per_truck)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Pickup Truck Revenue Per Mile</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/truck_rates/pickup_truck_revenue_per_mile')">{{
                        formatCurrency(db.field_rates.truck_rates.pickup_truck_revenue_per_mile)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Pickup Truck Cost Per Mile</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/truck_rates/pickup_truck_cost_per_mile')">{{
                        formatCurrency(db.field_rates.truck_rates.pickup_truck_cost_per_mile)
                      }}</span>
                    </td>
                  </tr>

                  <tr>
                    <td style="text-align:left">Service Truck Revenue Per Truck</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/truck_rates/service_truck_revenue_per_truck')">{{
                        formatCurrency(db.field_rates.truck_rates.service_truck_revenue_per_truck)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Service Truck Revenue Per Mile</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/truck_rates/service_truck_revenue_per_mile')">{{
                        formatCurrency(db.field_rates.truck_rates.service_truck_revenue_per_mile)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Service Truck Cost Per Mile</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('field_rates/truck_rates/service_truck_cost_per_mile')">{{
                        formatCurrency(db.field_rates.truck_rates.service_truck_cost_per_mile)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- TRANSPORTATION -->
        <v-expansion-panel>
          <v-expansion-panel-header>Transportation</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header" @click="showSub('transportation_dispatch')">Dispatch Rates</div>
              <v-simple-table v-show="show_sub === 'transportation_dispatch'">
                <tbody>
                  <tr>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Dispatch/st_revenue')">{{
                        formatCurrency(db.transportation_rates.Dispatch.st_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Dispatch/st_cost')">{{ formatCurrency(db.transportation_rates.Dispatch.st_cost) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Dispatch/ot_revenue')">{{
                        formatCurrency(db.transportation_rates.Dispatch.ot_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Dispatch/ot_cost')">{{ formatCurrency(db.transportation_rates.Dispatch.ot_cost) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('transportation_loading')">Loading Rates</div>
              <v-simple-table v-show="show_sub === 'transportation_loading'">
                <tbody>
                  <tr>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Loading/st_revenue')">{{ formatCurrency(db.transportation_rates.Loading.st_revenue) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Loading/st_cost')">{{ formatCurrency(db.transportation_rates.Loading.st_cost) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Loading/ot_revenue')">{{ formatCurrency(db.transportation_rates.Loading.ot_revenue) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Loading/ot_cost')">{{ formatCurrency(db.transportation_rates.Loading.ot_cost) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('transportation_local_truck')">Local Truck Rates</div>
              <v-simple-table v-show="show_sub === 'transportation_local_truck'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Local Small Truck</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Small Truck/st_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Small Truck'].st_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Small Truck/st_cost')">{{
                        formatCurrency(db.transportation_rates['Local Small Truck'].st_cost)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Small Truck/ot_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Small Truck'].ot_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Small Truck/ot_cost')">{{
                        formatCurrency(db.transportation_rates['Local Small Truck'].ot_cost)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Local Semi-Flat</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Flat/st_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Flat'].st_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Flat/st_cost')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Flat'].st_cost)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Flat/ot_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Flat'].ot_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Flat/ot_cost')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Flat'].ot_cost)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Local Semi-Loboy</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Loboy/st_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Loboy'].st_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Loboy/st_cost')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Loboy'].st_cost)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Loboy/ot_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Loboy'].ot_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Semi-Loboy/ot_cost')">{{
                        formatCurrency(db.transportation_rates['Local Semi-Loboy'].ot_cost)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Local Other</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Other/st_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Other'].st_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Other/st_cost')">{{
                        formatCurrency(db.transportation_rates['Local Other'].st_cost)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Other/ot_revenue')">{{
                        formatCurrency(db.transportation_rates['Local Other'].ot_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Local Other/ot_cost')">{{
                        formatCurrency(db.transportation_rates['Local Other'].ot_cost)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('transportation_out_state_truck')">Out of State Truck Rates</div>
              <v-simple-table v-show="show_sub === 'transportation_out_state_truck'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Out of State Small Truck</td>
                    <td style="text-align:right">
                      <div>Revenue Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Small Truck/mile_revenue')">{{
                        formatCurrency(db.transportation_rates['Out of State Small Truck'].mile_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Cost Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Small Truck/mile_cost')">{{
                        formatCurrency(db.transportation_rates['Out of State Small Truck'].mile_cost)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Out of State Semi-Flat Truck</td>
                    <td style="text-align:right">
                      <div>Revenue Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Semi-Flat/mile_revenue')">{{
                        formatCurrency(db.transportation_rates['Out of State Semi-Flat'].mile_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Cost Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Semi-Flat/mile_cost')">{{
                        formatCurrency(db.transportation_rates['Out of State Semi-Flat'].mile_cost)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Out of State Semi-Loboy Truck</td>
                    <td style="text-align:right">
                      <div>Revenue Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Semi-Loboy/mile_revenue')">{{
                        formatCurrency(db.transportation_rates['Out of State Semi-Loboy'].mile_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Cost Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Semi-Loboy/mile_cost')">{{
                        formatCurrency(db.transportation_rates['Out of State Semi-Loboy'].mile_cost)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Out of State Other Truck</td>
                    <td style="text-align:right">
                      <div>Revenue Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Other/mile_revenue')">{{
                        formatCurrency(db.transportation_rates['Out of State Other'].mile_revenue)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Cost Per Mile</div>
                      <span class="click-rate" @click="editRate('transportation_rates/Out of State Other/mile_cost')">{{
                        formatCurrency(db.transportation_rates['Out of State Other'].mile_cost)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('transportation_per_diem')">Per Diem Rates</div>
              <v-simple-table v-show="show_sub === 'transportation_per_diem'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Hotel</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('transportation_rates/per_diem_rates/hotels')">{{
                        formatCurrency(db.transportation_rates.per_diem_rates.hotels)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Meals</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('transportation_rates/per_diem_rates/meals')">{{
                        formatCurrency(db.transportation_rates.per_diem_rates.meals)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- ENGINEERING -->
        <v-expansion-panel>
          <v-expansion-panel-header>Engineering</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header" @click="showSub('engineering_in_house')">In House Rates</div>
              <v-simple-table v-show="show_sub === 'engineering_in_house'">
                <tbody>
                  <tr>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('engineering_rates/in_house/st_revenue')">{{ formatCurrency(db.engineering_rates.in_house.st_revenue) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('engineering_rates/in_house/st_cost')">{{ formatCurrency(db.engineering_rates.in_house.st_cost) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('engineering_rates/in_house/ot_revenue')">{{ formatCurrency(db.engineering_rates.in_house.ot_revenue) }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('engineering_rates/in_house/ot_cost')">{{ formatCurrency(db.engineering_rates.in_house.ot_cost) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('engineering_per_diem')">Per Diem Rates</div>
              <v-simple-table v-show="show_sub === 'engineering_per_diem'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Hotel</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('engineering_rates/per_diem_rates/hotels')">{{ formatCurrency(db.engineering_rates.per_diem_rates.hotels) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left">Meals</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('engineering_rates/per_diem_rates/meals')">{{ formatCurrency(db.engineering_rates.per_diem_rates.meals) }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- MECHANICAL -->
        <v-expansion-panel>
          <v-expansion-panel-header>Mechanical</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header">Labor Rates</div>
              <v-simple-table>
                <tbody>
                  <tr v-for="lr in db.mechanical_rates.labor_rates">
                    <td style="text-align:left">{{ lr.pr_class }} {{ lr.Classification }}</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('mechanical_rates/labor_rates/' + lr.pr_class + '/st_revenue_rate')">{{
                        formatCurrency(db.mechanical_rates.labor_rates[lr.pr_class].st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('mechanical_rates/labor_rates/' + lr.pr_class + '/st_cost_rate')">{{
                        formatCurrency(db.mechanical_rates.labor_rates[lr.pr_class].st_cost_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('mechanical_rates/labor_rates/' + lr.pr_class + '/ot_revenue_rate')">{{
                        formatCurrency(db.mechanical_rates.labor_rates[lr.pr_class].ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('mechanical_rates/labor_rates/' + lr.pr_class + '/ot_cost_rate')">{{
                        formatCurrency(db.mechanical_rates.labor_rates[lr.pr_class].ot_cost_rate)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- PUMP -->
        <v-expansion-panel>
          <v-expansion-panel-header>Pump</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header">Labor Rates</div>
              <v-simple-table>
                <tbody>
                  <tr v-for="lr in db.pump_rates.labor_rates">
                    <td style="text-align:left">{{ lr.pr_class }} {{ lr.Classification }}</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('pump_rates/labor_rates/' + lr.pr_class + '/st_revenue_rate')">{{
                        formatCurrency(db.pump_rates.labor_rates[lr.pr_class].st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('pump_rates/labor_rates/' + lr.pr_class + '/st_cost_rate')">{{
                        formatCurrency(db.pump_rates.labor_rates[lr.pr_class].st_cost_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('pump_rates/labor_rates/' + lr.pr_class + '/ot_revenue_rate')">{{
                        formatCurrency(db.pump_rates.labor_rates[lr.pr_class].ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('pump_rates/labor_rates/' + lr.pr_class + '/ot_cost_rate')">{{
                        formatCurrency(db.pump_rates.labor_rates[lr.pr_class].ot_cost_rate)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SPECIALTY -->
        <v-expansion-panel>
          <v-expansion-panel-header>Specialty</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header">Labor Rates</div>
              <v-simple-table>
                <tbody>
                  <tr v-for="lr in db.specialty_rates.labor_rates">
                    <td style="text-align:left">{{ lr.pr_class }} {{ lr.Classification }}</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('specialty_rates/labor_rates/' + lr.pr_class + '/st_revenue_rate')">{{
                        formatCurrency(db.specialty_rates.labor_rates[lr.pr_class].st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('specialty_rates/labor_rates/' + lr.pr_class + '/st_cost_rate')">{{
                        formatCurrency(db.specialty_rates.labor_rates[lr.pr_class].st_cost_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('specialty_rates/labor_rates/' + lr.pr_class + '/ot_revenue_rate')">{{
                        formatCurrency(db.specialty_rates.labor_rates[lr.pr_class].ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('specialty_rates/labor_rates/' + lr.pr_class + '/ot_cost_rate')">{{
                        formatCurrency(db.specialty_rates.labor_rates[lr.pr_class].ot_cost_rate)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- MACHINE -->
        <v-expansion-panel>
          <v-expansion-panel-header>Machine</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header" @click="showSub('machine_small_tools_consumables')">Small Tools & Consumables Rate</div>
              <v-simple-table v-show="show_sub === 'machine_small_tools_consumables'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Small Tools & Consumables Cost Rate</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('machine_rates/small_tool_consumables_rate')">{{ db.machine_rates.small_tool_consumables_rate }}%</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('machine_labor')">Labor Rates</div>
              <v-simple-table v-show="show_sub === 'machine_labor'">
                <tbody>
                  <tr v-for="lr in db.machine_rates.labor_rates">
                    <td style="text-align:left">{{ lr.pr_class }} {{ lr.Classification }}</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('machine_rates/labor_rates/' + lr.pr_class + '/st_revenue_rate')">{{
                        formatCurrency(db.machine_rates.labor_rates[lr.pr_class].st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Mosaic Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('machine_rates/labor_rates/' + lr.pr_class + '/mosaic_st_revenue_rate')">{{
                        formatCurrency(db.machine_rates.labor_rates[lr.pr_class].mosaic_st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('machine_rates/labor_rates/' + lr.pr_class + '/st_cost_rate')">{{
                        formatCurrency(db.machine_rates.labor_rates[lr.pr_class].st_cost_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('machine_rates/labor_rates/' + lr.pr_class + '/ot_revenue_rate')">{{
                        formatCurrency(db.machine_rates.labor_rates[lr.pr_class].ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Mosaic Overtime Time Revenue</div>
                      <span class="click-rate" @click="editRate('machine_rates/labor_rates/' + lr.pr_class + '/mosaic_ot_revenue_rate')">{{
                        formatCurrency(db.machine_rates.labor_rates[lr.pr_class].mosaic_ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('machine_rates/labor_rates/' + lr.pr_class + '/ot_cost_rate')">{{
                        formatCurrency(db.machine_rates.labor_rates[lr.pr_class].ot_cost_rate)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- ELMCO MACHINE -->
        <v-expansion-panel>
          <v-expansion-panel-header>ELMCO Machine</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header" @click="showSub('elmco_machine_small_tools_consumables')">Small Tools & Consumables Rate</div>
              <v-simple-table v-show="show_sub === 'elmco_machine_small_tools_consumables'">
                <tbody>
                  <tr>
                    <td style="text-align:left">Small Tools & Consumables Cost Rate</td>
                    <td style="text-align:right">
                      <span class="click-rate" @click="editRate('elmco_machine_rates/small_tool_consumables_rate')">{{ db.elmco_machine_rates.small_tool_consumables_rate }}%</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="rate-section">
              <div class="category-header" @click="showSub('elmco_machine_labor')">Labor Rates</div>
              <v-simple-table v-show="show_sub === 'elmco_machine_labor'">
                <tbody>
                  <tr v-for="lr in db.elmco_machine_rates.labor_rates">
                    <td style="text-align:left">{{ lr.Classification }}</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('elmco_machine_rates/labor_rates/' + lr.pr_class + '/st_revenue_rate')">{{
                        formatCurrency(db.elmco_machine_rates.labor_rates[lr.pr_class].st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('elmco_machine_rates/labor_rates/' + lr.pr_class + '/st_cost_rate')">{{
                        formatCurrency(db.elmco_machine_rates.labor_rates[lr.pr_class].st_cost_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('elmco_machine_rates/labor_rates/' + lr.pr_class + '/ot_revenue_rate')">{{
                        formatCurrency(db.elmco_machine_rates.labor_rates[lr.pr_class].ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('elmco_machine_rates/labor_rates/' + lr.pr_class + '/ot_cost_rate')">{{
                        formatCurrency(db.elmco_machine_rates.labor_rates[lr.pr_class].ot_cost_rate)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- ELMCO FABRICATION -->
        <v-expansion-panel>
          <v-expansion-panel-header>ELMCO FABRICATION</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="rate-section">
              <div class="category-header">Labor Rates</div>
              <v-simple-table>
                <tbody>
                  <tr v-for="lr in db.elmco_fabrication_rates.labor_rates">
                    <td style="text-align:left">{{ lr.Classification }}</td>
                    <td style="text-align:right">
                      <div>Standard Time Revenue</div>
                      <span class="click-rate" @click="editRate('elmco_fabrication_rates/labor_rates/' + lr.pr_class + '/st_revenue_rate')">{{
                        formatCurrency(db.elmco_fabrication_rates.labor_rates[lr.pr_class].st_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Standard Time Cost</div>
                      <span class="click-rate" @click="editRate('elmco_fabrication_rates/labor_rates/' + lr.pr_class + '/st_cost_rate')">{{
                        formatCurrency(db.elmco_fabrication_rates.labor_rates[lr.pr_class].st_cost_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Revenue</div>
                      <span class="click-rate" @click="editRate('elmco_fabrication_rates/labor_rates/' + lr.pr_class + '/ot_revenue_rate')">{{
                        formatCurrency(db.elmco_fabrication_rates.labor_rates[lr.pr_class].ot_revenue_rate)
                      }}</span>
                    </td>
                    <td style="text-align:right">
                      <div>Overtime Cost</div>
                      <span class="click-rate" @click="editRate('elmco_fabrication_rates/labor_rates/' + lr.pr_class + '/ot_cost_rate')">{{
                        formatCurrency(db.elmco_fabrication_rates.labor_rates[lr.pr_class].ot_cost_rate)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <EditGlobalRate v-if="db_path" @close="db_path = false" :dbpath="db_path" />
    <AddFieldLaborRevenueRates v-if="add_field_labor_revenue_rates" @close="add_field_labor_revenue_rates = false;updateCustomers()"  />
    <EditFieldLaborRevenueRates v-if="edit_field_labor_revenue_rates" :customername='edit_field_labor_for_customer' @close="edit_field_labor_revenue_rates = false;updateCustomers()"  />
    <AddFieldEquipment v-if="add_field_equipment" @close="add_field_equipment = false"  />

    <EditFieldEquipment v-if="edit_field_equipment" @close="edit_field_equipment = false"  :equipmentkey='edit_equipment_key'/>

    <AddFieldMaterial v-if="add_field_material" @close="add_field_material = false"  />

    <EditFieldMaterial v-if="edit_field_material" @close="edit_field_material = false"  :materialkey='edit_material_key'/>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditGlobalRate from '../components/EditGlobalRate.vue';
import AddFieldLaborRevenueRates from '../components/AddFieldLaborRevenueRates.vue';
import EditFieldLaborRevenueRates from '../components/EditFieldLaborRevenueRates.vue';
import AddFieldEquipment from '../components/AddFieldEquipment.vue';
import EditFieldEquipment from '../components/EditFieldEquipment.vue';
import EditFieldMaterial from '../components/EditFieldMaterial.vue';
import AddFieldMaterial from '../components/AddFieldMaterial.vue';
import MSA_Admin from '../components/MSA_Admin.vue';
import { formatCurrency } from '../mixins/formatCurrency.js';

export default {
  name: 'rates',
  components: { EditGlobalRate,AddFieldLaborRevenueRates,EditFieldLaborRevenueRates,AddFieldEquipment,EditFieldEquipment,AddFieldMaterial,EditFieldMaterial,MSA_Admin },
  mixins: [formatCurrency],
  data() {
    return {
      db_path: false,
      show_sub: false,
      add_field_labor_revenue_rates: false,
      edit_field_labor_revenue_rates: false,
      edit_field_labor_for_customer: null,
      edit_field_equipment: false,
      edit_equipment_key: null,
      add_field_equipment: false,
      add_field_material: false,
      edit_field_material: false,
      edit_material_key: null,
      customers_arr: []
    };
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      is_admin: 'is_admin',
      is_msa_admin: 'is_msa_admin'
    }),
    sorted_equipment(){
      var equipment_clone = JSON.parse( JSON.stringify(this.db.field_rates.equipment_rates) )
      var arr = []
      for(var e in equipment_clone){
        var obj = equipment_clone[e]
        obj.key = e
        arr.push(obj)
      }
      arr = _.orderBy(arr, 'description', 'asc'); // Use Lodash to sort array by 'name'
      return arr
    },
    sorted_material(){
      var material_clone = JSON.parse( JSON.stringify(this.db.field_rates.material_rates) )
      var arr = []
      for(var m in material_clone){
        var obj = material_clone[m]
        obj.key = m
        arr.push(obj)
      }
      arr = _.orderBy(arr, 'item', 'asc'); // Use Lodash to sort array by 'name'
      return arr
    },
    fabricationLaborClassifications() {
      return Object.keys(this.db.fabrication_rates.LaborRatesOT);
    },
    fabricationPayrollClasses() {
      return Object.keys(this.db.fabrication_rates.LaborRatesOT);
    },
    fabricationMaterialCodes() {
      return ['CAGE', 'HRLL', 'HRLS', 'HRPL', 'HRPS', 'LAD'];
    },
  },
  beforeMount() {
    if (!this.is_admin) {
      this.$router.push('/home');
    }
    this.updateCustomers()
  },
  methods: {
    updateCustomers(){
      for(var c in this.db.field_rates.labor_revenue_rates){
        var customer = c.slice(0, -2).trim()
        if(this.customers_arr.indexOf(customer) === -1){
          this.customers_arr.push(customer)
        }
      }
      this.customers_arr.sort()
    },
    editRate(path_str) {
      this.db_path = path_str;
    },
    showSub(sub){
      this.show_sub = this.show_sub !== sub ? sub : false
    },
    editFieldLaborRevenueRates(customer){
      this.edit_field_labor_for_customer = customer
      this.edit_field_labor_revenue_rates = true
    },
    editFieldEquipment(key){
      this.edit_equipment_key = key
      this.edit_field_equipment = true
    },
    editFieldMaterial(key){
      this.edit_material_key = key
      this.edit_field_material = true
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/global.scss';
.header {
  text-align: left;
  font-weight: bold;
  padding-bottom: 10px;
}

.category-header {
  font-size: 14px;
  background: #223773;
  color: white;
  padding: 4px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.rate-section {
  border: 1px solid lightgray;
  padding: 5px 20px 12px 20px;
  position: relative;
  margin: 10px;
}

thead td {
  padding-top: 10px;
  line-height: 100% !important;
  font-size: 12px;
}

tbody td {
  text-align: center;
}

.click-rate {
  color: blue;
  cursor: pointer;
}
.rates-btn{
  color: #223773;
  font-size: 12px;
  margin: 10px;
  cursor: pointer;
  width: 115px;
}
.container{
  max-width:5000px;
}
</style>
