<template>
    <header class="banner">
      <div class="toolbar-content">
        <div class="d-flex align-center">
          <img src="../assets/logo_new.svg" style="width:125px;">
        </div>
        <div class="d-flex align-center"><v-btn text class="logout-btn" @click="logout">Log Out</v-btn></div>
      </div>
    </header>
</template>

<script>
import Firebase from 'firebase/app'
export default {
    name: 'Banner',
    components: {},
    data(){
      return {

      }
    },
    computed: {

    },
    mounted(){

    },
    methods: {
      logout(){
        var self = this;
        Firebase.auth().signOut().then(function() {
          self.$router.push('/')
        })
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../styles/global.scss";
  @import url('https://fonts.googleapis.com/css2?family=Goldman&display=swap');

  .banner{
    max-width: 100%;
    height: 49px;
    border-bottom: 1px solid #d9d9d9;
  }

  .banner-title{
    font-family: 'Goldman';
    color: white;
    display: flex;
  }

  .banner-title-left{
    background: $ms-red;
    padding: 2px 4px;
  }

  .banner-title-right{
    background: $primary;
    padding: 2px;
  }

  .toolbar-content{
    display: flex;
    position: relative;
    z-index: 0;
    height: 100%;
    padding-left: 15px;
  }

  .v-btn.logout-btn{
    position: absolute;
    right: 0px;
    color: $primary;
    display: flex !important;
    font-weight: normal;
    text-transform: none;
    font-size: 12px;
  }

</style>
