import emailjs from 'emailjs-com'

export const emailMixin = {
  created() {

  },
  methods:{
    requestQuoteNumberEmail(account,opportunity){
      var templateParams = {
        account: account,
        opportunity: opportunity
      }
      emailjs.send('service_f2xnjmw', 'template_i0p7v93', templateParams, 'user_UuCxWh3KhHKPm1hzHuUs8')
        .then(function(response) {
           console.log('EMAIL SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('response FAILED:', error);
        });
    },
    estimateAssignment(to,department,account,opportunity, quote_number){
      var templateParams = {
        to: to,
        department: department,
        account: account,
        opportunity: opportunity,
        quote_number: quote_number
      }
      emailjs.send('service_f2xnjmw', 'template_714jfre', templateParams, 'user_UuCxWh3KhHKPm1hzHuUs8')
        .then(function(response) {
           console.log('EMAIL SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('response FAILED:', error);
        });
    },
    opportunityStageUpdate(to,account,opportunity, quote_number, old_stage, new_stage, stage_updated_by){
      var templateParams = {
        to: to,
        account: account,
        opportunity: opportunity,
        quote_number: quote_number,
        old_stage: old_stage,
        new_stage: new_stage,
        stage_updated_by: stage_updated_by
      }
      emailjs.send('service_f2xnjmw', 'template_oq4cadu', templateParams, 'user_UuCxWh3KhHKPm1hzHuUs8')
        .then(function(response) {
           console.log('EMAIL SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('response FAILED:', error);
        });
    },
    prebidAttendee(to,account,opportunity,quote_number,prebid_info){
      var templateParams = {
        to: to,
        account: account,
        opportunity: opportunity,
        quote_number: quote_number,
        prebid_info: prebid_info
      }
      emailjs.send('service_f2xnjmw', 'template_nstb1cq', templateParams, 'user_UuCxWh3KhHKPm1hzHuUs8')
        .then(function(response) {
           console.log('EMAIL SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('response FAILED:', error);
        });
    },
    newOppNotificationEmail(opportunityId,key){

      var templateParams = {
        oppData : opportunityId + key
      }
      emailjs.send('service_pmya31j', 'template_hladkop',templateParams, 'user_UuCxWh3KhHKPm1hzHuUs8')
        .then(function(response) {
           console.log('EMAIL SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('response FAILED:', error);
        });
    }
  }
}
