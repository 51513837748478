<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent style="background:#223773">

    <v-list-item class="px-2">
          <v-btn class="mr-2" fab dark x-small color="#f5f5f5" style="cursor:default">
            <div style="font-size:12px;color:#223773" v-if="db.users && current_user">{{db.users[current_user].first_name[0]+db.users[current_user].last_name[0]}}</div>
          </v-btn>
          <!-- <v-list-item-title v-if="db.users && current_user" style="font-size:12px;padding-left:0px;color:#f5f5f5">{{db.users[current_user].role}}</v-list-item-title> -->
      <!-- <v-btn icon @click.stop="mini = !mini" color="#f5f5f5"><v-icon>mdi-chevron-left</v-icon></v-btn> -->
    </v-list-item>

    <v-divider style="margin:0px"></v-divider>

    <v-list dense style="padding:0">
      <v-list-item v-for="item in items" v-if="db.users && (item.title !== 'Administration' || is_admin)" :key="item.title" link @click="$route.path !== item.route ? $router.push(item.route) : false" :class="{ active: $route.path === item.route }">

        <v-tooltip right v-if="true" color="#223773">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon :style="{color: $route.path === item.route ? '#223773' : '#f5f5f5'}">{{ item.icon }}</v-icon>
            </v-list-item-icon>
          </template>
          <span>{{item.title}}</span>
        </v-tooltip>
<!--
        <v-list-item-icon v-if="!mini">
          <v-icon :style="{color: $route.path === item.route ? '#223773' : '#f5f5f5'}">{{ item.icon }}</v-icon>
        </v-list-item-icon> -->

        <v-list-item-content>
          <v-list-item-title :style="{color: $route.path === item.route ? '#223773' : '#f5f5f5'}">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div style="position:absolute;bottom:5px;font-size:10px;width:50px;text-align:center;color:lightgray">V {{$store.state.version}}</div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'SideMenu',
    components: {},
    data(){
      return {
        drawer: true,
        items: [
          { title: 'Home', icon: 'mdi-home', route: '/home' },
          { title: 'Accounts', icon: 'mdi-card-account-details', route: '/accounts' },
          { title: 'Contacts', icon: 'mdi-card-account-phone', route: '/contacts' },
          { title: 'Opportunities', icon: 'mdi-cash-multiple', route: '/opportunities' },
          { title: 'Reports', icon: 'mdi-file-table', route: '/reports' },
          { title: 'Administration', icon: 'mdi-cog', route: '/admin' }
        ],
        mini: true
      }
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        is_admin: 'is_admin'
      })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" scoped>

  .v-navigation-drawer{
    width: 50px !important;
  }

  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child{
    margin-right: 10px;
  }

  .active{
    background: #f5f5f5
  }
</style>
