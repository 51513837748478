<template>
  <v-dialog scrollable persistent v-model="dialog" width="500px">
    <v-form ref="form" lazy-validation>
      <v-card>
        <div class="form-header">Edit Quote Rate</div>

        <v-card-text>
            <v-card style="">
              <v-card-text style="text-align:left">
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" min="0" label="" outlined v-model.number="new_rate" v-on:keydown.enter.prevent=''></v-text-field>
              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveRate()" small :disabled="initialrate === new_rate || new_rate === ''">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency } from '../mixins/formatCurrency.js'

export default {
  name: 'EditQuoteRate',
  components: {},
  mixins: [formatCurrency],
  data(){
    return{
      dialog: true,
      new_rate: 0
    }
  },
  props: {
    initialrate: Number
  },
  beforeMount(){
    var self = this
    self.new_rate = self.initialrate
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    saveRate(){
      this.$emit('saveQuoteRate',this.new_rate)
    }
  }
}
</script>

<style lang="scss">

</style>


