<template>
      <v-dialog v-model="id" scrollable persistent max-width="1100px;">

        <v-form ref="form" lazy-validation>
          <v-card>
            <div class="form-header">{{id === 'new' ? 'New' : 'Edit'}} Contact</div>
            <v-card-text>
              <v-container style="position:relative">

                <div class="form-banner">Contact Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete sort="name" required :rules="accountNameRules" v-model="form_data.account" :items="active_accounts" item-text="name" item-value="id" label="*Account Name" dense outlined></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete sort="name" v-model="form_data.owner" :items="active_users" item-text="name" item-value="id" label="*Contact Owner" dense outlined :disabled="!is_admin"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="2">
                    <v-select dense :items="['Mr','Ms','Mrs','Dr.','Prof.']" outlined label="Salutation" v-model="form_data.salutation"></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="*First Name" required :rules="firstNameRules" outlined v-model="form_data.first_name"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Middle Name" outlined v-model="form_data.middle_name"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field dense @focus="$event.target.select()" label="*Last Name" required :rules="lastNameRules" outlined v-model="form_data.last_name"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Title" outlined v-model="form_data.title"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Department" outlined v-model="form_data.department"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Email" outlined v-model="form_data.email"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Fax" outlined v-model="form_data.fax"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Phone" outlined v-model="form_data.phone"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Mobile" outlined v-model="form_data.mobile"></v-text-field>
                  </v-col>
                </v-row>

                <div class="form-banner">Address Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Mailing Address" outlined v-model="form_data.mailing_address"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Mailing City" outlined v-model="form_data.mailing_city"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Mailing State" outlined v-model="form_data.mailing_state"></v-text-field>
                  </v-col>
                </v-row>


                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-textarea dense label="Mailing Street" outlined rows="4" class="line-height-adjustment" v-model="form_data.mailing_street"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Mailing Zip" outlined v-model="form_data.mailing_zip"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Mailing Country" outlined v-model="form_data.mailing_country"></v-text-field>
                  </v-col>
                </v-row>

                <div style="text-align:left;font-size:12px">*indicates required field</div>

              </v-container>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">Cancel</v-btn>
              <v-btn color="primary" @click="saveContact()">Save</v-btn>
            </v-card-actions>
          </v-card>

        </v-form>
      </v-dialog>


</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';

  export default {
    name: 'ContactForm',
    props: {
        id: String
    },
    data(){
      return{
        searchInput: "",
        form_data: {
          'owner': '',
          'salutation': '',
          'first_name' : '',
          'middle_name' : '',
          'last_name' : '',
          'account': '',
          'title': '',
          'department': '',
          'email' : '',
          'fax': '',
          'phone': '',
          'mobile': '',
          'mailing_address': '',
          'mailing_street': '',
          'mailing_city': '',
          'mailing_state': '',
          'mailing_zip': '',
          'mailing_country': ''
        },
        valid: false,
        selected: '--None--',
        firstNameRules: [
          v => !!v.trim() || 'First Name is required'
        ],
        lastNameRules: [
          v => !!v.trim() || 'Last Name is required'
        ],
        accountNameRules: [
          v => !!v.trim() || 'Account Name is required'
        ],
      }
    },
    mounted(){
      if(this.id !== 'new'){
        this.form_data = JSON.parse( JSON.stringify( this.db.contacts[this.id] ) )
      }else{
        this.form_data.owner = this.current_user
      }
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        active_accounts: 'active_accounts',
        active_contacts: 'active_contacts',
        active_users: 'active_users',
        is_admin: 'is_admin'
      })
    },
    methods: {
      saveContact(){
        var self = this
        var is_valid = self.$refs.form.validate()

        if(! is_valid){
          self.$store.state.notification = 'Please complete required fields.'
          return
        }

        self.$store.state.isLoading = true

        if(this.id === 'new'){
          self.form_data.added_by = self.current_user
          self.form_data.created_date = Date.now()
          Firebase.database().ref('contacts').push(self.form_data).then(function(snapshot){
            Firebase.database().ref('contacts').child(snapshot.key).child('id').set(snapshot.key).then(function(){
              self.$store.state.notification = 'Contact successfully saved.'
              self.$store.state.isLoading = false
              self.$emit('close',snapshot.key)
            })
          })
        }

        if(this.id !== 'new'){
          self.form_data.edited_by = self.current_user
          self.form_data.edit_date = Date.now()
          Firebase.database().ref('contacts').child(self.id).set(self.form_data).then(function(snapshot){
            self.$store.state.notification = 'Contact successfully saved.'
            self.$store.state.isLoading = false
            self.$emit('close')
          })
        }
      }
    }
  }
</script>
<style lang="scss">

</style>

