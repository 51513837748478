<template>
  <v-dialog scrollable persistent v-model="dialog" width="500px">
    <v-form ref="form" lazy-validation>
      <v-card>
        <div class="form-header">Revenue Override</div>

        <v-card-text>
            <v-card>
              <v-card-text style="text-align:left;width:150px;margin:0 auto">
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" outlined suffix="%" v-model.number="override_percentage" v-on:keydown.enter.prevent=''></v-text-field>
              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveRevenueOverride()" small>SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditRevenueOverride',
  components: {},
  mixins: [],
  data(){
    return{
      dialog: true,
      override_percentage: 0
    }
  },
  props: {
    opportunityid: String
  },
  beforeMount(){
    var self = this
    Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(opp_snapshot){
      var opp = opp_snapshot.val()
      self.override_percentage = opp.revenue_override_percentage ? opp.revenue_override_percentage : 0
    })
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    saveRevenueOverride(){
      this.override_percentage = this.override_percentage ? this.override_percentage : 0
      this.$emit('saveRevenueOverride',this.override_percentage)
    }
  }
}
</script>

<style lang="scss">

</style>


