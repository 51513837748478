<template>
  <v-dialog scrollable persistent v-model="dialog" width="100%">
    <v-form ref="form" lazy-validation>
      <v-card style="background:#eeeeee" v-if="form_mounted">
        <div class="form-header">Excel Quote Sheet</div>

        <v-card-text>
          <!-- GENERAL -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">GENERAL</div>

              <v-row>
                <v-col cols="6">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quote Title*" outlined v-model="quote.quote_title"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="!isNew">
                  <div style="padding-top:10px;color:darkred">This quote was an Excel import. Only the title and completed status can be modified.</div>
                </v-col>
                <v-col cols="6" v-if="isNew">
                  <div v-if="!filelist.length">
                    <input type="file" name="fields[assetsFieldHandleExcel][]" id="assetsFieldHandleExcel" @change="onChangeExcel" ref="fileExcel" style="opacity: 0;display:none"/>
                    <v-btn color="primary" @click="searchFiles()">Upload Excel Quote Sheet</v-btn>
                  </div>
                  <div v-if="filelist.length">
                    <table class="uploaded-files-table" style="border:none">
                      <tr v-for="file in filelist" style="padding-top:10px;border:none">
                        <td style="padding-top:10px;padding-right:15px;border:none">{{ file.name }}</td>
                        <td style="padding-top:10px;border:none">
                          <v-btn color="primary" @click="removeExcel(filelist.indexOf(file))" x-small>DELETE</v-btn>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Completed" hide-detail v-model="quote.completed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Reviewed" hide-detail v-model="quote.reviewed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <table id="detail-table" v-if="table_arr.length > 0">
                <tr style="background:#121212;color:#d3d3d3;height:25px;border: 1px solid #4a4a4a">
                  <th v-for="header in table_cells" style="padding: 5px;font-size:12px;">{{header}}</th>
                </tr>
                <tr v-for="td in table_arr">
                  <td v-for="cell in table_cells" :class="{'total-row': td['Is Total Row']}">{{formatCell(cell, td[cell])}}</td>
                </tr>
              </table>
            </v-card-text>
          </v-card>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeForm()" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveQuote('replace')" small :disabled="!unsavedChanges">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>

  </v-dialog>

</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import { formatCurrency } from '../../mixins/formatCurrency.js'
import XLSX from 'xlsx'
import VueCurrencyFilter from 'vue-currency-filter'

export default {
  name: 'Excel',
  components: {},
  mixins: [formatCurrency],
  props:{
    opportunityid: String,
    quoteid: [String, Boolean]
  },
  data(){
    return{
      form_mounted: false,
      dialog: true,
      quote: {
        quote_sheet: 'Excel',
        department_quote_sheet: '',
        department: '',
        job_id_code: '',
        quote_type: 'New',
        quote_title: '',
        account: '',
        opportunity: '',
        created_by: '',
        created: '',
        phases: {},
        completed: false,
        reviewed: false,
        elmos: {},
        fab_elmos: {},
        show_in_master: true
      },
      initial_quote: {},
      isNew: true,
      filelist: [],
      table_cells: ['Category','Phase','Labor hrs','Labor Cost','Labor Rev','Material Cost','Material Rev','Equipment Cost','Equipment Rev','Sub Cost','Sub Rev','Other Cost','Other Rev','Total Cost','Total Rev'],
      table_arr: [],
      opportunity: {}
    }
  },
  beforeMount(){
    var self = this

    Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){

      self.opportunity = JSON.parse(JSON.stringify(snapshot.val()))
      self.isNew = self.quoteid ? false : true

      if(self.isNew){ // new quote
        self.quote.created_by = self.current_user
        self.quote.account = self.opportunity.account
        self.quote.opportunity = self.opportunityid
        self.quote.created = Date.now()
        self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        self.form_mounted = true
      }

      if(!self.isNew){ // edit quote
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).once('value',function(quote_snapshot){
          self.quote = JSON.parse(JSON.stringify(quote_snapshot.val()))
          self.initial_quote = JSON.parse(JSON.stringify(self.quote))
          self.form_mounted = true
        })
      }
    })
  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.calculateElmos()
      }else{
        this.table_arr = []
        this.quote.department_quote_sheet = ''
        this.quote.department = ''
        this.quote.job_id_code = ''
        this.quote.elmos = {}
        this.quote.fab_elmos = {}
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_accounts: 'active_accounts',
      active_users: 'active_users',
      ou_departments: 'ou_departments'
    }),
    unsavedChanges() {
      if(this.isNew){
        return JSON.stringify(this.initial_quote) !== JSON.stringify(this.quote) && this.filelist.length
      }else{
        return JSON.stringify(this.initial_quote) !== JSON.stringify(this.quote)
      }

    }
  },
  methods:{
    calculateElmos(file){
      var reader = new FileReader();
      var self = this
      var file = this.filelist[0]
      reader.readAsBinaryString(file);
      self.table_arr = []

      self.quote.elmos = {
        phases: {},
        total_revenue: 0,
        total_cost: 0,
        total_hours: 0,
        ou_code: self.quote.job_id_code,
        ou_department: self.quote.department
      };

      for(var i = 1; i < 51; i++) {
        self.quote.elmos.phases['Phase ' + i] = {
          equipment: { revenue: 0, cost: 0 },
          labor: { revenue: 0, cost: 0, hours: 0 },
          material: { revenue: 0, cost: 0 },
          other: { revenue: 0, cost: 0 },
          subcontracting: { revenue: 0, cost: 0 },
          total_revenue: 0,
          total_cost: 0,
        };
      }

      self.quote.fab_elmos = {
        "phase": 0,
        "material_cost": 0,
        "material_revenue": 0,
        "Fab Gen": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        },
        "Fab Subcontracting": {
          "subcontracting_cost": 0,
          "subcontracting_revenue": 0
        },
        "Fitting": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        },
        "Paint": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0,
          "material_cost": 0,
          "material_revenue": 0
        },
        "Pre Fab": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        },
        "Prep & PM": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        },
        "QC": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        },
        "Supervision": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        },
        "Welding": {
          "labor_cost": 0,
          "labor_hours": 0,
          "labor_revenue": 0
        }
      }

      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {type: 'binary'})
        var worksheet = workbook.Sheets['Summary']

        if(!worksheet){
          self.removeExcel(0)
          alert('Invalid File(s)')
          return
        }

        var json = XLSX.utils.sheet_to_json(worksheet)
        

        var format_type = Object.keys(json[0])[0] === '__EMPTY_16' ? 0 : 1

        var row_start = (json[0]['__EMPTY_17'] === 'Inputs' || json[0]['__EMPTY_16'] === 'Inputs') ? 4 : 1
        
        for(var i = row_start; i < json.length; i++){

          var arr = []
          var table_data = {}
          table_data['OU Code'] = (self.formatOU(format_type === 0 ? json[i]['__EMPTY_1'] : json[i]['__EMPTY_2'] )) ? self.formatOU(format_type === 0 ? json[i]['__EMPTY_1'] : json[i]['__EMPTY_2']) : ''

          if(table_data['OU Code'] === ''){
            alert('You must select the OU and department on the Excel quote sheet before importing.')
            self.removeExcel(0)
            return
          }

          table_data['OU Department'] = (format_type === 0 ? json[i]['__EMPTY_2'] : json[i]['__EMPTY_3']) ? format_type === 0 ? json[i]['__EMPTY_2'] : json[i]['__EMPTY_3'] : ''
          table_data['Category'] = (format_type === 0 ? json[i]['__EMPTY_3'] : json[i]['__EMPTY_4']) ? format_type === 0 ? json[i]['__EMPTY_3'] : json[i]['__EMPTY_4'] : ''
          table_data['Phase'] = (format_type === 0 ? json[i]['__EMPTY_4'] : json[i]['__EMPTY_5']) ? format_type === 0 ? json[i]['__EMPTY_4'] : json[i]['__EMPTY_5'] : ''
          table_data['Labor hrs'] = (format_type === 0 ? json[i]['__EMPTY_6'] : json[i]['__EMPTY_7']) ? format_type === 0 ? json[i]['__EMPTY_6'] : json[i]['__EMPTY_7'] : 0
          table_data['Labor Cost'] = (format_type === 0 ? json[i]['__EMPTY_7'] : json[i]['__EMPTY_8']) ? format_type === 0 ? json[i]['__EMPTY_7'] : json[i]['__EMPTY_8'] : 0
          table_data['Labor Rev'] = (format_type === 0 ? json[i]['__EMPTY_8'] : json[i]['__EMPTY_9']) ? format_type === 0 ? json[i]['__EMPTY_8'] : json[i]['__EMPTY_9'] : 0
          table_data['Material Cost'] = (format_type === 0 ? json[i]['__EMPTY_9'] : json[i]['__EMPTY_10']) ? format_type === 0 ? json[i]['__EMPTY_9'] : json[i]['__EMPTY_10'] : 0
          table_data['Material Rev'] = (format_type === 0 ? json[i]['__EMPTY_10'] : json[i]['__EMPTY_11']) ? format_type === 0 ? json[i]['__EMPTY_10'] : json[i]['__EMPTY_11'] : 0
          table_data['Equipment Cost'] = (format_type === 0 ? json[i]['__EMPTY_11'] : json[i]['__EMPTY_12']) ? format_type === 0 ? json[i]['__EMPTY_11'] : json[i]['__EMPTY_12'] : 0
          table_data['Equipment Rev'] = (format_type === 0 ? json[i]['__EMPTY_12'] : json[i]['__EMPTY_13']) ? format_type === 0 ? json[i]['__EMPTY_12'] : json[i]['__EMPTY_13'] : 0
          table_data['Sub Cost'] = (format_type === 0 ? json[i]['__EMPTY_13'] : json[i]['__EMPTY_14']) ? format_type === 0 ? json[i]['__EMPTY_13'] : json[i]['__EMPTY_14'] : 0
          table_data['Sub Rev'] = (format_type === 0 ? json[i]['__EMPTY_14'] : json[i]['__EMPTY_15']) ? format_type === 0 ? json[i]['__EMPTY_14'] : json[i]['__EMPTY_15'] : 0
          table_data['Other Cost'] = (format_type === 0 ? json[i]['__EMPTY_15'] : json[i]['__EMPTY_16']) ? format_type === 0 ? json[i]['__EMPTY_15'] : json[i]['__EMPTY_16'] : 0
          table_data['Other Rev'] = (format_type === 0 ? json[i]['__EMPTY_16'] : json[i]['__EMPTY_17']) ? format_type === 0 ? json[i]['__EMPTY_16'] : json[i]['__EMPTY_17'] : 0
          table_data['Total Cost'] = (format_type === 0 ? json[i]['__EMPTY_17'] : json[i]['__EMPTY_18']) ? format_type === 0 ? json[i]['__EMPTY_17'] : json[i]['__EMPTY_18'] : 0
          table_data['Total Rev'] = (format_type === 0 ? json[i]['__EMPTY_18'] : json[i]['__EMPTY_19']) ? format_type === 0 ? json[i]['__EMPTY_18'] : json[i]['__EMPTY_19'] : 0
          table_data['Is Total Row'] = i+1 === json.length

          for(var y = 0; y < self.ou_departments.length; y++){
            if(self.ou_departments[y].code === table_data['OU Code']){
              self.quote.department_quote_sheet = self.ou_departments[y].quote_sheet
              self.quote.department = self.ou_departments[y].department
              self.quote.job_id_code = self.ou_departments[y].code
              self.quote.elmos.ou_code = self.ou_departments[y].code
              self.quote.elmos.ou_department = self.ou_departments[y].department
            }
          }

          var phase_number = parseInt(table_data['Phase'].split(' ')[1])

          if(table_data['OU Code'] !== '004' && !table_data['Is Total Row']){

              self.quote.elmos.phases['Phase ' + phase_number].equipment.revenue += table_data['Equipment Rev']
              self.quote.elmos.phases['Phase ' + phase_number].equipment.cost += table_data['Equipment Cost']

              self.quote.elmos.phases['Phase ' + phase_number].labor.revenue += table_data['Labor Rev']
              self.quote.elmos.phases['Phase ' + phase_number].labor.cost += table_data['Labor Cost']
              self.quote.elmos.phases['Phase ' + phase_number].labor.hours += table_data['Labor hrs']

              self.quote.elmos.phases['Phase ' + phase_number].material.revenue += table_data['Material Rev']
              self.quote.elmos.phases['Phase ' + phase_number].material.cost += table_data['Material Cost']

              self.quote.elmos.phases['Phase ' + phase_number].other.revenue += table_data['Other Cost']
              self.quote.elmos.phases['Phase ' + phase_number].other.cost += table_data['Other Rev']

              self.quote.elmos.phases['Phase ' + phase_number].subcontracting.revenue += table_data['Sub Rev']
              self.quote.elmos.phases['Phase ' + phase_number].subcontracting.cost += table_data['Sub Cost']

              self.quote.elmos.phases['Phase ' + phase_number].total_revenue += table_data['Total Rev']
              self.quote.elmos.phases['Phase ' + phase_number].total_cost += table_data['Total Cost']

              self.quote.elmos.total_revenue += table_data['Total Rev']
              self.quote.elmos.total_cost += table_data['Total Cost']
              self.quote.elmos.total_hours += table_data['Labor hrs']
          }

          if(table_data['OU Code'] === '004'){

            self.quote.fab_elmos.phase = phase_number
            // self.quote.fab_elmos['Fab Subcontracting'].subcontracting_cost += table_data['Sub Cost']
            // self.quote.fab_elmos['Fab Subcontracting'].subcontracting_revenue += table_data['Sub Rev']
            self.quote.fab_elmos['Fab Subcontracting'].subcontracting_cost = table_data['Sub Cost']
            self.quote.fab_elmos['Fab Subcontracting'].subcontracting_revenue = table_data['Sub Rev']

            if(table_data['Category'].toLowerCase().indexOf('paint') !== -1){
              self.quote.fab_elmos['Paint'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Paint'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Paint'].labor_hours += table_data['Labor hrs']
              self.quote.fab_elmos['Paint'].material_cost += table_data['Material Cost']
              self.quote.fab_elmos['Paint'].material_revenue += table_data['Material Rev']
            }

            if(table_data['Category'].toLowerCase().indexOf('fab gen') !== -1){
              self.quote.fab_elmos['Fab Gen'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Fab Gen'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Fab Gen'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Category'].toLowerCase().indexOf('fitting') !== -1){
              self.quote.fab_elmos['Fitting'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Fitting'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Fitting'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Category'].toLowerCase().indexOf('pre fab') !== -1){
              self.quote.fab_elmos['Pre Fab'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Pre Fab'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Pre Fab'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Category'].toLowerCase().indexOf('prep') !== -1){
              self.quote.fab_elmos['Prep & PM'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Prep & PM'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Prep & PM'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Category'].toLowerCase().indexOf('qc') !== -1){
              self.quote.fab_elmos['QC'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['QC'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['QC'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Category'].toLowerCase().indexOf('supervision') !== -1){
              self.quote.fab_elmos['Supervision'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Supervision'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Supervision'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Category'].toLowerCase().indexOf('welding') !== -1){
              self.quote.fab_elmos['Welding'].labor_cost += table_data['Labor Cost']
              self.quote.fab_elmos['Welding'].labor_revenue += table_data['Labor Rev']
              self.quote.fab_elmos['Welding'].labor_hours += table_data['Labor hrs']
            }

            if(table_data['Is Total Row']){

              self.quote.elmos.phases['Phase ' + phase_number].labor.revenue = table_data['Labor Rev']
              self.quote.elmos.phases['Phase ' + phase_number].labor.cost = table_data['Labor Cost']
              self.quote.elmos.phases['Phase ' + phase_number].labor.hours = table_data['Labor hrs']

              self.quote.elmos.phases['Phase ' + phase_number].material.revenue = table_data['Material Rev']
              self.quote.elmos.phases['Phase ' + phase_number].material.cost = table_data['Material Cost']

              self.quote.elmos.phases['Phase ' + phase_number].equipment.revenue = table_data['Equipment Rev']
              self.quote.elmos.phases['Phase ' + phase_number].equipment.cost = table_data['Equipment Cost']

              self.quote.elmos.phases['Phase ' + phase_number].subcontracting.revenue = table_data['Sub Rev']
              self.quote.elmos.phases['Phase ' + phase_number].subcontracting.cost = table_data['Sub Cost']

              self.quote.elmos.phases['Phase ' + phase_number].other.revenue = table_data['Other Rev']
              self.quote.elmos.phases['Phase ' + phase_number].other.cost = table_data['Other Cost']

              self.quote.elmos.phases['Phase ' + phase_number].total_revenue = table_data['Total Rev']
              self.quote.elmos.phases['Phase ' + phase_number].total_cost = table_data['Total Cost']

              self.quote.fab_elmos.material_cost = table_data['Material Cost'] - self.quote.fab_elmos['Paint'].material_cost
              self.quote.fab_elmos.material_revenue = table_data['Material Rev'] - self.quote.fab_elmos['Paint'].material_revenue

              self.quote.elmos.total_revenue = table_data['Total Rev']
              self.quote.elmos.total_cost = table_data['Total Cost']
              self.quote.elmos.total_hours = table_data['Labor hrs']
            }
          }

          self.table_arr.push(table_data)

        }

      };

    },
    formatOU(ou){
      if(String(ou).length === 1){
        return '00' + String(ou)
      }
      if(String(ou).length === 2){
        return '0' + String(ou)
      }
      if(String(ou).length === 3){
        return String(ou)
      }
    },
    formatCC(cc){
      if(String(cc).length === 1){
        return '0' + String(cc)
      }
      if(String(cc).length === 2){
        return '0' + String(cc)
      }
      if(String(cc).length === 3){
        return String(cc)
      }
    },
    formatCell(cell, value){
      if(cell.indexOf('Cost') !== -1 || cell.indexOf('Rev') !== -1){
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        });
        return formatter.format(value);
      }

      if(typeof value === 'number'){
        return Math.round(value)
      }
      return value
    },
    searchFiles(){
      document.getElementById("assetsFieldHandleExcel").click()
    },
    onChangeExcel() {
      var files_arr = [...this.$refs.fileExcel.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
    },
    removeExcel(i) {
      this.filelist.splice(i, 1);
    },
    closeForm(){
      if(this.unsavedChanges){
        var confirm_close = confirm('You have unsaved changes. Are you sure you want to close this form? Any unsaved changes will be lost.')
        if(confirm_close){
          this.$emit('close')
        }
      }else{
        this.$emit('close')
      }
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YYYY')
    },
    saveQuote(save_type){

      var self = this
      self.$store.state.isLoading = true

      if(self.quote.quote_title.trim() === ''){
        self.$store.state.isLoading = false
        self.$store.state.notification = 'Please provide a title for this quote before saving.'
        return
      }

      Firebase.database().ref('opportunities').child(self.opportunityid).child('quote_last_modified').set(Date.now())
      
      // NEW
      if(self.isNew){
        Firebase.database().ref('quotes').child(self.opportunityid).push(self.quote).then(function(snapshot){
          Firebase.database().ref('totals').child(self.opportunityid).child(snapshot.key).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: true, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            var file = self.filelist[0]
            var extension = self.opportunityid + '/' + file.name
            var metadata = {customMetadata : {uploadedBy: self.current_user, uploadDate: Date.now()}}
            Firebase.storage().ref().child(extension).put(file,metadata).then((snapshot) => {
              self.$store.state.isLoading = false
              self.$emit('close')
            }).catch(function(err){
              self.$store.state.isLoading = false
              alert(err)
            });
          })
        })
      }

      // EDIT & REPLACE
      if(!self.isNew && save_type === 'replace'){
        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).child('quote_title').set(self.quote.quote_title).then(function(){
          Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).child('completed').set(self.quote.completed).then(function(){
            Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).child('reviewed').set(self.quote.reviewed).then(function(){
              Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).child('completed').set(self.quote.completed).then(function(){
                Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).child('reviewed').set(self.quote.reviewed).then(function(){
                  self.$store.state.isLoading = false
                  self.$emit('close')
                })
              })
            })
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .section{
    text-align: left;
    margin: 10px;
  }

  .section-header{
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .section-subheader{
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
    font-size: 10px;
  }

  #summary-section{
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    background: #eeeeee;
    text-align: left;
    width: 850px;
  }

  textarea{
    height: 25px;
  }

  hr{
    margin-top: 20px;
  }

  thead td {
    line-height: 100% !important;
    text-align: center;
    font-size: 12px;
  }

  tr:hover {
    background-color: transparent !important;
  }

  .search-files-btn{
    width: 200px;
    padding: 5px;
    font-weight: bold;
    background: #639cd9;
    color: white;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 4px;
  }

  #detail-table{
    margin-bottom: 10px;
    margin-top: 10px;
    background: white;
    color: black;
  }

  #detail-table td{
    border: 1px solid #4a4a4a;
    border-collapse: collapse;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 75px;
    font-size: 12px;
  }

  table, th, td {
    border-collapse: collapse;
    white-space: nowrap;
    text-align: left;
    background: white;
    color: black;
    border:1px solid;
  }

  .total-row{
    background: lightgray;
    color: black;
    font-weight: 500;
  }
</style>


