<template>
  <v-dialog scrollable persistent v-model="dialog" width="1200px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header">Add Field Labor Revenue Rates</div>

        <v-card-text>
            <v-card style="">
              <v-card-text style="text-align:left">
              <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Customer Name" outlined v-model="customer.name" v-on:keydown.enter.prevent=''></v-text-field>

              <div style="height:12px"><span style="color:red" v-if="customer_already">Customer already exists.</span></div>

              <v-simple-table>
                <thead>
                  <tr>
                    <th style=""></th>
                    <th style="">Project Manager</th>
                    <th style="">General Superintendent</th>
                    <th style="">Foreman</th>
                    <th style="">Journeymen</th>
                    <th style="">Helper/FW/HW</th>
                    <th style="">Safety Officer</th>
                    <th style="">QAQC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ST</td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.project_manager" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.general_superintendent" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.foreman" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.journeymen" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.helper" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.safety_officer" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.st.qaqc" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                  </tr>
                  <tr>
                    <td>OT</td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.project_manager" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.general_superintendent" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.foreman" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.journeymen" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.helper" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.safety_officer" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.ot.qaqc" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                  </tr>
                  <tr>
                    <td>DT</td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.project_manager" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.general_superintendent" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.foreman" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.journeymen" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.helper" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.safety_officer" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                    <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="customer.dt.qaqc" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></td>
                  </tr>
                </tbody>
              </v-simple-table>

              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveRate()" small :disabled="customer.name.trim() === '' || customer_already">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency } from '../mixins/formatCurrency.js'

export default {
  name: 'AddFieldLaborRevenueRates',
  components: {},
  mixins: [formatCurrency],
  data(){
    return{
      dialog: true,
      existing_customers: [],
      customer_already: false,
      customer: {
        name: '',
        st: {
          project_manager: 0,
          general_superintendent: 0,
          foreman: 0,
          journeymen: 0,
          helper: 0,
          safety_officer: 0,
          qaqc: 0
        },
        ot: {
          project_manager: 0,
          general_superintendent: 0,
          foreman: 0,
          journeymen: 0,
          helper: 0,
          safety_officer: 0,
          qaqc: 0
        },
        dt: {
          project_manager: 0,
          general_superintendent: 0,
          foreman: 0,
          journeymen: 0,
          helper: 0,
          safety_officer: 0,
          qaqc: 0
        }
      }
    }
  },
  beforeMount(){
    for(var c in this.db.field_rates.labor_revenue_rates){
      var customer = c.slice(0, -2).trim().toLocaleLowerCase()
      if(this.existing_customers.indexOf(customer) === -1){
        this.existing_customers.push(customer)
      }
    }
  },
  watch: {
    customer: {
      deep: true,
      handler(){
        this.customer_already = this.existing_customers.indexOf(this.customer.name.trim().toLocaleLowerCase()) !== -1 ? true : false

        for(var craft in this.customer.st){
          this.customer['st'][craft] = typeof(this.customer['st'][craft]) === 'number' ? this.customer['st'][craft] : 0
        }
        for(var craft in this.customer.ot){
          this.customer['ot'][craft] = typeof(this.customer['ot'][craft]) === 'number' ? this.customer['ot'][craft] : 0
        }
        for(var craft in this.customer.ot){
          this.customer['dt'][craft] = typeof(this.customer['dt'][craft]) === 'number' ? this.customer['dt'][craft] : 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    saveRate(){
      var self = this
      var customer_name = self.customer.name.trim()
      var st_rates = self.customer.st
      var ot_rates = self.customer.ot
      var dt_rates = self.customer.dt

      Firebase.database().ref('field_rates/labor_revenue_rates').child(customer_name+'ST').set(st_rates).then(function(){
        Firebase.database().ref('field_rates/labor_revenue_rates').child(customer_name+'OT').set(ot_rates).then(function(){
          Firebase.database().ref('field_rates/labor_revenue_rates').child(customer_name+'DT').set(dt_rates).then(function(){
            self.$emit('close')
            self.$store.state.notification = 'Customer successfully added.'
          })
        })
      })


    }
  }
}
</script>

<style lang="scss" scoped>


</style>


