<template>
  <div class="opportunity">

    <v-container fluid style="width: calc(100vw - 200px)"  v-if="loaded">

      <v-card style="margin-bottom:20px;">
        <v-card-text>
          <v-row class="inputs-container" id="rfq">
            <v-col cols="12">
              <v-icon large>mdi-account-cash</v-icon>
              <span style="font-size:16px;font-weight:bold;padding-left:10px">Opportunity: {{opportunity.name}}</span>
              <div>{{opportunity.quote_number ? opportunity.quote_number + ' - ' : ''}}{{db.accounts[opportunity.account].name ? db.accounts[opportunity.account].name + ' - ' : ''}}{{opportunity.facility_location ? opportunity.facility_location + ' - ' : ''}}{{opportunity.name}}</div>
            </v-col>

            <v-col cols="12" v-if="unassignedEstimators">
              <div class="display-label"><span style="color: red;font-style:italic">This opportunity has unassigned estimators</span></div>
            </v-col>

            <v-col cols="12">
              <div class="display-label">Amount: <span style="color: green;">{{formatCurrency(opportunity_total_revenue_with_override)}}</span><span style="color: red;padding-left:5px" v-if="opportunity_revenue_override_percentage !== 0">(includes {{opportunity_revenue_override_percentage}}% revenue override)</span> </div>
              <div class="display-label">Cost: <span style="color: black;padding-left:21px">{{formatCurrency(opportunity_total_cost)}}</span> </div>
              <div class="display-label">Margin: <span style="color: black;padding-left:5px">{{calculateMargin(opportunity_total_cost,opportunity_total_revenue_with_override)}}</span> </div>
            </v-col>

            <v-col cols="12" v-if="opportunity.salesforce_opportunity">
              <div class="display-label"><span style="color: red;font-style:italic">This opportunity was migrated from salesforce. Features are limited.</span> </div>
            </v-col>

            <v-col cols="12">
              <div class="display-label">Stage: {{opportunity.stage}} </div>
            </v-col>

            <v-col cols="12">
              <div class="display-label">Scope of Work</div>
              <div class="display-value">{{opportunity.scope_of_work}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Created By</div>
              <div class="display-value">{{opportunity.added_by ? (db.users[opportunity.added_by].first_name + ' ' + db.users[opportunity.added_by].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Opportunity Owner</div>
              <div class="display-value">{{opportunity.owner ? (db.users[opportunity.owner].first_name + ' ' + db.users[opportunity.owner].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Account</div>
              <div class="display-value" v-if="db.accounts[opportunity.account]">{{db.accounts[opportunity.account].name}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Close/Award Date</div>
              <div class="display-value">{{formatDate(opportunity.close_date)}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Project Start Date</div>
              <div class="display-value">{{ opportunity.project_start_date ? formatDate(opportunity.project_start_date) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Project End Date</div>
              <div class="display-value">{{opportunity.project_end_date ? formatDate(opportunity.project_end_date) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Likelihood of Close</div>
              <div class="display-value">{{opportunity.likelihood_of_close}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Facility Location</div>
              <div class="display-value">{{opportunity.facility_location}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Next Steps</div>
              <div class="display-value">{{opportunity.next_steps}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Quote Number</div>
              <div class="display-value">{{opportunity.quote_number}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Old Quote/Project Number</div>
              <div class="display-value">{{opportunity.old_quote_number}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Quote Delivery</div>
              <div class="display-value">{{opportunity.quote_delivery}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Due Date</div>
              <div class="display-value">{{opportunity.due_date ? formatDate(opportunity.due_date) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Due Time</div>
              <div class="display-value">{{opportunity.due_time}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Quote Type</div>
              <div class="display-value">{{opportunity.quote_type}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Proposal Type</div>
              <div class="display-value">{{opportunity.proposal_type}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping/Delivery Method</div>
              <div class="display-value">{{opportunity.shipping_delivery_method}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Contact To Send Quote</div>
              <div class="display-value">{{opportunity.contact_to_send_quote ? (db.contacts[opportunity.contact_to_send_quote].first_name + ' ' + db.contacts[opportunity.contact_to_send_quote].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">To Be Furnished</div>
              <div class="display-value">{{opportunity.to_be_furnished ? opportunity.to_be_furnished.join(', ') : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Revenue Category</div>
              <div class="display-value">{{opportunity.revenue_category}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Build to Specific Code</div>
              <div class="display-value">{{opportunity.build_to_specific_code}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Coatings Information</div>
              <div class="display-value">{{opportunity.coatings_information}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Steel</div>
              <div class="display-value">{{opportunity.steel}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">All Material A36 CS</div>
              <div class="display-value">{{opportunity.all_material_a36_cs}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Pre-Bid</div>
              <div class="display-value">{{opportunity.pre_bid}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Pre-Bid Date</div>
              <div class="display-value">{{opportunity.pre_bid_date ? formatDate(opportunity.pre_bid_date) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Pre-Bid Time</div>
              <div class="display-value">{{opportunity.pre_bid_time}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Pre-Bid Attendees</div>
              <div class="display-value">{{opportunity.pre_bid_attendees ? attendeesString(opportunity.pre_bid_attendees) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Job Number</div>
              <div class="display-value">{{opportunity.job_number}}</div>
            </v-col>

            <v-col cols="12">
              <div class="form-banner">Estimating</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4" class="inputs-container" v-for="estimating_object in opportunity.estimating" v-if="estimating_object.required">
              <div class="display-label">{{estimating_object.title + ' Estimator'}}</div>
              <div class="display-value" v-if="estimating_object.estimator">{{db.users[estimating_object.estimator].first_name + ' ' + db.users[estimating_object.estimator].last_name}}</div>
              <div class="display-value" v-if="!estimating_object.estimator" style="color:red;font-weight:bold">Unassigned</div>
            </v-col>

          </v-row>
          <v-row class="inputs-container">
            <v-col cols="12">
              <div class="form-banner">Competitor Information</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Loss Reason</div>
              <div class="display-value">{{opportunity.loss_reason}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Lost To Competitor</div>
              <div class="display-value">{{opportunity.lost_to_competitor}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Competitor Price</div>
              <div class="display-value">{{opportunity.competitor_price}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Competitors Involved</div>
              <div class="display-value">{{opportunity.competitors_involved}}</div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-btn color="primary" small @click="printRFQ()" style="margin:5px">Print</v-btn>
        <v-btn color="primary" small @click="cloneOpportunity()" style="margin:5px" v-if="!opportunity.salesforce_opportunity">Clone</v-btn>
        <v-btn color="primary" small v-if="is_admin" @click="deleteOpportunity()" style="margin:5px">Delete</v-btn>
        <v-btn color="primary" small @click="edit_form = true" style="margin:5px" v-if="!is_sales || opportunity.owner === current_user">Edit</v-btn>
      </v-card>

      <v-card style="margin-bottom:20px" v-if="!is_sales || opportunity.owner === current_user">
        <v-card-text>
          <div style="font-weight:bold">Files</div>

          <v-simple-table dense style="max-width:900px;">
            <template v-slot:default>
              <tbody>
                <tr v-for="file in files">
                  <td style=";line-height:120%;padding-top:4px;padding-bottom:4px;"><span class="table-link" @click="openFile(file.path)">{{ file.name }}</span></td>
                  <td style="font-size:10px !important">Uploaded By: {{db.users[file.metadata.uploadedBy].first_name + ' ' + db.users[file.metadata.uploadedBy].last_name}}</br>Uploaded: {{formatFileDate(file.metadata.uploadDate)}}</td>
                  <td><v-btn color="primary" x-small @click="deleteFile(file.path)" style="margin:5px">DELETE</v-btn></td>
                </tr>
                <tr></tr>
                <tr v-if="files.length"><v-btn  color="primary" x-small @click="downloadAllFiles()" style="margin:5px">DOWNLOAD ALL FILES</v-btn></tr>
                <tr v-if="!files.length"><div class="form-banner">No files available for this opportunity</div></tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="dropzone bg-gray" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" style="opacity: 0;display:none"/>
            <div v-if="!currentFile" style="position:relative;top:25%;text-align:center;">
              Drag and Drop Files Here
            </div>
          </div>

          <div style="margin-top:20px" v-if="fileslist.length">
            <hr>
            <div style="color:red;font-style:italic">Be sure to click "UPLOAD FILES" button below to save</div>
            <table>
              <tr v-for="(file,n) in fileslist">
                <td>{{file.name}}</td>
                <td><v-btn color="primary" x-small @click="removeFile(n)" style="margin:5px">REMOVE</v-btn></td>
              </tr>
              <tr><v-btn color="primary" x-small @click="uploadFiles">UPLOAD FILES</v-btn></tr>
            </table>
          </div>
        </v-card-text>
      </v-card>

      <v-card style="margin-bottom:20px" v-if="!is_sales && !opportunity.salesforce_opportunity">
        <v-card-text>
          <div class="display-label" v-if="unassignedEstimators"><span style="color: red;font-style:italic">This opportunity has unassigned estimators</span></div>
          <div style="font-weight:bold">Quotes</div>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-left">Master</th>
                  <th class="text-left">Completed</th>
                  <th class="text-left">Reviewed</th>
                  <th class="text-left">Quote Title</th>
                  <th class="text-left">Quote Sheet</th>
                  <th class="text-left">Department</th>
                  <th class="text-left">Created</th>
                  <th class="text-left">Modified</th>
                  <th class="text-left">Cost Est.</th>
                  <th class="text-left">Revenue Est.</th>
                  <th class="text-left">Margin</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
                <draggable :list="quotesArray" @end="finishDragOrder" handle=".handle" tag="tbody">
                  <tr v-for="(item,index) in quotesArray" :key="index">
                    <td><v-icon medium class="handle">mdi-reorder-horizontal</v-icon></td>
                    <td>
                      <v-checkbox dense hide-detail v-model="item.show_in_master" @change="setMasterView(item.key,item.show_in_master)" style="margin:0px;width:30px;height:30px;position:relative;top:0px"></v-checkbox>
                    </td>
                    <td><v-icon v-if="item.completed" style="postion:relative;left:15px;">mdi-check</v-icon></td>
                    <td><v-icon v-if="item.reviewed" style="postion:relative;left:15px;">mdi-check</v-icon></td>
                    <td style="line-height:120%;padding-top:4px;padding-bottom:4px;max-width:150px" v-if="item.key !== $store.state.last_viewed_quote"><span class="table-link" @click="viewQuote(item.key)">{{ item.quote_title }}</span></td>
                    <td style="line-height:120%;padding-top:4px;padding-bottom:4px;max-width:150px;background:rgba(144, 238, 144, 0.5)" v-if="item.key == $store.state.last_viewed_quote"><span class="table-link" @click="viewQuote(item.key)">{{ item.quote_title }}</span></td>
                    <td v-if="!item.is_msa_quote_sheet">{{ item.quote_sheet !== 'Excel' ? item.quote_sheet : item.department_quote_sheet + ' (Excel)'}}</td>
                    <td v-if="item.is_msa_quote_sheet">{{item.quote_sheet}} (MSA)</td>
                    <td>{{ item.department }}</td>
                    <td>{{ db.users[item.created_by].first_name + ' ' + db.users[item.created_by].last_name }} </br> {{ formatDate(item.created) }} </td>
                    <td>{{ item.modified_by ? (db.users[item.modified_by].first_name + ' ' + db.users[item.modified_by].last_name) : '' }} </br> {{ item.modified ? formatDate(item.modified) : ''}} </td>
                    <td>{{ formatCurrency(item.elmos.total_cost) }}</td>
                    <td>{{ formatCurrency(item.elmos.total_revenue) }}</td>
                    <td>{{ calculateMargin(item.elmos.total_cost,item.elmos.total_revenue) }}</td>
                    <td><v-btn color="primary" x-small @click="cloneQuoteSheet(item.key)">CLONE</v-btn></td>
                    <td>
                      <v-tooltip right color="#223773">
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-icon v-bind="attrs" v-on="on">
                            <v-icon style="cursor:pointer" @click="deleteQuote(item.key)">mdi-trash-can</v-icon>
                          </v-list-item-icon>
                        </template>
                        <span>Delete Quote</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </draggable>
                <tr style="border-top:1px solid">
                  <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                  <td style="font-weight:bold">{{ formatCurrency(opportunity_total_cost) }}</td>
                  <td style="font-weight:bold;">{{ formatCurrency(opportunity_total_revenue) }}</td>
                  <td style="font-weight:bold;">{{ calculateMargin(opportunity_total_cost,opportunity_total_revenue) }}</td>
                  <td></td>
                </tr>

            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
            <v-btn color="primary" x-small @click="selectAllQuotes(true)" v-if="anyUnselectedQuotesInMaster">Show All In Master</v-btn>
            <v-btn color="primary" x-small @click="selectAllQuotes(false)" v-if="anySelectedQuotesInMaster">Hide All In Master</v-btn>
        </v-card-actions>

        <v-card-actions>
            <v-btn color="primary" small @click="addQuote()">Add Quote</v-btn>
        </v-card-actions>
      </v-card>

      <v-card style="margin-bottom:20px" v-if="!is_sales && !opportunity.salesforce_opportunity">
          <table style="padding:10px">
            <tr>
              <td :style=" opportunity_revenue_override_percentage !== 0 ? 'color:red' : 'color:gray' ">Revenue Override:</td>
              <td style="color:blue;text-decoration:underline;cursor:pointer;width:40px;text-align:center" @click="openRevenueOverridePopup()">{{opportunity_revenue_override_percentage}}%</td>
            </tr>
          </table>
          <MasterSummary :opportunityid='id' ref="mastersummary" v-if="!is_sales && !opportunity.salesforce_opportunity"/>
      </v-card>

      <v-card style="margin-bottom:20px">

        <v-card-text>
          <div style="font-weight:bold">Notes</div>

          <div v-for="n in opp_notes" style="border-bottom:1px solid gray;margin: 10px 0px;position:relative">
            <div style="line-height:120%;white-space: pre-wrap;">{{n.note}}</div>
            <div style="font-weight:bold;font-size:10px;font-style:italic">{{ db.users[n.added_by].first_name + ' ' + db.users[n.added_by].last_name}} {{n.formatted_sort_date}}</div>
            

            <div style="position:absolute; right:0px; bottom:0px;">
              <v-btn v-if="n.added_by == current_user || is_admin" icon small  @click="deleteNote(n.id)">
                <v-icon small color="#223773">mdi-delete</v-icon>
              </v-btn>

              <v-btn v-if="n.added_by == current_user" icon small @click="editNote(n.id)">
                <v-icon small color="#223773">mdi-pencil</v-icon>
              </v-btn>
            </div>
             
          </div>
        </v-card-text>

        <v-card-actions>
            <v-btn color="primary" small @click="note_popup = true">Add Note</v-btn>
        </v-card-actions>
                  
      </v-card>

    </v-container>

    <EditRevenueOverride v-if="edit_revenue_override" :opportunityid="id" @close="closeRevenueOverridePopup()" @saveRevenueOverride="saveRevenueOverride"/>

    <OpportunityForm v-if="edit_form" :id="id" @close="edit_form = false;loadOpportunity()"/>
    <QuoteSheetSelection v-if="quote_sheet_selection" @close="quote_sheet_selection = false" @quote="openQuoteSheet($event)" />
    <FieldQuoteForm v-if="quote_form === 'Field'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />

    <CivilQuoteForm v-if="quote_form === 'Civil'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <ElectricalQuoteForm v-if="quote_form === 'Electrical'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />

    <ElmcoMachineQuoteForm v-if="quote_form === 'ELMCO Machine'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <ElmcoFabricationQuoteForm v-if="quote_form === 'ELMCO Fabrication'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <ElmcoFieldQuoteForm v-if="quote_form === 'ELMCO Field'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />

    <MachineQuoteForm v-if="quote_form === 'Machine'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <EngineeringQuoteForm v-if="quote_form === 'Engineering'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <TransportationQuoteForm v-if="quote_form === 'Transportation'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <MechanicalQuoteForm v-if="quote_form === 'Mechanical'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <PumpQuoteForm v-if="quote_form === 'Pump'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <SpecialtyQuoteForm v-if="quote_form === 'Specialty'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />
    <ExcelQuoteForm v-if="quote_form === 'Excel'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable();getOpportunityFiles()" />

    <MSAQuoteForm v-if="quote_form === 'MSA'" :opportunityid='id' :quoteid='quote_form_id' @close="quote_form = false;quote_form_id = false;loadOpportunity();$refs.mastersummary.buildTable()" />

    <AddNote v-if="note_popup" :opportunityid="id" :editid="edit_note" @close="closeNotePopup()"/>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/storage'
import Confirm from '../components/Confirm.vue'
import MasterSummary from '../components/MasterSummary.vue'
import { mapGetters, mapActions } from 'vuex'
import OpportunityForm from '../components/forms/OpportunityForm.vue'
import FieldQuoteForm from '../components/quote_sheets/Field.vue'
import MSAQuoteForm from '../components/quote_sheets/MSA.vue'

import CivilQuoteForm from '../components/quote_sheets/Civil.vue'
import ElectricalQuoteForm from '../components/quote_sheets/Electrical.vue'

import ElmcoMachineQuoteForm from '../components/quote_sheets/ElmcoMachine.vue'
import ElmcoFabricationQuoteForm from '../components/quote_sheets/ElmcoFabrication.vue'
import ElmcoFieldQuoteForm from '../components/quote_sheets/ElmcoField.vue'
import MachineQuoteForm from '../components/quote_sheets/Machine.vue'
import EngineeringQuoteForm from '../components/quote_sheets/Engineering.vue'
import TransportationQuoteForm from '../components/quote_sheets/Transportation.vue'
import MechanicalQuoteForm from '../components/quote_sheets/Mechanical.vue'
import PumpQuoteForm from '../components/quote_sheets/Pump.vue'
import SpecialtyQuoteForm from '../components/quote_sheets/Specialty.vue'
import QuoteSheetSelection from '../components/QuoteSheetSelection.vue'
import ExcelQuoteForm from '../components/quote_sheets/Excel.vue'

import AddNote from '../components/AddNote.vue'

import { formatCurrency } from '../mixins/formatCurrency.js'
import Moment from 'moment'
import jsPDF from "jspdf";
import html2PDF from 'jspdf-html2canvas';
import EditRevenueOverride from '../components/EditRevenueOverride.vue'
import draggable from 'vuedraggable'
import _ from 'lodash';

export default {
  name: 'Opportunity',
  components: {AddNote,OpportunityForm,MasterSummary,QuoteSheetSelection,FieldQuoteForm,CivilQuoteForm,ElectricalQuoteForm,ElmcoMachineQuoteForm,ElmcoFieldQuoteForm,ElmcoFabricationQuoteForm,MachineQuoteForm,EngineeringQuoteForm,TransportationQuoteForm,MechanicalQuoteForm,PumpQuoteForm,SpecialtyQuoteForm,ExcelQuoteForm,MSAQuoteForm,EditRevenueOverride,draggable},
  mixins: [formatCurrency],
  data(){
    return{
      loaded: false,
      opportunity: {},
      quotes: {},
      edit_form: false,
      quote_form: false,
      quote_form_id: false,
      quote_sheet_selection: false,
      id: this.$route.params['opportunityId'],
      estimate_form: false,
      currentFile: null,
      files: [],
      opportunity_total_revenue: 0,
      opportunity_total_cost: 0,
      opportunity_revenue_override_percentage: 0,
      opportunity_total_revenue_with_override: 0,
      edit_revenue_override: false,
      fileslist: [],
      quotesArray: [],
      note_popup: false,
      edit_note: null
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.quote_form) {
      var confirm_leave = confirm('Are you sure you want to leave? You may have unsaved changes.')
      if(confirm_leave){
        next()
      }
    } else {
      next()
    }
  },
  beforeMount(){
    this.loadOpportunity()
    this.getOpportunityFiles()
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      isLoading: 'isLoading',
      is_admin: 'is_admin',
      is_sales: 'is_sales',
      is_estimating: 'is_estimating'
    }),
    opp_notes(){
      var notes = []
      if(this.db.notes && this.db.notes[this.id]){
        for(var n in this.db.notes[this.id]){
          var note = this.db.notes[this.id][n]
          if(!note.deleted){
            note.id = n
            note.sort_date = note.modified_date ? note.modified_date : note.created_date
            note.formatted_sort_date = Moment(note.sort_date).format('MM/DD/YY h:mm a')
            notes.push(note)          
          }
        }
        var sorted_notes = _.orderBy(notes, ['sort_date'], ['desc']);
        return sorted_notes        
        }else{
          return []
        }
    },
    unassignedEstimators(){
      var unassigned = false
      for(var dept in this.opportunity.estimating){
        var est_obj = this.opportunity.estimating[dept]
        if(est_obj.required && !est_obj.estimator){
          unassigned = true
        }
      }
      return unassigned
    },
    anyUnselectedQuotesInMaster(){
      var quotes = this.quotes
      for(var q in quotes){
        if(!quotes[q].show_in_master){
          return true
        }
      }
      return false
    },
    anySelectedQuotesInMaster(){
      var quotes = this.quotes
      for(var q in quotes){
        if(quotes[q].show_in_master){
          return true
        }
      }
      return false
    }
  },
  methods:{
    finishDragOrder(){
      var self = this
      self.quotesArray.forEach((item, index) => {
        self.quotesArray[index].orderIndex = index
        Firebase.database().ref('quotes').child(self.id).child(self.quotesArray[index].key).child('orderIndex').set(index)
      });
    },
    onChange() {
      var newFiles = [...this.$refs.file.files];
      newFiles = _.sortBy(newFiles, (obj) => parseInt(obj.name, 10));
      this.fileslist = this.fileslist || [];
      this.fileslist = [...this.fileslist, ...newFiles];      
    },
    removeFile(i) {
      this.fileslist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-active')) {
        event.currentTarget.classList.remove('bg-gray');
        event.currentTarget.classList.add('bg-active');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray');
      event.currentTarget.classList.remove('bg-active');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-gray');
      event.currentTarget.classList.remove('bg-active');
      this.onChange();
    },
    openFile(path){
      Firebase.storage().ref().child(path).getDownloadURL()
        .then((url) => {
          window.open(url)
        })
        .catch((error) => {
          console.log(error)
        });
    },
    downloadFile(file){
    Firebase.storage().ref().child(file.path).getDownloadURL()
        .then((url) => {
            // Instead of directly using the URL, fetch the content and create a blob
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    // Use createObjectURL to generate a temporary URL for the blob
                    const objectUrl = URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = objectUrl;
                    a.download = file.name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    
                    // Release the created ObjectURL to save memory
                    URL.revokeObjectURL(objectUrl);
                })
                .catch((error) => {
                    console.log("Error fetching the blob:", error);
                });
        })
        .catch((error) => {
            console.log(error);
        });
    },
    downloadAllFiles(){
      var self = this
      var files = this.files
      for (var i = 0; i < files.length; i++) {
        ((index) => {
          setTimeout(() => {
            self.downloadFile(files[index])                      
          }, index * 1000);
        })(i);
      }
    },
    attendeesString(attendees){
      var names = []
      for(var i = 0; i < attendees.length; i++){
        names.push(this.db.users[attendees[i]].first_name + ' ' + this.db.users[attendees[i]].last_name)
      }
      return names.join(', ')
    },
    openRevenueOverridePopup(){
      this.edit_revenue_override = true
    },
    closeRevenueOverridePopup(){
      this.edit_revenue_override = false
    },
    closeNotePopup(){
      this.note_popup = false
      this.edit_note = null
    },
    saveRevenueOverride(percentage){
      var self = this
  
      Firebase.database().ref('opportunities').child(self.id).child('edited_by').set(self.current_user)
      Firebase.database().ref('opportunities').child(self.id).child('edit_date').set(Date.now())
      Firebase.database().ref('opportunities').child(self.id).child('revenue_override_percentage').set(percentage ? percentage : 0).then(function(){
        self.loadOpportunity()
        self.edit_revenue_override = false
      })
    },
    cloneQuoteSheet(key){
      var self = this
      var quote_clone = JSON.parse(JSON.stringify(self.quotes[key]))
      quote_clone.completed = false
      quote_clone.reviewed = false
      quote_clone.created = Date.now()
      quote_clone.created_by = self.current_user
      quote_clone.quote_title = '(CLONE) ' + quote_clone.quote_title

      Firebase.database().ref('opportunities').child(self.id).child('quote_last_modified').set(Date.now())

      Firebase.database().ref('totals').child(self.id).child(key).once('value',function(totals_snapshot){

        var total_clone = totals_snapshot.val()

        Firebase.database().ref('quotes').child(self.id).push(quote_clone).then(function(snapshot){
          Firebase.database().ref('totals').child(self.id).child(snapshot.key).set({revenue: total_clone.revenue, cost: total_clone.cost, show_in_master: total_clone.show_in_master, completed: false, reviewed: false, quote_sheet: total_clone.quote_sheet}).then(function(){
            self.loadOpportunity()
          })
        })

      })

    },
    loadOpportunity(){
      var self = this
      self.opportunity = {}
      self.opportunity_total_revenue = 0
      self.opportunity_total_cost = 0
      self.loaded = false
      
      Firebase.database().ref('opportunities').child(self.id).once('value',function(opp_snapshot){
        self.opportunity = JSON.parse(JSON.stringify(opp_snapshot.val()))
        self.opportunity_revenue_override_percentage = self.opportunity.revenue_override_percentage ? (self.opportunity.revenue_override_percentage) : 0
      }).then(function(){
        if(!self.opportunity.salesforce_opportunity){
          Firebase.database().ref('quotes').child(self.id).once('value',function(quotes_snapshot){
            var quotes = JSON.parse(JSON.stringify(quotes_snapshot.val()))
            self.quotes = quotes
            self.loaded = true
            for(var qid in quotes){
              if(quotes[qid].show_in_master){
                self.opportunity_total_revenue += quotes[qid].elmos.total_revenue;
                self.opportunity_total_cost += quotes[qid].elmos.total_cost
              }
            }
            self.buildArrayForTable()
            self.opportunity_total_revenue_with_override = self.opportunity_total_revenue + (self.opportunity_total_revenue * (self.opportunity_revenue_override_percentage / 100))

          })
        }else{
          self.opportunity_total_revenue_with_override = self.opportunity.salesforce_revenue
          self.opportunity_total_revenue = self.opportunity.salesforce_revenue
          self.opportunity_total_cost = self.opportunity.salesforce_cost
          self.loaded = true
        }
      })
    },
    buildArrayForTable(){
      var quotes = JSON.parse(JSON.stringify(this.quotes));
      var arr = [];
      var maxOrderIndex = 0; // Keep track of the highest orderIndex

      for (var q in quotes) {
          quotes[q].key = q;
          if (typeof quotes[q].orderIndex === 'undefined' || quotes[q].orderIndex === null) {
              quotes[q].orderIndex = -1; // temporary placeholder for missing orderIndex
          } else {
              maxOrderIndex = Math.max(maxOrderIndex, quotes[q].orderIndex);
          }
          arr.push(quotes[q]);
      }
      // Assign missing orderIndex values
      for (let i = 0; i < arr.length; i++) {
          if (arr[i].orderIndex === -1) {
              maxOrderIndex++;
              arr[i].orderIndex = maxOrderIndex;
          }
      }
      arr.sort((a, b) => a.orderIndex - b.orderIndex);
      this.quotesArray = arr;
    },
    printRFQ(){
      var element = document.getElementById('rfq');
      var name = this.opportunity.name
      var opt = {
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        output: name + ' RFQ.pdf'
      };
      html2PDF(element, opt);
    },
    selectAllQuotes(bool){
      var self = this;
      var transactions_needed = 0;
      var transactions_done = 0;

      Firebase.database().ref('opportunities').child(self.id).child('quote_last_modified').set(Date.now());
      Firebase.database().ref('quotes').child(self.id).once('value', function (quotes_snapshot) {
        var quotes = quotes_snapshot.val();
        transactions_needed = Object.keys(quotes).length;

        // Use forEach to loop through the quotes
        Object.keys(quotes).forEach(function (quote_id) {
          Firebase.database().ref('quotes').child(self.id).child(quote_id).child('show_in_master').set(bool).then(function () {
            Firebase.database().ref('totals').child(self.id).child(quote_id).child('show_in_master').set(bool).then(function () {
              transactions_done++;
              if (transactions_done === transactions_needed) {
                self.loadOpportunity();
              }
            });
          });
        });
      });      
    },
    setMasterView(quote_id,val){
      var self = this

      Firebase.database().ref('totals').child(self.id).child(quote_id).once('value',function(total_snapshot){
        var is_current = total_snapshot.val() !== null
        if(is_current){
          Firebase.database().ref('opportunities').child(self.id).child('quote_last_modified').set(Date.now())
          Firebase.database().ref().child('quotes').child(self.id).child(quote_id).child('show_in_master').set(val).then(function(){
            self.$refs.mastersummary.buildTable()
            Firebase.database().ref('totals').child(self.id).child(quote_id).child('show_in_master').set(val).then(function(){
              self.loadOpportunity()
            })
          })
        }else{
          self.$refs.mastersummary.buildTable()
          self.loadOpportunity()
        }
      })
    },
    calculateMargin(cost,revenue){
      return revenue > 0 ? ((revenue - cost) / revenue * 100 ).toFixed(1) + '%' : ''
    },
    getOpportunityFiles(){
      var self = this
      var arr = []
      Firebase.storage().ref().child(self.id).listAll().then(function(res){
        res.items.forEach((itemRef) => {
          itemRef.getMetadata().then(function(metadata){
            var file_obj = {
              name: itemRef.name,
              path: itemRef.fullPath,
              metadata: metadata.customMetadata
            }
            arr.push(file_obj)
          })
        });
        self.files = arr
      })
    },
    deleteQuote(quote_id){
      var self = this
      var confirm_delete = confirm('Are you sure you want to delete quote ' + self.quotes[quote_id].quote_title + '?')
      if(confirm_delete){
        Firebase.database().ref('opportunities').child(self.id).child('quote_last_modified').set(Date.now())
        Firebase.database().ref().child('quotes').child(self.id).child(quote_id).remove().then(function(){
          Firebase.database().ref().child('quotes_archive').child(self.id).child(quote_id).remove().then(function(){
            Firebase.database().ref('totals').child(self.id).child(quote_id).remove().then(function(){
              self.$refs.mastersummary.buildTable()
              self.loadOpportunity()
            })
          })
        })
      }
    },
    deleteFile(file){
      var self = this
      var confirm_delete = confirm('Are you sure you want to delete this file?')
      if(confirm_delete){
        self.$store.state.isLoading = true
        Firebase.storage().ref().child(file).delete().then(() => {
          self.getOpportunityFiles()
          self.$store.state.isLoading = false
        }).catch((error) => {
          alert(error)
          self.$store.state.isLoading = false
        });
      }
    },
    cloneOpportunity(){
      var self = this
      var totals_for_clone;
      var cloned_opportunity = this.opportunity
      cloned_opportunity.added_by = self.current_user
      cloned_opportunity.close_date = ''
      cloned_opportunity.created_date = Date.now()
      cloned_opportunity.due_date = ''
      cloned_opportunity.due_time = ''
      cloned_opportunity.edit_date = null
      cloned_opportunity.job_number = ''
      cloned_opportunity.quote_number = ''
      cloned_opportunity.name = '(clone) ' + cloned_opportunity.name
      cloned_opportunity.pre_bid_date = ''
      cloned_opportunity.pre_bid_time = ''
      cloned_opportunity.project_start_date = ''
      cloned_opportunity.project_end_date = ''
      cloned_opportunity.stage_history = null
      cloned_opportunity.stage = "Opportunity Identification"
      cloned_opportunity.is_clone = true

      Firebase.database().ref('totals').child(self.id).once('value',function(totals_snapshot){
        totals_for_clone = totals_snapshot.val()
      }).then(function(){
        Firebase.database().ref('opportunities').push(cloned_opportunity).then(function(opp_snapshot){
          Firebase.database().ref('opportunities').child(opp_snapshot.key).child('id').set(opp_snapshot.key).then(function(){
            Firebase.database().ref('quotes').child(opp_snapshot.key).set(self.quotes).then(function(){
              Firebase.database().ref('totals').child(opp_snapshot.key).set(totals_for_clone).then(function(){
                self.edit_form = opp_snapshot.key
                self.quote_form = false
                self.quote_form_id = false
                self.quote_sheet_selection = false
                self.id = opp_snapshot.key
                self.estimate_form = false
                self.currentFile = null
                self.files = []
                self.getOpportunityFiles()
                self.$router.push({name: 'Opportunity', params: {opportunityId: opp_snapshot.key}})
                document.getElementById('content').scrollTo({ top: 0, behavior: 'smooth' });
              })
            })
          })
        })
      })
    },
    deleteOpportunity(){
      var confirm_delete = confirm('WARNING! Deleting this opportunity will delete all quotes associated with it. Are you sure you wanted to delete opportunity ' + this.opportunity.name + '?')
      var self = this
      if(!confirm_delete){return}

      Firebase.database().ref('deleted_opportunities').child(self.id).set({'deleted_by':self.current_user})

      Firebase.database().ref('opportunities').child(self.id).remove().then(function(){
        Firebase.database().ref('quotes').child(self.id).remove().then(function(){
          Firebase.database().ref('quotes_archive').child(self.id).remove().then(function(){
            Firebase.database().ref('totals').child(self.id).remove().then(function(){
              self.$router.push('/opportunities')
            })
          })
        })
      })
    },
    deleteNote(nid){
      var self = this
      var confirm_delete = confirm('Are you sure you want to delete this note?')
      if(confirm_delete){
        Firebase.database().ref('notes').child(self.id).child(nid).update({deleted:true,deleted_by:self.current_user})
      }
    },
    editNote(nid){
      this.note_popup = true
      this.edit_note = nid
    },
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    openQuoteSheet(form){
      this.quote_sheet_selection = false
      this.quote_form = form
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatFileDate(date){
      return Moment(parseInt(date)).format('MM/DD/YY')
    },
    addQuote(){
      this.quote_sheet_selection = true
    },
    viewQuote(quote_id){
      this.quote_form = !this.quotes[quote_id].is_msa_quote_sheet ? this.quotes[quote_id].quote_sheet : 'MSA'
      this.quote_form_id = quote_id
      this.$store.state.last_viewed_quote = quote_id
    },
    uploadFiles(){
      var self = this;
      async function uploadSingleFile(file) {
          var extension = self.id + '/' + file.name;
          var metadata = {
              customMetadata: {
                  uploadedBy: self.current_user,
                  uploadDate: Date.now()
              }
          };
          await Firebase.storage().ref().child(extension).put(file, metadata);
      }

      self.$store.state.isLoading = true;

      (async () => {
          for (let file of self.fileslist) {
              try {
                  await uploadSingleFile(file);
              } catch (err) {
                  alert(err);
              }
          }
          self.fileslist = [];  // Clear the list after all files are uploaded
          self.getOpportunityFiles();
          self.$store.state.isLoading = false;
      })();      
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-list-item__icon{height: 0px !important}

  .dropzone{
    position: relative;
    display: table-cell;
    top: 10px;
    width: 450px;
    height: 50px;
    border-radius: 4px;
  }

  .bg-gray{
    background: white;
    color: #223773;
    border: 2px dashed #223773;
  }

  .bg-active{
    background: #223773;
    border: 2px dashed white;
    color: white;
  }
  .handle{
    cursor:pointer
  } 
  .sortable-ghost{
    background: gray;
    color: white;
  }
</style>


