<template>
  <v-dialog scrollable persistent v-model="dialog" width="500px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header">Edit Report</div>
        <v-card-text>
            <v-card>
              <v-card-text style="text-align:left">
                <v-text-field dense @focus="$event.target.select()" v-model="report_object.name" hide-details="auto" label="Report Name" outlined v-on:keydown.enter.prevent=''></v-text-field>
              </v-card-text>

              <v-card-text style="text-align:left">
                <v-autocomplete sort="name" v-model="report_object.owner" hide-details="true" :items="active_users" item-text="name" item-value="id" label="Report Owner" dense outlined></v-autocomplete>
              </v-card-text>

              <v-radio-group v-model="report_object.access" style="width:100px;margin-left:20px">
                <v-radio label="Public" value="Public"></v-radio>
                <v-radio label="Private" value="Private"></v-radio>
              </v-radio-group>

            </v-card>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="$emit('save',report_object,id)" small :disabled="!unsavedReportChanges">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditReport',
  components: {},
  mixins: [],
  props: {
    id: String
  },
  data(){
    return{
      dialog: true,
      original_report_object: {
        name: '',
        access: '',
        owner: ''
      },
      report_object: {
        name: '',
        access: '',
        owner: ''
      }
    }
  },
  beforeMount(){
    var self = this
    Firebase.database().ref('reports').child(self.id).once('value',function(snapshot){
      var report = snapshot.val()
      self.report_object.name = report.name
      self.report_object.access = report.access
      self.report_object.owner = report.owner
      self.original_report_object = JSON.parse( JSON.stringify( self.report_object ) )
    })
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_users: 'active_users'
    }),
    unsavedReportChanges(){
      return JSON.stringify(this.original_report_object) !== JSON.stringify(this.report_object)
    },
  }
}
</script>

<style lang="scss">

</style>


