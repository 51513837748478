<template>
  <div class="home">
      <v-container fluid>
        <v-row dense>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card style="">
              <v-card-text>
                <v-row dense>
                  <v-col cols="6" sm="6" md="6" lg="6">
                    <div style="font-size:18px">Total Open Opportunities <span style="font-weight:bold;color:green;font-size:22px;">{{formatCurrency(total_open_opportunities)}}</span></div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" lg="6" style="position:relative;text-align:right" v-if="last_updated !== ''">
                    <div><span style="font-size:10px;font-color:lightgray;padding-right:10px">Dashboard last updated: {{last_updated}}</span><v-btn color="primary" x-small style="" @click="getOpenOpportunities()">REFRESH</v-btn></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <v-card style="">
              <v-card-text>
                <OpportunitiesDueTodayByOwnerChart :opportunityData='opportuntites_due_today_by_owner_chart_data' :openCount='open_count' />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <v-card style="">
              <v-card-text>
                <OpenOpportunitiesByOwnerTable :data='open_opportunities_by_owner_table_data' :total='total_open_opportunities' />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <v-card style="">
              <v-card-text>
                <MyAssignedEstimatesTable :data='my_assigned_estimates'/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <v-card style="">
              <v-card-text>
                <CloseDatePastDueTable :data='past_due_opportunities'/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <v-card style="">
              <v-card-text>
                <InAssigningQuoteNumberStageTable :data='assigning_quote_number_opportunities'/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <v-card>
              <v-card-text>
                <LoggedCallsTable/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import OpenOpportunitiesByOwnerTable from '../components/charts/OpenOpportunitiesByOwnerTable.vue'
import CloseDatePastDueTable from '../components/charts/CloseDatePastDueTable.vue'
import InAssigningQuoteNumberStageTable from '../components/charts/InAssigningQuoteNumberStageTable.vue'
import MyAssignedEstimatesTable from '../components/charts/MyAssignedEstimatesTable.vue'
import LoggedCallsTable from '../components/charts/LoggedCallsTable.vue'
import OpportunitiesDueTodayByOwnerChart from '../components/charts/OpportunitiesDueTodayByOwnerChart.vue'
import moment from 'moment'
import { formatCurrency } from './../mixins/formatCurrency.js'

export default {
  name: 'home',
  components: {OpportunitiesDueTodayByOwnerChart,OpenOpportunitiesByOwnerTable,CloseDatePastDueTable,InAssigningQuoteNumberStageTable,MyAssignedEstimatesTable,LoggedCallsTable},
  data(){
    return{
      last_updated: '',
      total_open_opportunities: 0,
      open_opportunities_by_owner_table_data: [],
      past_due_opportunities: [],
      assigning_quote_number_opportunities: [],
      my_assigned_estimates: [],
      opportuntites_due_today_by_owner_chart_data: {},
      open_count: 0
    }
  },
  mixins: [formatCurrency],
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  mounted(){
    this.getOpenOpportunities()
  },
  methods:{
    getOpenOpportunities(){
      var self = this
      var owner_data = {}
      var current_date = Date.now()
      var opportunity_to_revenue_mapping = {}
      self.$store.state.isLoading = true
      self.total_open_opportunities = 0
      self.past_due_opportunities = []
      self.assigning_quote_number_opportunities = []
      self.my_assigned_estimates = []

      Firebase.database().ref('totals').once('value',function(totals_snapshot){
        var totals_snapshot_val = totals_snapshot.val()
        for(var opp_id in totals_snapshot_val){
          opportunity_to_revenue_mapping[opp_id] = 0
          var totals = totals_snapshot_val[opp_id]
          for(var tid in totals){
            var total = totals[tid]
            if(total.show_in_master){
              opportunity_to_revenue_mapping[opp_id] += total.revenue
            }
          }
        }
      }).then(function(){
        Firebase.database().ref('opportunities').orderByChild('status').equalTo('open').once('value',function(snapshot){
          var opps = snapshot.val()
          var opportuntites_due_today_by_owner_chart_data = {}
          var open_count = 0

          for(var o in opps){
            var opportunity = opps[o]
            var stage = opps[o].stage
            var owner_name = self.db.users[opportunity.owner].first_name + ' ' + self.db.users[opportunity.owner].last_name
            var opp_total = 0
            var revenue_override_percentage = opportunity.revenue_override_percentage ? (opportunity.revenue_override_percentage / 100 ) : 0

            if (opportunity.salesforce_opportunity) {
              opp_total += opportunity.salesforce_revenue
            }else{
              opp_total += opportunity_to_revenue_mapping[o] ? opportunity_to_revenue_mapping[o] : 0
            }

            opp_total = opp_total + (opp_total * revenue_override_percentage)

            var is_past_due = moment(opportunity.close_date).isBefore(current_date)
            var is_within_last_90_days = moment(opportunity.close_date).isAfter(moment().subtract(91, 'days')) && moment(opportunity.close_date).isBefore(moment());

            var today = moment().format('YYYY-MM-DD')

            if(today === opportunity.due_date && ['Estimating', 'Quote Revision', 'Review & Assembly', 'Assigning Quote #'].indexOf(stage) !== -1){
              if(!opportuntites_due_today_by_owner_chart_data[owner_name]){
                opportuntites_due_today_by_owner_chart_data[owner_name] = 1
              }else{
                opportuntites_due_today_by_owner_chart_data[owner_name]++
              }
              open_count++
            }

            if(stage !== 'Opportunity Identification'){
              if(!owner_data[opportunity.owner]){
                owner_data[opportunity.owner] = {}
                owner_data[opportunity.owner]['total'] = 0
                owner_data[opportunity.owner]['open_opportunities'] = []
              }

              owner_data[opportunity.owner].id = opportunity.owner
              owner_data[opportunity.owner].name = owner_name
              owner_data[opportunity.owner]['open_opportunities'].push({id: o, name: opportunity.name, account: self.db.accounts[opportunity.account].name, account_id:opportunity.account,  stage: stage, amount: opp_total})
              owner_data[opportunity.owner]['total'] += opp_total              
            }

            var data_object = {owner: owner_name,opportunity: opportunity.name,account: self.db.accounts[opportunity.account].name,stage: stage,close_date: opportunity.close_date,sortable_close_date: moment(opportunity.close_date),id: o}

            if(is_past_due && is_within_last_90_days && stage === 'Customer Evaluating'){
              self.past_due_opportunities.push(data_object)
            }

            if(stage === 'Assigning Quote #' && opportunity.quote_number === ''){
              self.assigning_quote_number_opportunities.push(data_object)
            }

            var estimating = opportunity.estimating
            for(var department in estimating){
              if(estimating[department].estimator === self.current_user && (opportunity.stage === 'Estimating' || opportunity.stage === 'Quote Revision')){
                self.my_assigned_estimates.push({opportunity: opportunity.name, account: self.db.accounts[opportunity.account].name, opportunity_id: o, department: estimating[department].title, stage: opportunity.stage, due_date: opportunity.due_date, quote_number: opportunity.quote_number})
              }
            }

            if(stage !== 'Opportunity Identification'){
              self.total_open_opportunities += opp_total
            }
            
            self.$store.state.isLoading = false
          }

          var owner_arr = []
          for(var owner in owner_data){
            owner_arr.push(owner_data[owner])
          }

          var owners_sorted_by_total = owner_arr.slice(0);
          owners_sorted_by_total.sort(function(a,b) {
              return b.total - a.total;
          });

          self.open_opportunities_by_owner_table_data = owners_sorted_by_total
          self.open_count = open_count

          self.opportuntites_due_today_by_owner_chart_data = Object.keys(opportuntites_due_today_by_owner_chart_data).sort().reduce(
            (obj, key) => {
              obj[key] = opportuntites_due_today_by_owner_chart_data[key];
              return obj;
            },
            {}
          );

          self.last_updated = moment().format("MM/DD/YY h:mm:ss A")

        }) //end of firebase opportunities call
      })
    }
  }
}
</script>

<style lang="scss">
  .home{
    background: #E8E8E8;
  }
</style>


