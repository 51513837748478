<template>
      <v-dialog scrollable persistent v-model="dialog" width="800">
        <v-form ref="form" lazy-validation>
          <v-card>
            <div class="form-header">Note</div>

              <v-container style="position:relative">
                <v-row class="inputs-container">

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea dense label="*Note" required outlined v-model="note" rows="5" auto-grow></v-textarea>
                  </v-col>

                </v-row>
              </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">Cancel</v-btn>
              <v-btn color="primary" @click="saveNote()" :disabled="disable">Save</v-btn>
            </v-card-actions>

          </v-card>
        </v-form>
      </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';

  export default {
    name: 'LogCallForm',
    props: {
      opportunityid: String,
      editid: String
    },
    data(){
      return{
        dialog: true,
        valid: false,
        note: '',
        original_note_for_edit: ''
      }
    },
    beforeMount(){
      if(this.editid){
        this.note = this.db.notes[this.opportunityid][this.editid].note
        this.original_note_for_edit = this.note
      }
    },
    watch: {
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
      }),
      disable(){
        if(!this.editid){
          return this.note.trim().length == 0  
        }else{
          return this.note.trim().length == 0  || this.original_note_for_edit == this.note
        }
        
      }
    },
    methods: {
      saveNote(){
        var self = this
        
        if(!this.editid){
          var note_object = {}
          note_object.note = self.note
          note_object.added_by = self.current_user
          note_object.created_date = Date.now()
          Firebase.database().ref('notes').child(self.opportunityid).push(note_object).then(function(snapshot){
            self.$emit('close')
          })          
        }else{
          var note_object = {}
          note_object.note = self.note
          note_object.modified_by = self.current_user
          note_object.modified_date = Date.now()
          Firebase.database().ref('notes').child(self.opportunityid).child(self.editid).update(note_object).then(function(snapshot){
            self.$emit('close')
          })
        }
      }
    }
  }
</script>
<style lang="scss">

</style>

