<template>
  <v-app>
    <div id="main" v-resize="setIsMobile">
      <div id="main-left" v-if="$route.path !== '/'">
        <SideMenu/>
      </div>
      <div id="main-right">
        <Banner/>
        <div id="content">
          <div style="width:60px;cursor:pointer;margin-left:10px;margin-top:5px" @click="$router.back()" v-if="$route.path !== '/home'"><v-icon small>mdi-arrow-left-bold</v-icon><span style="position:relative;top:1px;color:#223773;font-weight:bold;font-size:14px">BACK</span></div>
          <router-view/>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :background-color="'#000'" :opacity="0.5" :color="'#c92127'" :lock-scroll="true" :loader="'dots'" :blur="'0px'"></loading>
    <Notification v-if="$store.state.notification" @close="$store.state.notification = false"/>
  </v-app>
</template>

<script>
import Firebase from 'firebase/app'
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'
import Notification from './components/Notification.vue'
import { mapGetters, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'App',
  components: {Banner,SideMenu,Notification,Loading},
  data(){
    return{

    }
  },
  watch: {
    'current_user': function(val){
      var self = this
      if(val === null){
        Firebase.auth().signOut().then(function() { // force user logout if deactivated. see vueex store
          self.$router.push('/')
        })
      }
    },
  },
  mounted(){
    var self = this
    Firebase.auth().onAuthStateChanged(user => {
      if(user){
        self.retrieveDatabaseSnapshot(user.uid)
        if(self.$route.path !== '/home'){
          self.$router.push('/home')
        }
      }
    })
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      current_user: 'current_user'
    }),
  },
  methods:{
    ...mapActions(['retrieveDatabaseSnapshot','setIsMobile']),
  }
}
</script>

<style lang="scss">
  @import 'styles/global.scss';

  body,html{
    overflow: hidden;
  }

/*::-webkit-scrollbar {
      background: white;
  }*/

  #app{
    overflow: hidden;
    background: #E8E8E8;
  }

  #main{
    display: table;
    height: 100%;
  }

  #main-left{
    display: table-cell;
    width: 50px;
    height: 100%;
  }

  #main-right{
    display: table-cell;
    height: 100%;
    vertical-align: top;
  }

  #content{
    height: calc(100vh - 55px);
    overflow-y: auto;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
     font-size: 12px !important;
  }

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
   font-size: 12px !important;
}

.v-expansion-panel-header.thin-panel-header{
  min-height: 25px !important;
  padding: 0px 5px !important;;
}
</style>

