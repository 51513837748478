<template>
  <div>
    <div style="padding:0px 0px 0px 0px;font-size:18px;">Assign Quote Number</div>

      <v-data-table style="height: 410px;overflow-y:auto" dense :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="[{text: 'Owner', value: 'owner'},{text: 'Opportunity', value: 'opportunity'},{text: 'Stage', value: 'stage'}]" :items="data" :items-per-page="-1" hide-default-footer fixed-header>
        <template v-slot:item="row">
            <tr>
              <td style="font-size:10px !important">{{row.item.owner}}</td>
              <td style="font-size:10px !important"><span style="color:#223773;cursor: pointer;" @click="$router.push({name: 'Opportunity', params: {opportunityId: row.item.id}})">{{row.item.opportunity}}</span></br><span style="font-size:10px;font-style: italic">{{row.item.account}}</span></td>
              <td style="font-size:10px !important">{{row.item.stage}}</td>
            </tr>
        </template>
      </v-data-table>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: {
    data: Array,
  },
  components: {},
  created() {},
  data() {
    return {
      sortBy: 'close_date',
      sortDesc: false,
    };
  },
  computed: {

  },
  watch:{

  },
  methods: {

  }
};
</script>
