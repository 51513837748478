<template>
  <v-dialog scrollable persistent v-model="dialog" width="500px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header">Edit Global Rate</div>

        <v-card-text>
            <v-card style="">
              <v-card-text style="text-align:left">
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="" outlined min="0" v-model.number="new_rate" v-on:keydown.enter.prevent=''></v-text-field>
              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveRate()" small :disabled="initial_rate === new_rate || new_rate === ''">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency } from '../mixins/formatCurrency.js'

export default {
  name: 'EditGlobalRate',
  components: {},
  mixins: [formatCurrency],
  data(){
    return{
      dialog: true,
      initial_rate: 0,
      new_rate: 0
    }
  },
  props: {
    dbpath: String
  },
  beforeMount(){
    var self = this
    Firebase.database().ref(self.dbpath).once('value',function(snapshot){
      self.initial_rate = snapshot.val()
      self.new_rate = self.initial_rate
    })
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    saveRate(){
      var self = this
      self.$refs.form.validate()
      Firebase.database().ref(self.dbpath).set(self.new_rate).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style lang="scss">

</style>


