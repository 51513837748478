<template>
  <div class="manage_reports">
    <v-container fluid>
      <v-card>
        <v-card-title>MANAGE REPORTS</v-card-title>
        <v-data-table dense :mobile-breakpoint="0" no-data-text="Loading Data..." must-sort :headers="headers" :items="reports" :items-per-page="500" :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" :height="$vuetify.breakpoint.height - 270 + 'px'" fixed-header>
          <template v-slot:item="row">
            <tr>
              <td>{{row.item.name}}</td>
              <td>{{row.item.access}}</td>
              <td>{{row.item.owner}}</td>
              <td>{{row.item.created}}</td>
              <td>{{row.item.created_by}}</td>
              <td>{{row.item.modified}}</td>
              <td>{{row.item.modified_by}}</td>

              <td style="width:50px">
                <v-tooltip top color="#223773" style="height:20px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon v-bind="attrs" v-on="on">
                      <v-icon style="cursor:pointer" @click="editReport(row.item.id)">mdi-pencil</v-icon>
                    </v-list-item-icon>
                  </template>
                  <span>Edit Report</span>
                </v-tooltip>
              </td>
              <td style="width:50px">
                <v-tooltip top color="#223773" style="height:20px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon v-bind="attrs" v-on="on">
                      <v-icon style="cursor:pointer" @click="deleteReport(row.item.id)">mdi-trash-can</v-icon>
                    </v-list-item-icon>
                  </template>
                  <span>Delete Report</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <EditReport v-if="edit_report_modal" :id="edit_report_id" @close="edit_report_modal = false" @save="saveEditReport"/>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import EditReport from '../components/EditReport.vue'

export default {
  name: 'manage_reports',
  components: {EditReport},
  data(){
    return{
      reports: [],
      headers: [
        { text: 'Report Name', align: 'start', value: 'name'},
        { text: 'Access', value: 'access'},
        { text: 'Owner', value: 'owner'},
        { text: 'Created', value: 'created'},
        { text: 'Created By', value: 'created_by'},
        { text: 'Modified', value: 'modified'},
        { text: 'Modified By', value: 'modified_by'},
        { text: '', value: '', sortable: false},
        { text: '', value: '', sortable: false},
      ],
      edit_report_modal: false,
      edit_report_id: ''
    }
  },
  beforeMount(){
    if(!this.is_admin){
      this.$router.push('/home')
    }else{
      this.getReports()
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      active_users: 'active_users',
      is_admin: 'is_admin'
    })
  },
  methods:{
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    getReports(){
      var self = this
      self.reports = []

      Firebase.database().ref('reports').once('value',function(snapshot){
        var reports_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
        for(var report in reports_db_clone){
          reports_db_clone[report].id = report
          reports_db_clone[report].owner = self.db.users[reports_db_clone[report].owner].first_name + ' ' + self.db.users[reports_db_clone[report].owner].last_name
          reports_db_clone[report].created_by = self.db.users[reports_db_clone[report].created_by].first_name + ' ' + self.db.users[reports_db_clone[report].created_by].last_name
          reports_db_clone[report].modified_by = reports_db_clone[report].modified_by ? (self.db.users[reports_db_clone[report].modified_by].first_name + ' ' + self.db.users[reports_db_clone[report].modified_by].last_name) : ''
          reports_db_clone[report].created = Moment(parseFloat(reports_db_clone[report].created_date)).format('MM/DD/YY')
          reports_db_clone[report].modified = reports_db_clone[report].modified_date ? Moment(parseFloat(reports_db_clone[report].modified_date)).format('MM/DD/YY') : ''
          self.reports.push(reports_db_clone[report])
        }
      })
    },
    editReport(id){
      this.edit_report_id = id
      this.edit_report_modal = true
    },
    deleteReport(id){
      var self = this
      var report = _.find(this.reports, {id: id})
      var confirm_delete = confirm('Are you sure you want to delete report "' + report.name + '"?')
      if(confirm_delete){
        Firebase.database().ref('reports').child(id).remove().then(function(){
          self.getReports()
        })
      }
    },
    saveEditReport(obj,id){
      var self = this
      Firebase.database().ref('reports').child(id).child('name').set(obj.name).then(function(){
        Firebase.database().ref('reports').child(id).child('access').set(obj.access).then(function(){
          Firebase.database().ref('reports').child(id).child('owner').set(obj.owner).then(function(){
            self.edit_report_modal = false
            self.edit_report_id = ''
            self.getReports()
          })
        })
      })

    }
  }
}
</script>

<style lang="scss" scoped>
  .v-list-item__icon{height: 0px !important}
</style>


