<template>
  <v-dialog scrollable persistent v-model="dialog" width="1200px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header">Add Department</div>

        <v-card-text>
            <v-card style="">
              <v-card-text style="text-align:left">

              <div style="margin-bottom:15px" >
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Department (i.e, 'MSI CF General Industrial', 'MSI SF General Industrial')" outlined v-model="new_department_ou.department" v-on:keydown.enter.prevent=''></v-text-field>
                <div style="height:12px"><span style="color:red" v-if="department_already">Department already exists.</span></div>
              </div>


              <div style="margin-bottom:15px" >
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Code (i.e, '038', '238')" outlined v-model="new_department_ou.code" v-on:keydown.enter.prevent=''></v-text-field>
                <div style="height:12px"><span style="color:red" v-if="code_already">Code already exists.</span></div>
              </div>

              <v-select dense hide-details="auto" :items="['ELMCO Fabrication','ELMCO Machine','Civil','Electrical','Engineering','Fabrication','Field','Machine','Mechanical','Pump','Specialty','Transportation']" outlined label="Quote Sheet (this field is used for imported Excel files)" v-model="new_department_ou.quote_sheet"></v-select>

              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveDepartmentOU()" small :disabled="code_already || department_already || new_department_ou.code.trim() === '' || new_department_ou.department.trim() === '' || new_department_ou.quote_sheet === ''">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddDepartmentOU',
  data(){
    return{
      dialog: true,
      code_already: false,
      department_already: false,
      exisiting_codes: [],
      existing_departments: [],
      new_department_ou: {
        code: '',
        department: '',
        quote_sheet: ''
      }
    }
  },
  beforeMount(){
    for(var i = 0; i < this.ou_departments.length; i++){
      this.exisiting_codes.push(this.ou_departments[i].code)
      this.existing_departments.push(this.ou_departments[i].department.trim().toLowerCase())
    }
  },
  watch: {
    new_department_ou: {
      deep: true,
      handler(){
        this.code_already = this.exisiting_codes.indexOf(this.new_department_ou.code.trim().toLocaleLowerCase()) !== -1 ? true : false
        this.department_already = this.existing_departments.indexOf(this.new_department_ou.department.trim().toLocaleLowerCase()) !== -1 ? true : false

      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      ou_departments: 'ou_departments'
    })
  },
  methods:{
    saveDepartmentOU(){
      var self = this
      Firebase.database().ref('ou_departments').push(self.new_department_ou).then(function(){
        self.$store.state.notification = 'Department successfully saved.'
        self.$emit('close')
      })
    }
  }
}
</script>

<style lang="scss" scoped>


</style>


