<template>
  <div class="reports">
    <div class="side-filters-container">
      <div class="side-filters-inner-scroll">
        <draggable v-model="fields">
            <transition-group>
              <div class="filter-row" v-for="(field,index) in fields" :key="index">
                <div class="filter-checkbox"><v-checkbox v-model="field.selected" hide-details :ripple="false"></v-checkbox></div>
                <div class="filter-icon">
                  <v-icon color="grey lighten-2" v-if="field.filter && field.filter.length === 0" v-on:click="filter(index)">mdi-filter-plus</v-icon>
                  <v-icon color="#223773" v-if="field.filter && field.filter.length > 0" v-on:click="filter(index)">mdi-filter-plus</v-icon>

                  <v-icon color="grey lighten-2" v-if="field.filter_from === null && field.filter_to === null" v-on:click="filter(index)">mdi-filter-plus</v-icon>
                  <v-icon color="#223773" v-if="field.filter_from || field.filter_to" v-on:click="filter(index)">mdi-filter-plus</v-icon>

                  <v-icon color="grey lighten-2" v-if="field.filter_by === 'any_value'" v-on:click="filter(index)">mdi-filter-plus</v-icon>
                  <v-icon color="#223773" v-if="field.filter_by === 'less_than' || field.filter_by === 'greater_than'" v-on:click="filter(index)">mdi-filter-plus</v-icon>
                </div>
                <div class="filter-label">{{field.label}}</div>
              </div>
            </transition-group>
        </draggable>
      </div>
      <div class="filter-btns">
        <div @click="selectAll()" v-if="!unselected_columns" class="filter-link">SELECT ALL</div>
        <div @click="unselectAll()" v-if="unselected_columns" class="filter-link">UNSELECT ALL</div>
        <div @click="clearAllFilters()" class="filter-link" v-if="active_filter">CLEAR FILTERS</div>
      </div>
    </div>
    <div class="data-table-container">
      <div class="data-table-inner-scroll">
        <div class="table-controls">
          <v-text-field style="width:300px;display:table-cell" outlined dense v-model="search" append-icon="mdi-magnify" label="Search Table..." single-line hide-details autocomplete="off"></v-text-field>
          <div style="width:300px;display:table-cell;padding-left:25px;position:relative">
            <v-autocomplete sort="name" v-model="$store.state.selected_report" :items="reports_list_master" item-text="name" item-value="id" label="Report" dense outlined></v-autocomplete>
            <table style="position:absolute;top:43px;right:-2px;" v-if="$store.state.selected_report">
              <td><v-btn color="primary" x-small style="" @click="save_report_modal = true" :disabled="!unsavedReportChanges">save as</v-btn></td>
              <td><v-btn color="primary" x-small style="" @click="saveExisitingReport()" v-if="$store.state.selected_report !== 'template_report'" :disabled="!unsavedReportChanges">save</v-btn></td>
            </table>
          </div>

          <div style="width:300px;display:table-cell;padding-left:25px;position:relative">
            <v-checkbox
              v-model="$store.state.include_closed_opportunities"
              :label="`Include Closed Opportunities`"
              @change="loadOpportunities()"
            ></v-checkbox>
          </div>
          <v-btn class="export-btn" color="primary" small @click="exportReport()">EXPORT<v-icon small style="margin-left:5px">mdi-export</v-icon></v-btn>
        </div>
        <div style="position:absolute;top:48px;left:5px;"><v-btn color="primary" x-small style="" @click="loadOpportunities()">refresh</v-btn><span style="font-size:10px;font-color:lightgray;padding-left:10px">last updated: {{last_updated}}</span></div>
        <v-data-table :no-data-text="loading_message" :height="$vuetify.breakpoint.height - 250 + 'px'" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" must-sort dense id="report-table" :search="search" :headers="headers" :items="opportunities_table_arr" :items-per-page="250" :footer-props="{'items-per-page-options': [250,500,1000,2000,-1]}" fixed-header>
          <template v-slot:item="row">
              <tr>
                <td v-for="field in fields" v-if="field.selected">
                  <span v-if="field.value === 'name'" class="table-link" v-on:click="$router.push({name: 'Opportunity', params: {opportunityId: row.item.id}})">{{row.item[field.value]}}</span>
                  <span v-if="field.value !== 'name' && field.value !== 'total' && field.value !== 'cost' && field.value !== 'margin' && field.value !== 'due_time' && ['close_date','due_date','pre_bid_date','project_start_date','project_end_date'].indexOf(field.value) === -1">{{row.item[field.value]}}</span>
                  <span v-if="field.value === 'total' || field.value === 'cost'">{{formatCurrency(row.item[field.value])}}</span>
                  <span v-if="field.value === 'margin'">{{formatMargin(row.item[field.value])}}</span>
                  <span v-if="field.value === 'due_time'">{{row.item['due_time_readable']}}</span>
                  <span v-if="field.value === 'pre_bid_time'">{{row.item['pre_bid_time_readable']}}</span>
                  <span v-if="['close_date','due_date','pre_bid_date','project_start_date','project_end_date'].indexOf(field.value) !== -1">{{formatDate(row.item[field.value])}}</span>
                </td>
              </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog scrollable persistent v-model="filter_popup.dialog" v-if="filter_popup.field" width="500px">
      <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
        <v-card>
          <div class="form-header">Filter {{filter_popup.header}}</div>
          <v-card-text style="text-align:left">
            <div v-if="filter_popup.field !== 'total' && filter_popup.field !== 'cost' && filter_popup.field !== 'margin' && !filter_popup.field.includes('date')">
              <v-autocomplete
                :items="filter_popup.options"
                :label="'Filter By'"
                multiple
                dense
                outlined
                v-model="fields[filter_popup.index].filter"
                :search-input.sync="searchInput"
                @change="searchInput=''"
              ></v-autocomplete>
              <span v-if="fields[filter_popup.index].filter && fields[filter_popup.index].filter.length > 0" style="font-size:12px;font-weight:bold;color:#223773;cursor:pointer" @click="clearFieldFilters(filter_popup.index)">CLEAR FILTER</span>
            </div>
            <div v-if="filter_popup.field === 'total' || filter_popup.field === 'cost' || filter_popup.field === 'margin'" style="min-height:185px;">
              <v-radio-group v-model="fields[filter_popup.index].filter_by">
                <v-radio label="Any Value" :value="'any_value'"></v-radio>
                <v-radio label="Less Than" :value="'less_than'"></v-radio>
                <v-radio label="Greater Than" :value="'greater_than'"></v-radio>
              </v-radio-group>
              <v-text-field dense prefix="" v-if="fields[filter_popup.index].filter_by !== 'any_value'" type="number" outlined v-model.number="fields[filter_popup.index].filter_value"></v-text-field>
            </div>

            <div v-if="filter_popup.field.includes('date')">

              <v-select dense :items="['NONE', 'TODAY', 'TOMORROW', 'THIS WEEK', 'THIS MONTH', 'THIS YEAR', 'LAST WEEK', 'LAST MONTH', 'LAST YEAR', 'NEXT WEEK', 'NEXT MONTH']" @change="setDateFromPreset(filter_popup.index)" outlined label="Preset" v-model="fields[filter_popup.index].filter_preset"></v-select>

              <v-menu v-model="from_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="fields[filter_popup.index].filter_from" label="From" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off"></v-text-field>
                </template>
                <v-date-picker v-model="fields[filter_popup.index].filter_from" class="mt-4" @input="from_date_menu = false"></v-date-picker>
              </v-menu>
              <v-menu v-model="to_date_menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="fields[filter_popup.index].filter_to" label="To" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" dense outlined readonly autocomplete="off"></v-text-field>
                </template>
                <v-date-picker v-model="fields[filter_popup.index].filter_to" class="mt-4" @input="to_date_menu = false"></v-date-picker>
              </v-menu>
              <span v-if="fields[filter_popup.index].filter_to || fields[filter_popup.index].filter_from" style="font-size:12px;font-weight:bold;color:#223773;cursor:pointer" @click="clearDateFilters(filter_popup.index)">CLEAR FILTER</span>
            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeFilter()" small>CLOSE</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <SaveReport v-if="save_report_modal" @close="save_report_modal = false" @save="saveNewReport"/>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters } from 'vuex'
import moment from 'moment'
import XLSX from 'xlsx'
import { formatCurrency } from '../mixins/formatCurrency.js'
import draggable from 'vuedraggable'
import SaveReport from '../components/SaveReport.vue'

export default {
  name: 'reports',
  components: {draggable,SaveReport},
  mixins: [formatCurrency],
  data(){
    return{
      searchInput: '',
      loading_message: 'Loading data...',
      save_report_modal: false,
      search: '',
      sortBy: 'name',
      sortDesc: false,
      reports_list_master: [],
      filter_popup: {
        dialog: false,
        field: null,
        header: '',
        index: null,
        options: []
      },
      last_updated: '',
      from_date_menu: false,
      to_date_menu: false,
      filter_options: {
        'stage': ['Opportunity Identification', 'Assigning Quote #', 'Estimating', 'Review & Assembly', 'Submitting Proposal', 'Quote Revision', 'Customer Evaluating', 'Closed Won', 'Closed Lost'],
        'all_material_a36_cs': ['Yes','No'],
        'coatings_information': ['No Paint','Paint','Other','Galvanized','Prime Only','Per Specifications'],
        'pre_bid': ['Yes','No'],
        'likelihood_of_close': ['Evaluating', 'Well Positioned', 'Strongly Positioned', 'Verbal Award'],
        'proposal_type': ['Formal Proposal','Price/LT Only'],
        'quote_delivery': ['AM Review', 'Email Customer', 'Portal', 'Internal'],
        'quote_type': ['Budget', 'Firm Quote', 'T&M'],
        'revenue_category': ['Concrete Work','Dragline','Dredge','Electrical','Filters','Gearbox','Maintenance Support','Pipe','Plate Work','Pumps','Rotating Equipment','Structural Steel','Tanks','Boiler','Other'],
        'shipping_delivery_method': ['FOB - Customer Site','FOB - Midstate'],
        'steel': ['Import','Domestic','N/A','Either Acceptable'],
        'loss_reason': ['Price','Relationship','Cancelled', 'No bid - resources', 'No bid - services not offered', 'Self performed'],
        'furnish_labor': ['YES','NO'],
        'furnish_equipment': ['YES','NO'],
        'furnish_material': ['YES','NO'],
        'furnish_subs': ['YES','NO'],
        'quote_review_pending': ['YES','NO'],
        'has_unassigned_departments': ['YES','NO'],
        'departments': ['Civil','Electrical','ELMCO','ELMCO Fabrication','ELMCO Field','ELMCO Machine','Engineering','Fabrication','Field','MED','Machine','Mechanical','Pump','QA/QC','SMS','TECO','Transportation'],
        'unassigned_departments': ['Civil','Electrical','ELMCO','ELMCO Fabrication','ELMCO Field','ELMCO Machine','Engineering','Fabrication','Field','MED','Machine','Mechanical','Pump','QA/QC','SMS','TECO','Transportation'],
        'industry': ['Beverage','Chemicals','Construction','Building','Cement','Citrus','Food','Mining','Municipal','Packaging','Phosphate','Power','Pulp/Paper','Sand/Aggr','Sugar','Other'],
        'name': [],
        'account': [],
        'quote_number': [],
        'owner': [],
        'added_by': [],
        'contact_to_send_quote': [],
        'edited_by': [],
        'job_number': [],
        'old_quote_number': [],
        'shop_estimators': [],
        'field_estimators': [],
      },
      template_fields: [
                {label: 'Opportunity', value: 'name', selected: true, filter: [], filter_type: 'array'},
                {label: 'Stage', value: 'stage', selected: true, filter: [], filter_type: 'array'},
                {label: 'Account', value: 'account', selected: true, filter: [], filter_type: 'array'},

                {label: 'Account Shipping Address', value: 'account_shipping_address', selected: false, no_filter: true, filter_type: false},
                {label: 'Account Billing Address', value: 'account_billing_address', selected: false, no_filter: true, filter_type: false},

                {label: 'Industry', value: 'industry', selected: false, filter: [], filter_type: 'array'},
                {label: 'Owner', value: 'owner', selected: true, filter: [], filter_type: 'array'},
                {label: 'Quote Number', value: 'quote_number', selected: true, filter: [], filter_type: 'array'},
                {label: 'Close Date', value: 'close_date', selected: false, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Due Date', value: 'due_date', selected: true, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Due Time', value: 'due_time', selected: true, no_filter: true, filter_type: false},
                {label: 'Total', value: 'total', selected: true, filter_by: 'any_value', filter_value: false, filter_type: 'total'},
                {label: 'Cost', value: 'cost', selected: false, filter_by: 'any_value', filter_value: false, filter_type: 'cost'},
                {label: 'Margin %', value: 'margin', selected: false, filter_by: 'any_value', filter_value: false, filter_type: 'margin'},
                {label: 'Added By', value: 'added_by', selected: false, filter: [], filter_type: 'array'},
                {label: 'Departments', value: 'departments', selected: false, filter: [], filter_type: 'array'},
                {label: 'Unassigned Departments', value: 'unassigned_departments', selected: false, filter: [], filter_type: 'array'},
                {label: 'Has Unassigned Departments', value: 'has_unassigned_departments', selected: false, filter: [], filter_type: 'array'},
                {label: 'Quotes Completed', value: 'quotes_completed', selected: false, no_filter: true, filter_type: false},
                {label: 'Quotes Reviewed', value: 'quotes_reviewed', selected: false, no_filter: true, filter_type: false},
                {label: 'Pending Review', value: 'quote_review_pending', selected: false, filter: [], filter_type: 'array'},
                {label: 'Field Estimators', value: 'field_estimators', selected: false, filter: [], filter_type: 'array'},
                {label: 'Shop Estimators', value: 'shop_estimators', selected: false, filter: [], filter_type: 'array'},
                {label: 'All Material A36 CS', value: 'all_material_a36_cs', selected: false, filter: [], filter_type: 'array'},
                {label: 'Coatings Information', value: 'coatings_information', selected: false, filter: [], filter_type: 'array'},
                {label: 'Contact To Send Quote', value: 'contact_to_send_quote', selected: false, filter: [], filter_type: 'array'},
                {label: 'Pre Bid', value: 'pre_bid', selected: false, filter: [], filter_type: 'array'},
                {label: 'Last Modified By', value: 'edited_by', selected: false, filter: [], filter_type: 'array'},
                {label: 'Job Number', value: 'job_number', selected: false, filter: [], filter_type: 'array'},
                {label: 'Likelihood Of Close', value: 'likelihood_of_close', selected: false, filter: [], filter_type: 'array'},
                {label: 'Proposal Type', value: 'proposal_type', selected: false, filter: [], filter_type: 'array'},
                {label: 'Quote Delivery', value: 'quote_delivery', selected: false, filter: [], filter_type: 'array'},
                {label: 'Quote Type', value: 'quote_type', selected: false, filter: [], filter_type: 'array'},
                {label: 'Revenue Category', value: 'revenue_category', selected: false, filter: [], filter_type: 'array'},
                {label: 'Shipping Delivery Method', value: 'shipping_delivery_method', selected: false, filter: [], filter_type: 'array'},
                {label: 'Steel', value: 'steel', selected: false, filter: [], filter_type: 'array'},
                {label: 'Furnish Labor', value: 'furnish_labor', selected: false, filter: [], filter_type: 'array'},
                {label: 'Furnish Equipment', value: 'furnish_equipment', selected: false, filter: [], filter_type: 'array'},
                {label: 'Furnish Material', value: 'furnish_material', selected: false, filter: [], filter_type: 'array'},
                {label: 'Furnish Subs', value: 'furnish_subs', selected: false, filter: [], filter_type: 'array'},
                {label: 'Loss Reason', value: 'loss_reason', selected: false, filter: [], filter_type: 'array'},
                {label: 'Old Quote Number', value: 'old_quote_number', selected: false, filter: [], filter_type: 'array'},
                {label: 'Created Date', value: 'created_date', selected: false, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Last Modified', value: 'edit_date', selected: false, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Pre Bid Date', value: 'pre_bid_date', selected: false, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Project Start Date', value: 'project_start_date', selected: false, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Project End Date', value: 'project_end_date', selected: false, filter_from: null, filter_to: null, filter_preset: 'NONE', filter_type: 'date'},
                {label: 'Build To Specific Code', value: 'build_to_specific_code', selected: false, no_filter: true, filter_type: false},
                {label: 'Facility Location', value: 'facility_location', selected: false, no_filter: true, filter_type: false},
                {label: 'Competitor Price', value: 'competitor_price', selected: false, no_filter: true, filter_type: false},
                {label: 'Lost To Competitor', value: 'lost_to_competitor', selected: false, no_filter: true, filter_type: false},
                {label: 'Next Steps', value: 'next_steps', selected: false, no_filter: true, filter_type: false},
                {label: 'Pre Bid Time', value: 'pre_bid_time', selected: false, no_filter: true, filter_type: false},
                {label: 'Scope Of Work', value: 'scope_of_work', selected: false, no_filter: true, filter_type: false},
                {label: 'Competitors Involved', value: 'competitors_involved', selected: false, no_filter: true, filter_type: false},
              ],
      fields: [],
      opportunities_arr: [],
      opportunities_table_arr: []
    }
  },
  beforeMount(){
    var self = this

    // LOAD REPORTS
    Firebase.database().ref('reports').once('value',function(snapshot){
      var reports = snapshot.val()
      for(var report in reports){
        var report_fields = reports[report].fields
        for(var i = 0; i < report_fields.length; i++){
          var filter_type = report_fields[i].filter_type
          if(filter_type === 'array'){
            report_fields[i].filter = report_fields[i].filter ? report_fields[i].filter : []
          }
          if(filter_type === 'date'){
            report_fields[i].filter_from = report_fields[i].filter_from ? report_fields[i].filter_from : null
            report_fields[i].filter_to = report_fields[i].filter_to ? report_fields[i].filter_to : null
          }
        }
        if(reports[report].access === 'Public' || reports[report].owner === self.current_user){
          self.reports_list_master.push({name: reports[report].name, id: report, fields: report_fields})
          self.reports_list_master = _.orderBy(self.reports_list_master,['name'],['asc'])
        }
      }

      self.reports_list_master.unshift({name: 'Template', id: 'template_report'})
      self.fields = self.$store.state.selected_report === 'template_report' ? JSON.parse( JSON.stringify( self.template_fields ) ) : JSON.parse( JSON.stringify( _.find(self.reports_list_master, {id: self.selected_report}).fields ) )

      for(var i = 0; i < self.fields.length; i++){
        if(self.fields[i].filter_preset){
          self.setDateFromPreset(i)
        }
      }

      self.listen_for_report_change = true
      self.sortBy = self.$store.state.report_sort_by
      self.sortDesc = self.$store.state.report_sort_desc
    }).then(function(){
      self.loadOpportunities()
    });
  },
  watch:{
    'selected_report': function (val) {
      this.fields = val === 'template_report' ? JSON.parse( JSON.stringify( this.template_fields ) ) : JSON.parse( JSON.stringify( _.find(this.reports_list_master, {id: this.selected_report}).fields ) )
      for(var i = 0; i < this.fields.length; i++){
        if(this.fields[i].filter_preset){
          this.setDateFromPreset(i)
        }
      }
      this.buildTable()
    },
    'sortBy': function ( val ){
      this.$store.state.report_sort_by = val
    },
    'sortDesc': function ( val ){
      this.$store.state.report_sort_desc = val
    },
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      revenue_categories: 'revenue_categories',
      selected_report: 'selected_report'
    }),
    active_filter(){
      var has_filter = false
      for(var field in this.fields){
        if(this.fields[field].filter){
          if(this.fields[field].filter.length > 0){
            has_filter = true
          }
        }
        if(this.fields[field].filter_by){
          if(this.fields[field].filter_by !== 'any_value'){
            has_filter = true
          }
        }
        if(this.fields[field].filter_from || this.fields[field].filter_to){
          if(this.fields[field].filter_from !== null || this.fields[field].filter_to !== null){
            has_filter = true
          }
        }
      }
      return has_filter
    },
    unsavedReportChanges(){
      var active_fields = []
      var original_fields = []
      if(this.reports_list_master.length === 0 || this.fields.length === 0){
        return false
      }
      if(this.selected_report === 'template_report'){
        active_fields = JSON.parse( JSON.stringify( this.fields ) )
        original_fields = JSON.parse( JSON.stringify( this.template_fields ) )
      }else{
        var active_arr = JSON.parse( JSON.stringify( this.fields ) )
        for(var i = 0; i < active_arr.length; i++){
          if(active_arr[i].filter_preset){
            active_arr[i].filter_from = null
            active_arr[i].filter_to = null
          }
          active_fields.push(active_arr[i])
        }
        var original_arr = JSON.parse( JSON.stringify( _.find(this.reports_list_master, {id: this.selected_report}).fields ) )
        for(var i = 0; i < original_arr.length; i++){
          if(original_arr[i].filter_preset){
            original_arr[i].filter_from = null
            original_arr[i].filter_to = null
          }
          original_fields.push(original_arr[i])
        }
      }
      return JSON.stringify(active_fields) !== JSON.stringify(original_fields)
    },
    headers(){
      var headers = []
      for(var field in this.fields){
        if(this.fields[field].selected){
          headers.push({text: this.fields[field].label, align: 'start', value: this.fields[field].value})
        }
      }
      return headers
    },
    unselected_columns(){
      var all_selected = true
      for(var field in this.fields){
        if(!this.fields[field].selected){
          all_selected = false
        }
      }
      return all_selected
    },
  },
  methods:{
    formatDate(val){
      return val ? moment(val).format('MM/DD/YY') : ''
    },
    loadOpportunities(){
      var self = this
      var opportunity_to_revenue_mapping = {}
      var opportunity_to_cost_mapping = {}
      var opportunity_to_quotes_completed = {}
      var opportunity_to_quotes_reviewed = {}
      var opportunity_to_quotes_needs_reviewed = {}
      self.$store.state.isLoading = true
      self.opportunities_arr = []

      if(!self.$store.state.include_closed_opportunities){
        Firebase.database().ref('totals').once('value',function(totals_snapshot){
          var totals_snapshot_val = totals_snapshot.val()
          for(var opp_id in totals_snapshot_val){
            opportunity_to_revenue_mapping[opp_id] = 0
            opportunity_to_cost_mapping[opp_id] = 0
            opportunity_to_quotes_completed[opp_id] = []
            opportunity_to_quotes_reviewed[opp_id] = []
            opportunity_to_quotes_needs_reviewed[opp_id] = []
            var totals = totals_snapshot_val[opp_id]
            for(var tid in totals){
              var total = totals[tid]
              if(total.show_in_master){
                opportunity_to_revenue_mapping[opp_id] += total.revenue
                opportunity_to_cost_mapping[opp_id] += total.cost
              }
              if(total.completed){
                opportunity_to_quotes_completed[opp_id].push(total.quote_sheet)
              }
              if(total.reviewed){
                opportunity_to_quotes_reviewed[opp_id].push(total.quote_sheet)
              }
              if(total.completed && !total.reviewed){
                opportunity_to_quotes_needs_reviewed[opp_id].push(total.quote_sheet)
              }
            }
          }
        }).then(function(){
          Firebase.database().ref('opportunities').orderByChild('status').equalTo('open').once('value',function(snapshot){
            var opportunities_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
            for(var opp_id in opportunities_db_clone){
              var opp_object = opportunities_db_clone[opp_id]
              var table_row = {}
              var opp_total_amt = 0
              var opp_total_cost = 0
              var revenue_override_percentage = opp_object.revenue_override_percentage ? (opp_object.revenue_override_percentage / 100 ) : 0

              if(!opp_object.disabled){
                opp_object.id = opp_id
                opp_object.industry = self.db.accounts[opp_object.account].industry
                opp_object.account_shipping_address = self.db.accounts[opp_object.account].shipping_address + ' | ' + self.db.accounts[opp_object.account].shipping_street + ', ' + self.db.accounts[opp_object.account].shipping_city + ', ' + self.db.accounts[opp_object.account].shipping_state + ', ' + self.db.accounts[opp_object.account].shipping_zip
                opp_object.account_billing_address = self.db.accounts[opp_object.account].billing_address + ' | ' + self.db.accounts[opp_object.account].billing_street + ', ' + self.db.accounts[opp_object.account].billing_city + ', ' + self.db.accounts[opp_object.account].billing_state + ', ' + self.db.accounts[opp_object.account].billing_zip
                opp_object.account = self.db.accounts[opp_object.account].name
                opp_object.added_by = self.db.users[opp_object.added_by].first_name + ' ' + self.db.users[opp_object.added_by].last_name
                opp_object.contact_to_send_quote = opp_object.contact_to_send_quote ? (self.db.contacts[opp_object.contact_to_send_quote].first_name + ' ' + self.db.contacts[opp_object.contact_to_send_quote].last_name) : ''
                opp_object.close_date = opp_object.close_date ? opp_object.close_date : ''
                opp_object.due_date = opp_object.due_date ? opp_object.due_date : ''
                opp_object.pre_bid_date = opp_object.pre_bid_date ? opp_object.pre_bid_date : ''
                opp_object.project_start_date = opp_object.project_start_date ? opp_object.project_start_date : ''
                opp_object.project_end_date = opp_object.project_end_date ? opp_object.project_end_date : ''
                opp_object.created_date = moment(opp_object.created_date).format('MM/DD/YY')
                opp_object.edit_date = opp_object.edit_date? moment(opp_object.edit_date).format('MM/DD/YY') : ''
                opp_object.edited_by = opp_object.edited_by ? (self.db.users[opp_object.edited_by].first_name + ' ' + self.db.users[opp_object.edited_by].last_name) : ''
                opp_object.owner = self.db.users[opp_object.owner].first_name + ' ' + self.db.users[opp_object.owner].last_name
                opp_object.furnish_labor = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Labor') ? 'YES' : 'NO'
                opp_object.furnish_material = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Material') ? 'YES' : 'NO'
                opp_object.furnish_equipment = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Equipment') ? 'YES' : 'NO'
                opp_object.furnish_subs = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Subs') ? 'YES' : 'NO'
                opp_object.total = 0
                opp_object.cost = 0

                if (opp_object.salesforce_opportunity) {
                  opp_object.total += opp_object.salesforce_revenue
                  opp_object.cost += opp_object.salesforce_cost
                }else{
                  opp_object.total += opportunity_to_revenue_mapping[opp_id] ? opportunity_to_revenue_mapping[opp_id] : 0
                  opp_object.cost += opportunity_to_cost_mapping[opp_id] ? opportunity_to_cost_mapping[opp_id] : 0
                }

                opp_object.total = opp_object.total + (opp_object.total * revenue_override_percentage)

                opp_object.margin = parseFloat(self.margin(opp_object.total,opp_object.cost))
                opp_object.due_time_readable = opp_object.due_time
                opp_object.due_time = opp_object.due_time ? self.convertTime12to24(opp_object.due_time) : ''
                opp_object.pre_bid_time_readable = opp_object.pre_bid_time
                opp_object.pre_bid_time = opp_object.pre_bid_time ? self.convertTime12to24(opp_object.pre_bid_time) : ''
                opp_object.departments = ''
                opp_object.shop_estimators = ''
                opp_object.field_estimators = ''

                var departments = []
                var shop_estimators = []
                var field_estimators = []
                var unassigned_departments = []

                for(var dept in opp_object.estimating){
                  if(opp_object.estimating[dept].required){
                    departments.push(opp_object.estimating[dept].title)

                    if(opp_object.estimating[dept].estimator.length > 0){
                      var estimator = self.db.users[opp_object.estimating[dept].estimator].first_name + ' ' + self.db.users[opp_object.estimating[dept].estimator].last_name
                      if(dept === 'fabrication' || dept === 'machine' || dept === 'mechanical' || dept === 'pump' || dept === 'transportation' || dept === 'engineering' || dept === 'elmco_machine' || dept === 'elmco_fabrication'){
                        if(shop_estimators.indexOf(estimator) === -1){
                          shop_estimators.push(estimator)
                        }
                      }else{
                        if(field_estimators.indexOf(estimator) === -1){
                          field_estimators.push(estimator)
                        }
                      }
                    }else{
                      unassigned_departments.push(opp_object.estimating[dept].title)
                    }
                  }
                }

                self.filter_options.name.push(opp_object.name)
                self.filter_options.account.push(opp_object.account)
                self.filter_options.quote_number.push(opp_object.quote_number)
                self.filter_options.owner.push(opp_object.owner)
                self.filter_options.added_by.push(opp_object.added_by)
                self.filter_options.contact_to_send_quote.push(opp_object.contact_to_send_quote)
                self.filter_options.edited_by.push(opp_object.edited_by)
                self.filter_options.job_number.push(opp_object.job_number)
                self.filter_options.old_quote_number.push(opp_object.old_quote_number)

                for(var s = 0; s < shop_estimators.length; s++){
                  if(self.filter_options.shop_estimators.indexOf(shop_estimators[s] === -1)){
                    self.filter_options.shop_estimators.push(shop_estimators[s])
                  }
                }

                for(var f = 0; f < field_estimators.length; f++){
                  if(self.filter_options.field_estimators.indexOf(field_estimators[f] === -1)){
                    self.filter_options.field_estimators.push(field_estimators[f])
                  }
                }

                opp_object.departments = departments.sort().join('; ')
                opp_object.shop_estimators = shop_estimators.sort().join('; ')
                opp_object.field_estimators = field_estimators.sort().join('; ')
                opp_object.unassigned_departments = unassigned_departments.sort().join('; ')

                opp_object.quotes_completed = opportunity_to_quotes_completed[opp_id] ? opportunity_to_quotes_completed[opp_id].join('; ') : ''
                opp_object.quotes_reviewed = opportunity_to_quotes_reviewed[opp_id] ? opportunity_to_quotes_reviewed[opp_id].join('; ') : ''
                opp_object.quote_review_pending = opportunity_to_quotes_needs_reviewed[opp_id] ? (opportunity_to_quotes_needs_reviewed[opp_id].length === 0 ? 'NO' : 'YES') : 'NO'
                opp_object.has_unassigned_departments = unassigned_departments.length === 0 ? 'NO' : 'YES'

                self.opportunities_arr.push(opp_object)
              }
            }

            self.last_updated = moment().format("MM/DD/YY h:mm:ss A")

          }).then(function(){
            self.buildTable()
            self.$store.state.isLoading = false
          })
        })
      }else{
        Firebase.database().ref('totals').once('value',function(totals_snapshot){
          var totals_snapshot_val = totals_snapshot.val()
          for(var opp_id in totals_snapshot_val){
            opportunity_to_revenue_mapping[opp_id] = 0
            opportunity_to_cost_mapping[opp_id] = 0
            opportunity_to_quotes_completed[opp_id] = []
            opportunity_to_quotes_reviewed[opp_id] = []
            opportunity_to_quotes_needs_reviewed[opp_id] = []
            var totals = totals_snapshot_val[opp_id]
            for(var tid in totals){
              var total = totals[tid]
              if(total.show_in_master){
                opportunity_to_revenue_mapping[opp_id] += total.revenue
                opportunity_to_cost_mapping[opp_id] += total.cost
              }
              if(total.completed){
                opportunity_to_quotes_completed[opp_id].push(total.quote_sheet)
              }
              if(total.reviewed){
                opportunity_to_quotes_reviewed[opp_id].push(total.quote_sheet)
              }
              if(total.completed && !total.reviewed){
                opportunity_to_quotes_needs_reviewed[opp_id].push(total.quote_sheet)
              }
            }
          }
        }).then(function(){
          Firebase.database().ref('opportunities').once('value',function(snapshot){
            var opportunities_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
            for(var opp_id in opportunities_db_clone){
              var opp_object = opportunities_db_clone[opp_id]
              var table_row = {}
              var opp_total_amt = 0
              var opp_total_cost = 0
              if(!opp_object.disabled){
                opp_object.id = opp_id
                opp_object.industry = self.db.accounts[opp_object.account].industry
                opp_object.account_shipping_address = self.db.accounts[opp_object.account].shipping_address + ' | ' + self.db.accounts[opp_object.account].shipping_street + ', ' + self.db.accounts[opp_object.account].shipping_city + ', ' + self.db.accounts[opp_object.account].shipping_state + ', ' + self.db.accounts[opp_object.account].shipping_zip
                opp_object.account_billing_address = self.db.accounts[opp_object.account].billing_address + ' | ' + self.db.accounts[opp_object.account].billing_street + ', ' + self.db.accounts[opp_object.account].billing_city + ', ' + self.db.accounts[opp_object.account].billing_state + ', ' + self.db.accounts[opp_object.account].billing_zip
                opp_object.account = self.db.accounts[opp_object.account].name
                opp_object.added_by = self.db.users[opp_object.added_by].first_name + ' ' + self.db.users[opp_object.added_by].last_name
                opp_object.contact_to_send_quote = opp_object.contact_to_send_quote ? (self.db.contacts[opp_object.contact_to_send_quote].first_name + ' ' + self.db.contacts[opp_object.contact_to_send_quote].last_name) : ''
                opp_object.created_date = moment(opp_object.created_date).format('YYYY-MM-DD')
                opp_object.edit_date = opp_object.edit_date? moment(opp_object.edit_date).format('YYYY-MM-DD') : ''
                opp_object.edited_by = opp_object.edited_by ? (self.db.users[opp_object.edited_by].first_name + ' ' + self.db.users[opp_object.edited_by].last_name) : ''
                opp_object.owner = self.db.users[opp_object.owner].first_name + ' ' + self.db.users[opp_object.owner].last_name
                opp_object.furnish_labor = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Labor') ? 'YES' : 'NO'
                opp_object.furnish_material = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Material') ? 'YES' : 'NO'
                opp_object.furnish_equipment = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Equipment') ? 'YES' : 'NO'
                opp_object.furnish_subs = opp_object.to_be_furnished && opp_object.to_be_furnished.includes('Subs') ? 'YES' : 'NO'
                opp_object.total = 0
                opp_object.cost = 0

                if (opp_object.salesforce_opportunity) {
                  opp_object.total += opp_object.salesforce_revenue
                  opp_object.cost += opp_object.salesforce_cost
                }else{
                  opp_object.total += opportunity_to_revenue_mapping[opp_id] ? opportunity_to_revenue_mapping[opp_id] : 0
                  opp_object.cost += opportunity_to_cost_mapping[opp_id] ? opportunity_to_cost_mapping[opp_id] : 0
                }

                opp_object.margin = parseFloat(self.margin(opp_object.total,opp_object.cost))
                opp_object.due_time_readable = opp_object.due_time
                opp_object.due_time = opp_object.due_time ? self.convertTime12to24(opp_object.due_time) : ''
                opp_object.pre_bid_time_readable = opp_object.pre_bid_time
                opp_object.pre_bid_time = opp_object.pre_bid_time ? self.convertTime12to24(opp_object.pre_bid_time) : ''
                opp_object.departments = ''
                opp_object.shop_estimators = ''
                opp_object.field_estimators = ''

                var departments = []
                var shop_estimators = []
                var field_estimators = []
                var unassigned_departments = []

                for(var dept in opp_object.estimating){
                  if(opp_object.estimating[dept].required){
                    departments.push(opp_object.estimating[dept].title)
                    if(opp_object.estimating[dept].estimator.length > 0){
                      var estimator = self.db.users[opp_object.estimating[dept].estimator].first_name + ' ' + self.db.users[opp_object.estimating[dept].estimator].last_name
                      if(dept === 'fabrication' || dept === 'machine' || dept === 'mechanical' || dept === 'pump' || dept === 'transportation' || dept === 'engineering' || dept === 'elmco_machine' || dept === 'elmco_fabrication'){
                        if(shop_estimators.indexOf(estimator) === -1){
                          shop_estimators.push(estimator)
                        }
                      }else{
                        if(field_estimators.indexOf(estimator) === -1){
                          field_estimators.push(estimator)
                        }
                      }
                    }else{
                      unassigned_departments.push(opp_object.estimating[dept].title)
                    }
                  }
                }

                self.filter_options.name.push(opp_object.name)
                self.filter_options.account.push(opp_object.account)
                self.filter_options.quote_number.push(opp_object.quote_number)
                self.filter_options.owner.push(opp_object.owner)
                self.filter_options.added_by.push(opp_object.added_by)
                self.filter_options.contact_to_send_quote.push(opp_object.contact_to_send_quote)
                self.filter_options.edited_by.push(opp_object.edited_by)
                self.filter_options.job_number.push(opp_object.job_number)
                self.filter_options.old_quote_number.push(opp_object.old_quote_number)

                for(var s = 0; s < shop_estimators.length; s++){
                  if(self.filter_options.shop_estimators.indexOf(shop_estimators[s] === -1)){
                    self.filter_options.shop_estimators.push(shop_estimators[s])
                  }
                }

                for(var f = 0; f < field_estimators.length; f++){
                  if(self.filter_options.field_estimators.indexOf(field_estimators[f] === -1)){
                    self.filter_options.field_estimators.push(field_estimators[f])
                  }
                }

                opp_object.departments = departments.sort().join('; ')
                opp_object.shop_estimators = shop_estimators.sort().join('; ')
                opp_object.field_estimators = field_estimators.sort().join('; ')
                opp_object.unassigned_departments = unassigned_departments.sort().join('; ')

                opp_object.quotes_completed = opportunity_to_quotes_completed[opp_id] ? opportunity_to_quotes_completed[opp_id].join('; ') : ''
                opp_object.quotes_reviewed = opportunity_to_quotes_reviewed[opp_id] ? opportunity_to_quotes_reviewed[opp_id].join('; ') : ''
                opp_object.quote_review_pending = opportunity_to_quotes_needs_reviewed[opp_id] ? (opportunity_to_quotes_needs_reviewed[opp_id].length === 0 ? 'NO' : 'YES') : 'NO'
                opp_object.has_unassigned_departments = unassigned_departments.length === 0 ? 'NO' : 'YES'

                self.opportunities_arr.push(opp_object)
              }
            }

            self.last_updated = moment().format("MM/DD/YYYY h:mm:ss A")

          }).then(function(){
            self.buildTable()
            self.$store.state.isLoading = false
          })
        })
      }

    },
    buildTable(){
      this.loading_message = 'Loading data...'
      var opps_arr = this.opportunities_arr
      var valid_opps_arr = []
      for(var i = 0; i < opps_arr.length; i++){
        var opp_object = opps_arr[i]
        if(this.meetsFilterCriteria(opp_object)){
          valid_opps_arr.push(opp_object)
        }
      }
      if(valid_opps_arr.length === 0){
        this.loading_message = 'No matching records found'
      }
      this.opportunities_table_arr = valid_opps_arr
    },
    margin(revenue,cost){
      var original = ((revenue - cost) / revenue) * 100
      var formatted = original.toFixed(1)
      return (revenue > 0 ? formatted : 0)
    },
    formatMargin(margin){
      return margin + '%'
    },
    convertTime12to24(time12h){
      var [time, modifier] = time12h.split(' ');
      var [hours, minutes] = time.split(':');
      var sortable_string;
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      sortable_string = String(hours) + String(minutes)
      return parseInt(sortable_string) !== 0 ? parseInt(sortable_string) : 1
    },
    saveNewReport(report_obj){
      var self = this
      var fields_clone = JSON.parse( JSON.stringify( self.fields ) )

      var report = {
        name: report_obj.name,
        access: report_obj.access,
        fields: fields_clone,
        created_by: self.current_user,
        owner: self.current_user,
        created_date: Date.now()
      }

      Firebase.database().ref('reports').push(report).then(function(snapshot){
        self.reports_list_master.unshift({name: report.name, id: snapshot.key, fields: report.fields})
        self.$store.state.selected_report = snapshot.key
        self.save_report_modal = false
      })
    },
    saveExisitingReport(){
      var self = this
      var confirm_save = confirm('Are you sure you want to save and replace report "' +  _.find(self.reports_list_master, {id: self.selected_report}).name + '"?')
      if(confirm_save){
        var fields_clone = JSON.parse( JSON.stringify( self.fields ) )
        Firebase.database().ref('reports').child(self.$store.state.selected_report).child('fields').set(fields_clone)
        Firebase.database().ref('reports').child(self.$store.state.selected_report).child('modified_by').set(self.current_user)
        Firebase.database().ref('reports').child(self.$store.state.selected_report).child('modified_date').set(Date.now())
      }
    },
    meetsFilterCriteria(row){
      var meets_filter_criteria = true
      for(var i = 0; i < this.fields.length; i++){
        var field = this.fields[i]
        if(field.value === 'departments' || field.value === 'unassigned_departments' || field.value ==='field_estimators' || field.value === 'shop_estimators'){
          if(field.filter.length === 0){
            meets_filter_criteria = true
          }else{
            var row_arr = row[field.value].split('; ')
            var has_filter = false

            for(var y = 0; y < field.filter.length; y++){
              if(row_arr.includes(field.filter[y])){
                has_filter = true
              }
            }
            meets_filter_criteria = has_filter
          }
        }

        if(this.filter_options[field.value] && field.value !== 'departments' && field.value !== 'unassigned_departments' && field.value !== 'field_estimators' && field.value !== 'shop_estimators'){
          meets_filter_criteria = field.filter.length === 0 || field.filter.includes(row[field.value])
        }

        if(field.value === 'total' || field.value === 'cost' || field.value === 'margin'){
          switch (field.filter_by) {
            case 'equal_to':
              meets_filter_criteria = row[field.value] === field.filter_value
              break;
            case 'greater_than':
              meets_filter_criteria = row[field.value] > field.filter_value
              break;
            case 'less_than':
              meets_filter_criteria = row[field.value] < field.filter_value
              break;
            default:
              meets_filter_criteria = true
          }
        }

        if(field.value.includes('date')){
          if(field.filter_from && field.filter_to){
            meets_filter_criteria = moment(row[field.value]).isSameOrAfter(moment(field.filter_from)) && moment(row[field.value]).isSameOrBefore(moment(field.filter_to))
          }
          if(field.filter_from && !field.filter_to){
            meets_filter_criteria = moment(row[field.value]).isSameOrAfter(moment(field.filter_from))
          }
          if(!field.filter_from && field.filter_to){
            meets_filter_criteria = moment(row[field.value]).isSameOrBefore(moment(field.filter_to))
          }
          if(!field.filter_from && !field.filter_to){
            meets_filter_criteria = true
          }
        }

        if(!meets_filter_criteria){break}
      }

      return meets_filter_criteria
    },
    selectAll(){
      for(var field in this.fields){
        this.fields[field].selected = true
      }
    },
    unselectAll(){
      for(var field in this.fields){
        this.fields[field].selected = false
      }
    },
    closeFilter(){
      this.filter_popup.dialog = false
      this.buildTable()
    },
    filter(index){
      var field = this.fields[index]
      this.filter_popup.dialog = true
      this.filter_popup.field = field.value
      this.filter_popup.header = field.label
      this.filter_popup.index = index
      this.filter_popup.options = this.filter_options[field.value]
    },
    clearFieldFilters(index){
      this.fields[index].filter = []
    },
    clearDateFilters(index){
      this.fields[index].filter_from = null
      this.fields[index].filter_to = null
      this.fields[index].filter_preset = 'NONE'
    },
    setDateFromPreset(index){
      switch (this.fields[index].filter_preset) {
        case 'TODAY':
          this.fields[index].filter_from = moment().format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().format('YYYY-MM-DD')
          break;
        case 'TOMORROW':
          this.fields[index].filter_from = moment().add(1,'days').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().add(1,'days').format('YYYY-MM-DD')
          break;
        case 'THIS WEEK':
          this.fields[index].filter_from = moment().startOf('week').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().endOf('week').format('YYYY-MM-DD')
          break;
        case 'THIS MONTH':
          this.fields[index].filter_from = moment().startOf('month').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().endOf('month').format('YYYY-MM-DD')
          break;
        case 'THIS YEAR':
          this.fields[index].filter_from = moment().startOf('year').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().endOf('year').format('YYYY-MM-DD')
          break;
        case 'LAST WEEK':
          this.fields[index].filter_from = moment().subtract(1,'weeks').startOf('week').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().subtract(1,'weeks').endOf('week').format('YYYY-MM-DD')
          break;
        case 'LAST MONTH':
          this.fields[index].filter_from = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')
          break;
        case 'LAST YEAR':
          this.fields[index].filter_from = moment().subtract(1,'years').startOf('year').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().subtract(1,'years').endOf('year').format('YYYY-MM-DD')
          break;
        case 'NEXT WEEK':
          this.fields[index].filter_from = moment().add(1,'weeks').startOf('week').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().add(1,'weeks').endOf('week').format('YYYY-MM-DD')
          break;
        case 'NEXT MONTH':
          this.fields[index].filter_from = moment().add(1,'months').startOf('month').format('YYYY-MM-DD')
          this.fields[index].filter_to = moment().add(1,'months').endOf('month').format('YYYY-MM-DD')
          break;          
        default:
          this.fields[index].filter_from ? this.fields[index].filter_from : null
          this.fields[index].filter_to ? this.fields[index].filter_to : null
      }
    },
    clearAllFilters(){
      this.search = ''
      for(var field in this.fields){
        if(this.fields[field].filter){
          this.fields[field].filter = []
        }
        if(this.fields[field].filter_by){
          this.fields[field].filter_by = 'any_value'
          this.fields[field].filter_value = false
        }
        if(this.fields[field].filter_from || this.fields[field].filter_to){
          this.fields[field].filter_from = null
          this.fields[field].filter_to = null
          this.fields[field].filter_preset = 'NONE'
        }
      }
      this.buildTable()
    },
    // updateReport(id,arr){
    //   Firebase.database().ref('reports').child(id).child('fields').set(arr)
    // },
    exportReport(){
      // var reports = this.reports_list_master
      // var shipping = {
      //   "filter_type": false,
      //   "label": "Account Shipping Address",
      //   "no_filter": true,
      //   "selected": false,
      //   "value": "account_shipping_address"
      // }
      // var billing = {
      //   "filter_type": false,
      //   "label": "Account Billing Address",
      //   "no_filter": true,
      //   "selected": false,
      //   "value": "account_billing_address"
      // }
      // var self = this
      // Firebase.database().ref('reports').once('value',function(snapshot){
      //   var reports = snapshot.val()

      //   for(var report_id in reports){
      //     var report = reports[report_id]

      //     if(report.fields){

      //       if(report.fields.length == 55){
      //         report.fields.push(shipping)
      //         report.fields.push(billing)
      //         self.updateReport(report_id,report.fields)
      //       }
      //     }
      //   }
      // })
      // return
      var self = this
      var workbook = XLSX.utils.book_new()
      var worksheet = XLSX.utils.table_to_sheet(document.getElementById('report-table'), {raw: true})
      var date = moment().format('YYYY-MM-DD')
      var abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z","AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ","BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"]
      var range = worksheet['!ref']
      var first_row = parseFloat(range.split(':')[0].replace(/[^\d.-]/g, ''))
      var last_row = parseFloat(range.split(':')[1].replace(/[^\d.-]/g, ''))

      for(var i = first_row; i <= last_row; i++){
        for(var y = 0; y < abc.length; y++){
          if(worksheet[abc[y]+i]){
            if(worksheet[abc[y]+i]['v'].includes('$') && (worksheet[abc[y]+1]['v'] === 'Total' || worksheet[abc[y]+1]['v']  === 'Cost') ){
              worksheet[abc[y]+i] = {t: 'n', v: parseFloat(worksheet[abc[y]+i]['v'].replace('$','').replace(/,/g, ''))}
            }
          }
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'ms-ops report ' + date + '.xlsx')
    },
  }
}
</script>

<style lang="scss" scoped>

  .reports{
    display: flex;
    width: 100%;
    padding: 10px;
    background: white;
  }

  .side-filters-container{
    width: 225px;
    background: white;
    height: calc(100vh - 110px);
    overflow: hidden;
    border-radius: 4px;
  }

  .side-filters-inner-scroll{
    height: calc(100vh - 165px);
    overflow-y: auto;
    border-radius: 4px;
  }

  .data-table-container{
    flex:1;
    padding: 5px;
  }

  .data-table-inner-scroll{
    position: relative;
    overflow-x: auto;
    width: calc(100vw - 305px);
  }

  .filter-row{
    display: table;
    width: 100%;
    background: white;
    cursor: pointer;
    border-radius: 4px;
  }

  .filter-checkbox{
    position: relative;
    bottom:2px;
    display: table-cell;
    width: 24px;
  }

  .filter-icon{
    position: relative;
    bottom: 1px;
    width: 25px;
    display: table-cell;
    vertical-align: middle;
  }

  .filter-label{
    display: table-cell;
    font-size: 12px;
    color: rgb(105, 105, 105);
    text-align: left;
    vertical-align: middle;
  }

  .filter-btns{
    margin-top: 5px;
    padding-top: 5px;
    height: 30px;
    display: table;
  }

  .filter-link{
    font-size:10px;
    font-weight:bold;
    margin:0px;
    width: 95px;
    display: table-cell;
    vertical-align: middle;
  }

  ::v-deep .filter .v-label {
   font-size: 12px;
  }

  .v-input--selection-controls {
    margin-top: 0px;
  }

  .side-filters-inner-scroll ::v-deep .v-input--hide-details > .v-input__control > .v-input__slot{
    width: 0px;
  }

  .table-controls{
    display: table;
    padding: 5px;
  }

  .filter-link{
    color: #223773;
  }

  .export-btn{
    position: absolute;
    top: 0px;
    right: 0px;
  }

  ::v-deep .v-data-table{
    table-layout: fixed;
  }

  ::v-deep .v-data-table th{
    border: thin solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    border-right: 0;
    background: #223773 !important;
    color: white !important;
    padding: 4px !important;
  }

  ::v-deep .v-data-table td{
    border: thin solid rgba(0, 0, 0, 0.12);
    border-right: 0;
    border-top: 0;
    min-width: 150px;
    word-break: break-all;
    padding: 4px !important;
  }

  ::v-deep .v-data-table table tr th:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }

  ::v-deep .v-data-table table tr td:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }

  ::v-deep .mdi-arrow-up::before {
    color: white;
  }
</style>


