<template>
  <div class="accounts">
    <v-container fluid>
      <v-card>
        <v-card-title>
          <v-row dense style="max-width:800px">
            <v-btn small color="primary" @click="form_id = 'new'" style='margin:10px'>New</v-btn>
            <v-col cols="12" sm="12" md="4">
              <v-text-field outlined dense v-model="search" append-icon="mdi-magnify" label="Search Accounts..." single-line hide-details autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select outlined dense v-model="filter" :items="['All Accounts', 'My Accounts']"></v-select>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table dense :mobile-breakpoint="0" no-data-text="No data available" :search="search" must-sort :headers="!isMobile ? headers : $_.filter(headers, {show_mobile: true})" :items="accounts_table_arr" :items-per-page="500" :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" :height="$vuetify.breakpoint.height - 300 + 'px'" fixed-header>
          <template v-slot:item="row">
              <tr>
                <td><span class="table-link" v-on:click="$router.push({name: 'Account', params: {accountId: row.item.id}})">{{row.item.name}}</span></td>
                <td v-if="!isMobile">{{row.item.industry}}</td>
                <td v-if="!isMobile"><span class="table-link" v-on:click="$router.push({name: 'User', params: {userId: row.item.owner}})">{{row.item.owner_name}}</span></td>
              </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <AccountForm v-if="form_id" :id="form_id" @close="buildTable()" />
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import AccountForm from '../components/forms/AccountForm.vue'
import { formatCurrency } from '../mixins/formatCurrency.js'

export default {
  name: 'accounts',
  components: {AccountForm},
  mixins: [formatCurrency],
  data(){
    return{
      form_id: null,
      search: '',
      filter: 'All Accounts',
      headers: [
        { text: 'Account Name', align: 'start', value: 'name', show_mobile: true},
        { text: 'Industry', value: 'industry'},
        { text: 'Account Owner', value: 'owner_name'},
      ],
      accounts_table_arr: []
    }
  },
  beforeMount(){
    var stored_filter = this.$store.state.accounts_table_filter
    if(stored_filter){
      this.filter = stored_filter
    }else{
      var user_role = this.db.users[this.current_user].role
      if(user_role === 'Administrator'){
        this.filter = 'All Accounts'
      }
      if(user_role === 'Sales'){
        this.filter = 'My Accounts'
      }
      if(user_role === 'Estimating'){
        this.filter = 'All Accounts'
      }
    }
  },
  mounted(){
    this.buildTable()
  },
  watch: {
    'filter': function (val) {
      this.buildTable()
      this.$store.state.accounts_table_filter = val
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      isMobile: 'isMobile'
    })
  },
  methods:{
    buildTable(){
      var self = this
      self.form_id = null
      self.accounts_table_arr = []
      Firebase.database().ref('accounts').once('value',function(snapshot){
        var accounts_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
        for(var account_id in accounts_db_clone){
          var account_object = accounts_db_clone[account_id]
          var table_row = {}
          if(!account_object.disabled){
            table_row.name = account_object.name
            table_row.id = account_id
            table_row.owner = account_object.owner
            table_row.owner_name = self.db.users[account_object.owner].first_name + ' ' + self.db.users[account_object.owner].last_name
            table_row.parent_account_name = account_object.parent_account ? accounts_db_clone[account_object.parent_account].name : ''
            table_row.industry = account_object.industry
            if(self.filter === 'All Accounts' || (self.filter === 'My Accounts' && table_row.owner === self.current_user)){
              self.accounts_table_arr.push(table_row)
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>


