<template>
  <div class="account">
    <v-container fluid v-if="db.accounts[id]">
      <v-card>
        <v-card-text>
          <v-row class="inputs-container">

            <v-col cols="12">
              <v-icon large >mdi-office-building</v-icon>
              <span style="font-size:16px;font-weight:bold">Account: {{db.accounts[id].name}}</span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Created By</div>
              <div class="display-value">{{db.accounts[id].added_by ? (db.users[db.accounts[id].added_by].first_name + ' ' + db.users[db.accounts[id].added_by].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Account Owner</div>
              <div class="display-value">{{db.accounts[id].owner ? (db.users[db.accounts[id].owner].first_name + ' ' + db.users[db.accounts[id].owner].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Parent Account</div>
              <div class="display-value">{{db.accounts[id].parent_account ? db.accounts[db.accounts[id].parent_account].name : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Industry</div>
              <div class="display-value">{{db.accounts[id].industry}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Phone</div>
              <div class="display-value">{{db.accounts[id].phone}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Website</div>
              <div class="display-value">{{db.accounts[id].website}}</div>
            </v-col>

            <v-col cols="12">
              <div class="display-label">Description</div>
              <div class="display-value">{{db.accounts[id].description}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Billing Address</div>
              <div class="display-value">{{db.accounts[id].billing_address}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Billing Street</div>
              <div class="display-value">{{db.accounts[id].billing_street}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Billing City</div>
              <div class="display-value">{{db.accounts[id].billing_city}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Billing State</div>
              <div class="display-value">{{db.accounts[id].billing_state}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Billing Zip Code</div>
              <div class="display-value">{{db.accounts[id].billing_zip}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Billing Country</div>
              <div class="display-value">{{db.accounts[id].billing_country}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping Address</div>
              <div class="display-value">{{db.accounts[id].shipping_address}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping Street</div>
              <div class="display-value">{{db.accounts[id].shipping_street}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping Zip Code</div>
              <div class="display-value">{{db.accounts[id].shipping_zip}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping City</div>
              <div class="display-value">{{db.accounts[id].shipping_city}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping State</div>
              <div class="display-value">{{db.accounts[id].shipping_state}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Shipping Country</div>
              <div class="display-value">{{db.accounts[id].shipping_country}}</div>
            </v-col>
          </v-row>

        </v-card-text>

        <v-btn color="primary" small v-if="is_admin" @click="deleteAccount()" style="margin:5px">Delete</v-btn>

        <v-btn color="primary" small @click="edit_form = true" style="margin:5px">Edit</v-btn>

      </v-card>
    </v-container>

    <v-container fluid v-if="db.accounts[id]">
      <v-card>

        <v-card-text>
          <div style="font-weight:bold">Logged Calls for {{db.accounts[id].name}}</div>
          <v-btn color="primary" small @click="log_call = true" style="position:absolute;top:10px;right:10px"><v-icon small class="mr-2">mdi-book-open</v-icon>LOG A CALL</v-btn>
          <v-data-table dense :mobile-breakpoint="0" must-sort :headers="[{ text: 'Name', value: 'name' },{ text: 'Date', value: 'date' },{ text: 'Time', value: 'time' },{ text: 'Contact', value: 'contact' },{ text: 'Type', value: 'subject' },{ text: 'Comments', value: 'comments' },{text:'',sortable:false}]" :items="getLoggedCalls" :items-per-page="-1" hide-default-footer :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" fixed-header>
            <template v-slot:item="row">
                <tr>
                  <td style="min-width:100px;">{{row.item.name}}</td>
                  <td style="min-width:100px;">{{row.item.date}}</td>
                  <td style="min-width:100px;">{{row.item.time}}</td>
                  <td style="min-width:100px;">{{row.item.contact_name}}</td>
                  <td style="min-width:100px;">{{row.item.subject}}</td>
                  <td style="min-width:100px;">{{row.item.comments}}</td>
                  <td v-if="is_admin">
                    <v-tooltip top color="#223773" style="height:20px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-icon v-bind="attrs" v-on="on">
                          <v-icon style="cursor:pointer" @click="deleteCall(row.item.id)">mdi-trash-can</v-icon>
                        </v-list-item-icon>
                      </template>
                      <span>Delete Call</span>
                    </v-tooltip>
                  </td>
                </tr>
            </template>
          </v-data-table>

        </v-card-text>
      </v-card>
    </v-container>

    <!-- <v-container fluid v-if="account_opportunitites.length && db.accounts[id]"> -->
    <v-container fluid>
      <v-card>
        <v-card-text>
          <div style="font-weight:bold">Opportunities for {{db.accounts[id].name}}</div>
          <v-data-table dense :no-data-text="loading_message" :mobile-breakpoint="0" must-sort :headers="!isMobile ? headers : $_.filter(headers, {show_mobile: true})" :items="account_opportunitites" :items-per-page="-1" hide-default-footer :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" fixed-header>
            <template v-slot:item="row">
                <tr>
                  <td><span class="table-link" v-on:click="$router.push({name: 'Opportunity', params: {opportunityId: row.item.id}})">{{row.item.name}}</span></td>
                  <td v-if="!isMobile">{{row.item.quote_number}}</td>
                  <td v-if="!isMobile">{{row.item.owner_name}}</td>
                  <td v-if="!isMobile">{{row.item.stage !== '--None--' ? row.item.stage : ''}}</td>
                  <td v-if="!isMobile">{{ formatDate(row.item.close_date) }}</td>
                  <td v-if="!isMobile">{{formatCurrency(row.item.total)}}</td>
                  <td v-if="!isMobile">{{formatMargin(row.item.margin)}}</td>
                </tr>
            </template>
          </v-data-table>

        </v-card-text>
      </v-card>
    </v-container>
    <AccountForm v-if="edit_form" :id="id" @close="edit_form = false" />
    <LogCall v-if="log_call" @close="log_call = false" :accountid="id" :lockaccount="true" />
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import Confirm from '../components/Confirm.vue'
import { mapGetters, mapActions } from 'vuex'
import AccountForm from '../components/forms/AccountForm.vue'
import LogCall from '../components/LogCall.vue'
import { formatCurrency } from '../mixins/formatCurrency.js'
import Moment from 'moment'

export default {
  name: 'account',
  components: {AccountForm,LogCall},
  mixins: [formatCurrency],
  data(){
    return{
      log_call: false,
      edit_form: false,
      account_opportunitites: [],
      id: this.$route.params['accountId'],
      loading_message: 'Loading data...',
      headers: [
        { text: 'Opportunity Name', align: 'start', value: 'name', show_mobile: true},
        { text: 'Quote Number', value: 'quote_number' },
        { text: 'Opportunity Owner', value: 'owner_name' },
        { text: 'Stage', value: 'stage' },
        { text: 'Close Date', value: 'close_date'},
        { text: 'Total', value: 'total'},
        { text: 'Margin', value: 'margin'},
      ]
    }
  },
  mounted(){
    var self = this
    var opps_clone = {}
    var opps = []
    var opportunity_to_revenue_mapping = {}
    var opportunity_to_cost_mapping = {}

    Firebase.database().ref('totals').once('value',function(totals_snapshot){
      var totals_snapshot_val = totals_snapshot.val()
      for(var opp_id in totals_snapshot_val){
        opportunity_to_revenue_mapping[opp_id] = 0
        opportunity_to_cost_mapping[opp_id] = 0
        var totals = totals_snapshot_val[opp_id]
        for(var tid in totals){
          var total = totals[tid]
          if(total.show_in_master){
            opportunity_to_revenue_mapping[opp_id] += total.revenue
            opportunity_to_cost_mapping[opp_id] += total.cost
          }
        }
      }
    }).then(function(){
      Firebase.database().ref('opportunities').orderByChild('account').equalTo(self.id).once('value',function(snapshot){
        var opportunities = snapshot.val()
        for(var o in opportunities){
          var opportunity_object = opportunities[o];
          opportunity_object.id = o;
          opportunity_object.owner_name = self.db.users[opportunity_object.owner].first_name + ' ' + self.db.users[opportunity_object.owner].last_name;
          opportunity_object.account_name = opportunity_object.account ? self.db.accounts[opportunity_object.account].name : '';
          opportunity_object.total = 0
          opportunity_object.cost = 0
          var revenue_override_percentage = opportunity_object.revenue_override_percentage ? (opportunity_object.revenue_override_percentage / 100 ) : 0
          if (opportunity_object.salesforce_opportunity) {
            opportunity_object.total += opportunity_object.salesforce_revenue
            opportunity_object.cost += opportunity_object.salesforce_cost
          }else{
            opportunity_object.total += opportunity_to_revenue_mapping[opportunity_object.id] ? opportunity_to_revenue_mapping[opportunity_object.id] : 0
            opportunity_object.cost += opportunity_to_cost_mapping[opportunity_object.id] ? opportunity_to_cost_mapping[opportunity_object.id] : 0
          }
          opportunity_object.total = opportunity_object.total + (opportunity_object.total * revenue_override_percentage)
          opportunity_object.margin = parseFloat(self.margin(opportunity_object.total,opportunity_object.cost))
          if (!opportunity_object.disabled) {
            opps.push(opportunity_object);
          }
        }
        self.account_opportunitites = opps
        if(self.account_opportunitites.length === 0){
          self.loading_message = 'No opportunities available for this account'
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      is_admin: 'is_admin',
      isMobile: 'isMobile'
    }),
    getLoggedCalls() {
        var calls_clone = JSON.parse(JSON.stringify(this.db.logged_calls))
        var calls_arr = []

        for(var call in calls_clone){
          if(calls_clone[call].account === this.id){
            calls_clone[call].name = this.db.users[calls_clone[call].added_by].first_name + ' ' + this.db.users[calls_clone[call].added_by].last_name
            calls_clone[call].date = Moment(calls_clone[call].created_date).format('MM/DD/YY')
            calls_clone[call].time = Moment(calls_clone[call].created_date).format('h:mma')
            calls_clone[call].contact_name = calls_clone[call].contact ? (this.db.contacts[calls_clone[call].contact].first_name + ' ' + this.db.contacts[calls_clone[call].contact].last_name) : ''
            calls_clone[call].account_name = this.db.accounts[calls_clone[call].account].name
            calls_arr.push(calls_clone[call])
          }
        }
        return _.orderBy(calls_arr, ['created_date'],['desc']);
    }
  },
  methods:{
    margin(revenue,cost){
      var original = ((revenue - cost) / revenue) * 100
      var formatted = original.toFixed(1)
      return (revenue > 0 ? formatted : 0)
    },
    formatMargin(margin){
      return margin + '%'
    },
    // opportunityTotalObject(op_id){
    //   var self = this
    //   var total = 0
    //   var cost = 0
    //   Firebase.database().ref('totals').child(op_id).once('value',function(totals_snapshot){
    //     var totals = totals_snapshot.val()
    //     for(var tid in totals){
    //       var total_object = totals[tid]
    //       if(total_object.show_in_master){
    //         total += total_object.revenue
    //         cost += total_object.cost
    //       }
    //     }
    //   }).then(function(){
    //     margin: parseFloat(self.margin(total,cost))
    //   })

    // },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    deleteAccount(){
      var self = this
      var linked_parent_accounts = []
      var linked_contacts = []
      var linked_logged_calls = []
      var linked_opportunities = []
      var associated_record_types = []

      for(var account in this.db.accounts){
        if(this.db.accounts[account].parent_account === this.id){
          linked_parent_accounts.push(this.db.accounts[account].name)
        }
      }

      for(var contact in this.db.contacts){
        if(this.db.contacts[contact].account === this.id){
          linked_contacts.push(this.db.contacts[contact].first_name + ' ' + this.db.contacts[contact].last_name)
        }
      }

      for(var call in this.db.logged_calls){
        if(this.db.logged_calls[call].account === this.id){
          linked_logged_calls.push(call)
        }
      }


      Firebase.database().ref('opportunities').orderByChild('account').equalTo(self.id).once('value',function(snapshot){

        var opportunities = snapshot.val()


        for(var opp in opportunities){
          linked_opportunities.push(opportunities[opp].name)
        }

        if(linked_parent_accounts.length){
          associated_record_types.push('Parent Account for one or more Accounts')
        }

        if(linked_contacts.length){
          associated_record_types.push('Account for one or more Contacts')
        }

        if(linked_logged_calls.length){
          associated_record_types.push('Account for one or more Logged Calls')
        }

        if(linked_opportunities.length){
          associated_record_types.push('Account for one or more Opportunities')
        }


        if(associated_record_types.length){
          var str = '<div style="margin-bottom:25px;text-align:left">' + self.db.accounts[self.id].name + ' cannot be deleted for the following reason(s):</div>'
          for(var i = 0; i < associated_record_types.length; i++){
            str += '<div style="margin-bottom:5px;text-align:left">' + associated_record_types[i] + '</div>'
          }
          self.$store.state.notification = str
        }else{
          var confirm_delete = confirm('Are you sure you wanted to delete account ' + self.db.accounts[self.id].name + '?')
          if(!confirm_delete){return}
          Firebase.database().ref('accounts').child(self.id).remove().then(function(){
            self.$router.push('/accounts')
          })
        }

      })

    },
    deleteCall(id){
        var self = this
        var confirm_delete = confirm('Are you sure you wanted to delete this logged call?')
        if(!confirm_delete){return}
        Firebase.database().ref('logged_calls').child(id).remove().then(function(){

        })
    }
  }
}
</script>

<style lang="scss">

</style>


